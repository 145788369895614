import { useRouter } from 'next/router'
import React, { FC } from 'react'
import { NZLogo } from '@nzsb/shopnx-ui'
import CN from 'classnames'

export interface BrandingInfoProps {
  [x: string]: any
  className?: string
  logo?: any
  cta?: {
    heading?: string
    number?: string
    icon?: string
  }
}

export const BrandingInfo: FC<BrandingInfoProps> = ({
  className,
  logo,
  cta,
  ...restProps
}: BrandingInfoProps) => {
  const BrandingInfoClasses = CN(`branding-info mb-[18px] lg:mb-0`, className)

  const router = useRouter()

  return (
    <div data-component-id='branding-info' className={BrandingInfoClasses} {...restProps}>
      <NZLogo
        {...logo}
        className='!w-[172px] !h-[44px] mb-[16px] cursor-pointer'
        onClick={() => {
          router.push('/')
        }}
      />
      <a className='font-semibold text-O-400 text-base' href={`tel:${cta?.number}`}>
        {cta?.number}
      </a>
    </div>
  )
}

BrandingInfo.defaultProps = {
  className: undefined,
  logo: undefined,
  cta: undefined
}

export default BrandingInfo
