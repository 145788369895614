import { useQuery } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

/* API Request */
export const getDealsAPI = async () => {
  const { data: response } = await httpPOST({
    url: '/promotions/deal/getproducts',
    body: {
      searchText: '',
      pageIndex: 1,
      pageSize: 4
    }
  })
  return response
}

/* Client side react-query custom hook */
export const useGetDeals = () => {
  const { data, ...restOptions } = useQuery({
    queryKey: ['fetch-deals'],
    queryFn: () => getDealsAPI()
  })

  return { deals: data?.data?.products || [], ...restOptions }
}
