import { useRouter } from 'next/router'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { IAppContext } from 'types/context'
import { IGetCart, useGetCart } from 'lib/actions'
import { useSession } from 'lib/actions/session/getSession'

const AppContext = createContext({} as IAppContext)

export function AppWrapper({ children, values }: any) {
  const router = useRouter()

  const { session, isLoading: isSessionLoading } = useSession()
  const [tableData, setTableData] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([])
  const [product, setProduct] = useState({} as any)
  const [prevTableData, setPrevTableData] = useState([])
  const [footerLinks, setFooterLinks] = useState([])

  // Cart States
  const [quickCartIconInfo, setQuickCartIconInfo] = useState<IGetCart>({ cartId: '', noOfItems: 0 })

  // checkOut States
  const [cxmlData, setCxmlData] = useState('')
  const [ociData, setOciData] = useState([])

  // Fetching data to keep refetching on focus
  const { refetch: getCartQuickInfo, isFetching: isLoadingGetCartQuickInfo } = useGetCart()

  useEffect(() => {
    getCartQuickInfo().then(res => {
      setQuickCartIconInfo({
        cartId: res?.data?.data?.cartId || '',
        noOfItems: res?.data?.data?.noOfItems || 0
      })
    })
  }, [router])

  const sharedState: IAppContext = {
    session,
    isSessionLoading,
    user: session?.user,
    isLoggedIn: !!session?.isLoggedIn,
    productVariants: {
      tableData,
      setTableData,
      selectedProducts,
      setSelectedProducts,
      product,
      setProduct,
      prevTableData,
      setPrevTableData
    },
    cart: {
      isLoadingGetCartQuickInfo,
      ...quickCartIconInfo,
      setQuickCartIconInfo
    },
    checkOut: {
      cxmlData,
      setCxmlData,
      ociData,
      setOciData
    },
    footerLinks: {
      footerLinks,
      setFooterLinks
    }
  }

  return <AppContext.Provider value={{ ...sharedState, ...values }}>{children}</AppContext.Provider>
}

export function useAppContext() {
  return useContext(AppContext)
}
