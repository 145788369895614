import { useMutation } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

export interface IFeedbackResponse {
  messageTitle: string | null
  messageDescription: string | null
  messageType: string
  messageCode: string
  data: any
}

export interface IFeedbackReq {
  captchaToken: string
  comments: string
  emailAddress: string
  firstName: string
  lastName: string
}

/* API Request */
export const feedbackRequest = async (req: IFeedbackReq) => {
  const response = await httpPOST({
    url: `account/submit_feedback_form`,
    body: req
  })
  return response?.data as IFeedbackResponse
}

/* Client side react-query custom hook */
export const useFeedback = () => {
  const { data, mutateAsync, ...restOptions } = useMutation({
    mutationFn: feedbackRequest
  })

  // Passing mutateAsync as feedback
  // and restOptions as rest of the options
  return { feedbackResponse: data, feedback: mutateAsync, ...restOptions }
}
