import React, { FC, useState } from 'react'
import { Button, Lozenge, Modal, ModalBody, ModalFooter } from '@nzsb/shopnx-ui'
import CN from 'classnames'

export interface AddressProps {
  id: string
  displayName?: string
  firstName: string
  lastName: string
  contactNumber: string
  businessName: string
  addressDetails: string
  streetAddress: string
  city: string
  suburb: string
  country: string
  postCode: string
  latitude: string | null
  longitude: string | null
  attentionTo: string
  isDefault: boolean
  isInvoiceAddress: boolean
  isAccountShippingAddress: boolean
}

export interface AddressCardProps {
  className?: string
  componentId?: string
  data: AddressProps
  isSelectable?: boolean
  isSelectedCard?: string
  onCardClick?: (cardData: AddressProps) => void
  onEditClick?: () => void
  onRemoveClick?: () => void
}

export const AddressCard: FC<AddressCardProps> = ({
  className,
  componentId,
  data,
  isSelectable = false,
  isSelectedCard,
  onCardClick,
  onEditClick,
  onRemoveClick
}: AddressCardProps) => {
  const AddressCardClasses = CN(
    `${componentId}-address-card flex flex-col w-full border border-N-50 p-5 rounded justify-between`,
    {
      'bg-B-25 !border-B-500 cursor-default': data?.isDefault && isSelectable === false,
      'bg-white !border-N-50 cursor-default':
        (!data?.isDefault || data?.isInvoiceAddress) && isSelectable === false,
      'cursor-pointer hover:bg-B-25 hover:!border-B-500': isSelectable,
      'cursor-pointer bg-B-25 !border-B-500': isSelectable && data?.id === isSelectedCard
    },
    className
  )
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)

  return (
    <>
      <button
        type='button'
        className={AddressCardClasses}
        onClick={event => {
          event.preventDefault()
          if (isSelectable && onCardClick) {
            onCardClick(data)
          }
        }}>
        <div className='w-full text-left'>
          {data?.displayName && <span className='font-bold text-base'>{data?.displayName}</span>}
          {data?.attentionTo && <p className='text-base'>{data?.attentionTo}</p>}
          {data?.streetAddress && <p className='text-base'>{data?.streetAddress}</p>}
          {data?.suburb && <p className='text-base'>{data?.suburb}</p>}
          {data?.city && <p className='text-base'>{data?.city}</p>}
          {data?.postCode && <p className='text-base'>{data?.postCode}</p>}
        </div>

        {!data?.isInvoiceAddress && (
          <div className='flex justify-between items-center pt-5 w-full'>
            <div>
              {data?.isAccountShippingAddress ? (
                <Lozenge
                  icon={CN('ri-checkbox-circle-fill', {
                    '!text-O-500': data?.isDefault
                  })}
                  variant={data?.isDefault ? 'warning-1' : 'disabled'}
                  appearance='subtle'
                  componentId={`${componentId}-account-shipping-address-lozenge`}
                  className='text-sm text-start'>
                  Account Shipping Address
                </Lozenge>
              ) : (
                data?.isDefault && (
                  <Lozenge
                    icon='ri-checkbox-circle-fill !text-O-500'
                    variant={'warning-1'}
                    appearance='subtle'
                    componentId={`${componentId}-default-address-lozenge`}
                    className='text-sm text-start'>
                    Default
                  </Lozenge>
                )
              )}
            </div>
            {!data?.isAccountShippingAddress && (
              <div className='flex gap-x-5'>
                <Button
                  size='md'
                  appearance='link-gray'
                  type='button'
                  iconBefore='nzsbi-edit !text-[24px]'
                  isRingOffset={false}
                  isEnableStopPropagation={isSelectable}
                  componentId={`${componentId}-edit-address-button`}
                  onClick={() => {
                    if (onEditClick) {
                      onEditClick()
                    }
                  }}>
                  Edit
                </Button>

                <Button
                  size='md'
                  appearance='link-red'
                  type='button'
                  iconBefore='nzsbi-delete !text-[24px]'
                  isRingOffset={false}
                  isEnableStopPropagation={isSelectable}
                  componentId={`${componentId}-delete-address-button`}
                  onClick={() => {
                    setIsOpenDeleteModal(true)
                  }}>
                  Remove
                </Button>
              </div>
            )}
          </div>
        )}
      </button>

      <Modal
        isOpen={isOpenDeleteModal}
        componentId='shipping-address-delete-modal'
        className='w-full !max-w-[380px]'
        onClickOverlay={() => {
          setIsOpenDeleteModal(false)
        }}>
        <div
          className='flex flex-row items-center justify-end h-[52px] px-[20px]'
          data-component-id='shipping-address-delete-modal-header'>
          <Button
            iconOnly
            type='button'
            iconBefore='nzsbi-close'
            componentId='shipping-address-delete-modal-header-close'
            appearance='link-gray'
            size='xs'
            onClick={() => {
              setIsOpenDeleteModal(false)
            }}
          />
        </div>

        <ModalBody
          componentId='shipping-address-delete-modal-body'
          className='!pt-0 flex flex-col justify-center items-center'>
          <i className='shipping-address-delete-warning-icon ri-error-warning-line text-[40px] text-Y-500 mb-[16px]' />
          <div className='w-full text-center'>
            Do you really want to remove this shipping Address?
          </div>
        </ModalBody>
        <ModalFooter>
          <div className='flex gap-[16px]'>
            <Button
              type='button'
              appearance='secondary-gray'
              size='xs'
              componentId='shipping-address-delete-modal-cancel-button'
              onClick={() => {
                setIsOpenDeleteModal(false)
              }}>
              Cancel
            </Button>

            <Button
              type='button'
              appearance='primary-orange'
              size='xs'
              componentId='shipping-address-delete-modal-remove-button'
              onClick={() => {
                if (onRemoveClick) {
                  onRemoveClick()
                }
                setIsOpenDeleteModal(false)
              }}>
              Remove
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default AddressCard
