import { useMutation } from '@tanstack/react-query'
import { httpPatch } from 'lib/http'

export interface IPatchAccountDetailsResponse {
  messageTitle: string | null
  messageDescription: string | null
  messageType: string
  data: any
}

/**
 * Created to obtain dela products mentioned in the CMS end and retrieve again from the BE
 * @param productIds
 * @param dealsCount
 * @returns
 */
export const patchAccountDetails = async (updateDetails: {
  userDetails: {
    firstName: string
    lastName: string
    mobileNumber: string
    businessName: string
  }
  context: any
}) => {
  const { context, userDetails } = updateDetails
  const { data } = await httpPatch({
    url: `account/updatedetails`,
    context,
    body: {
      ...userDetails
    }
  })
  return data
}

export const usePatchAccountDetails = () => {
  return useMutation({
    mutationFn: patchAccountDetails
  })
}
