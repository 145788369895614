import React, { FC } from 'react'
import { Modal, ModalBody, ModalHeader } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { useModalContext } from 'lib/contexts/modal-context'

import { SignUp } from 'components/forms/SignUp'

export interface ISignUpModalProps {
  className?: string
}

export const SignUpModal: FC<ISignUpModalProps> = ({
  className,
  ...restProps
}: ISignUpModalProps) => {
  const { signUpModal } = useModalContext()
  const [showResult, setShowResult] = React.useState(false)

  const hideModal = () => {
    signUpModal?.close()
  }
  return (
    <Modal
      componentId='sign-up-modal'
      isOpen={signUpModal?.isOpen}
      onClose={signUpModal?.close}
      className={CN('flex-shrink-0', {
        'w-[620px]': !showResult,
        'max-w-[480px]': showResult
      })}
      {...restProps}>
      <ModalHeader
        componentId='sign-up-modal'
        onClose={hideModal}
        heading={null}
        hasBorder={false}
      />
      <ModalBody maxHeight={740} componentId='sign-up-modal' className='p-0'>
        <SignUp setShowResult={setShowResult} />
      </ModalBody>
    </Modal>
  )
}

export default SignUpModal
