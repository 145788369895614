import { useQuery } from '@tanstack/react-query'
import { httpGET } from 'lib/http'

/* Fetch Session */
export const fetchSession = async () => {
  try {
    const data = await httpGET({
      url: '/session/getSession'
    })
    return data
  } catch (error) {
    return { error }
  }
}

export const useSession = () => {
  /* Use Query */
  const {
    data: session,
    refetch: getSession,
    ...restOptions
  } = useQuery({
    queryKey: ['session'],
    queryFn: fetchSession,
    enabled: true
  })

  return { ...restOptions, getSession, session }
}

export default useSession
