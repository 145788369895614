import Image from 'next/image'
import { useRouter } from 'next/router'
import React, { FC } from 'react'
import { Button } from '@nzsb/shopnx-ui'
import noFavouritiesImg from 'public/images/no-favourities.png'

export interface EmptyFavouritiesProps {}

export const EmptyFavourities: FC<EmptyFavouritiesProps> = () => {
  const router = useRouter()

  const onClickBackToHome = () => {
    router.push('/')
  }

  return (
    <div className='empty__myFavourites flex flex-col items-center justify-center mb-[80px] w-full border-[1px] rounded-[8px] border-[#E4E4E3] px-[49px] sm:!px-[134px] lg:px-[120px]'>
      <div className='empty__myFavourites-emptyIamge flex flex-col items-center justify-center w-[112px] h-[112px] mt-[40px] sm:w-[136px] sm:h-[136px] '>
        <Image src={noFavouritiesImg} alt='' />
      </div>

      <p className='empty-myFavourites__heading flex font-display font-400 text-center text-display-h1-m sm:text-display-h1 text-N-800 pt-3'>
        NO FAVOURITES YET
      </p>

      <p className='empty-myFavourites__contentText-1 flex text-center text-base text-N-500 font-500 pt-2'>
        It appears that you have not selected any items for your favourite list.
      </p>

      <p className='empty-myFavourites__contentText-2 flex text-center text-base text-N-500 font-500 pt-3 mb-5'>
        Simply click the favourite icon next to any items to select it. Your favourite items will
        appear on this page for your convenience.
      </p>

      <div className='flex w-full sm:!w-[226px] lg:!w-[286px]'>
        <Button
          componentId='empty-favourites-to-home'
          onClick={onClickBackToHome}
          className='w-full text-left mb-[132px] sm:mb-[136px] lg:mb-[88px]'
          size='md'
          appearance='secondary-orange'
          iconBefore='nzsbi-chevron-left'>
          BACK TO HOME PAGE
        </Button>
      </div>
    </div>
  )
}

export default EmptyFavourities
