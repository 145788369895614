import { useMutation } from '@tanstack/react-query'
import { useAppContext } from 'lib/contexts/app-context'
import { httpPOST } from 'lib/http'

import { useGetCart } from './useGetCart'

export interface IAddToCartResponse {
  messageTitle: string | null
  messageDescription: string | null
  messageType: string
  data: any
}

export interface IAddToCartReq {
  productId: number
  variantId?: number
  quantity: number
  cartId?: string
}

export const addToCart = async (req: IAddToCartReq) => {
  const data = await httpPOST({
    url: 'cart/addtocart',
    body: req
  })

  return data?.data as IAddToCartResponse
}

export const useAddToCart = () => {
  /** Get cart Id */
  const { cart } = useAppContext()
  const { refetch: fetchGetCart } = useGetCart()

  /* Use Query */
  const { data, mutateAsync, ...restOptions } = useMutation({
    mutationFn: async (req: IAddToCartReq) => {
      const res = await addToCart({ ...req, cartId: cart?.cartId })
      fetchGetCart()
      return res
    }
  })

  return { addToCartResponse: data, addToCartAsync: mutateAsync, ...restOptions }
}
