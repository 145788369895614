import Router from 'next/router'
import { useMutation } from '@tanstack/react-query'
import { useSession } from 'lib/actions/session/getSession'
import { useModalContext } from 'lib/contexts/modal-context'
import { httpPOST } from 'lib/http'

/* API Request */
export const logOutRequest = async () => {
  try {
    const response = await httpPOST({
      type: 'internal',
      url: '/auth/logout'
    })

    return response
  } catch (error) {
    return { error }
  }
}

/* Client side react-query custom hook */
export const useLogout = (closeModals = true, redirectHome = true) => {
  const { getSession } = useSession()
  const { allModals } = useModalContext()

  const { mutate, ...restOptions } = useMutation({
    mutationFn: logOutRequest,
    onSuccess: async () => {
      if (closeModals) allModals.close()
      await getSession()
      if (redirectHome) Router.push('/')
    }
  })

  // Passing mutateAsync as logout
  // and restOptions as rest of the options
  return { logout: mutate, ...restOptions }
}
