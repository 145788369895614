import Link from 'next/link'
import React, { FC, useState } from 'react'
import { Button, ProdCardProps } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { useAddToFavoriteProduct, useDeleteFavoriteProduct } from 'lib/actions'
import { checkURL } from 'lib/utilities'

import { ProductCard } from '../cards/ProductCard'

export interface DealProductListProps {
  actionButton?: {
    buttonText: string
    url: string
    iconAfter?: string
    iconBefore?: string
  }
  componentId?: string
  isPromotions: boolean
  productClassName?: string
  productListClassName?: string
  products?: ProdCardProps[]
  title?: string
}

export const DealProductList: FC<DealProductListProps> = ({
  actionButton,
  componentId,
  isPromotions,
  productClassName,
  productListClassName,
  products,
  title,
  ...restProps
}: DealProductListProps) => {
  const DealProductListClasses = CN(`${componentId}-product-list grid gap-6`, productListClassName)

  const [renderingProducts, setRenderingProducts] = useState<any>(products || [])

  const { mutate: addToFavoriteAction } = useAddToFavoriteProduct()
  const { mutate: deleteFavoriteProduct } = useDeleteFavoriteProduct()

  const onFavoriteActionClick = (productId: string) => {
    const updatedProducts = [...renderingProducts]
    const productIndex = updatedProducts.findIndex((product: any) => product.id === productId)

    if (updatedProducts[productIndex].isFavorite) {
      deleteFavoriteProduct(productId)
    } else {
      addToFavoriteAction(productId)
    }

    const updatedProduct = {
      ...updatedProducts[productIndex],
      isFavorite: !updatedProducts[productIndex].isFavorite
    }
    updatedProducts[productIndex] = updatedProduct
    setRenderingProducts(updatedProducts)
  }

  const {
    originalUrl: buttonOriginalUrl,
    restUrlWithQuery: buttonQuery,
    isInsideUrl: buttonUrlIsInternal
  } = checkURL(actionButton?.url ?? '')

  return (
    <div className={DealProductListClasses} {...restProps}>
      <div className='flex flex-col justify-start gap-y-2 gap-x-6 lg:!flex-row lg:!justify-between lg:items-end'>
        {title && (
          <div
            data-component-id='popular-brand-category-header'
            className='font-600 text-h3 text-N-800'>
            {title}
          </div>
        )}
        {actionButton && (
          <Link
            href={buttonUrlIsInternal ? buttonQuery : buttonOriginalUrl ?? {}}
            passHref
            target={buttonUrlIsInternal ? '' : '_blank'}
            className={CN({
              'cursor-pointer': actionButton?.url,
              'cursor-auto': actionButton?.url
            })}>
            <Button
              appearance='link'
              iconAfter={actionButton?.iconAfter}
              iconBefore={actionButton?.iconBefore}
              size='md'>
              {actionButton?.buttonText}
            </Button>
          </Link>
        )}
      </div>
      <div className='flex overflow-x-auto no-scrollbar'>
        <div className='flex lg:gap-8 md:gap-5 gap-4 items-start justify-start'>
          {renderingProducts?.map((product: ProdCardProps) => (
            <ProductCard
              key={`${product.id}-product-card`}
              className='min-w-[293px]'
              product={product}
              appearance='vertical'
              isShowStock={!isPromotions}
              isShowStoreDetails={!isPromotions}
              onFavoriteActionClick={() => {
                onFavoriteActionClick(product.id)
              }}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default DealProductList
