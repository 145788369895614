import { useQuery } from '@tanstack/react-query'
import { httpGET } from 'lib/http'

export interface IGetOrderByIdReq {
  orderId?: string
}

export interface Address {
  id: string
  displayName: string
  firstName: string
  lastName: string
  contactNumber: string
  businessName: string
  addressDetails: string
  streetAddress: string
  city: string
  suburb: string
  country: string
  postCode: string
  latitude: number | null
  longitude: number | null
  attentionTo: string
  isDefault: boolean
  isInvoiceAddress: boolean
  isAccountShippingAddress: boolean
  isShippingAddress: boolean
}

export interface OrderLine {
  title: string
  productId: number
  variantId: number
  lineNumber: number
  sku: string
  price: number
  priceWithGst: number
  quantity: number
  image: {
    id: string
    url: string
    alt: string
    title: string
  }
  isCatalogue: boolean
  productFlag: number
  subTotal: number
  totalPriceWithGst: number
}

export interface PickUpDetails {
  branchCode: string
  branchName: string
  streetAddress: string
  suburb: string
  city: string
  postCode: string
  pickupBranchName: string
  pickupOption: number
  preferredDate: string
  firstName: string
  lastName: string
  contactNumber: string
  emailAddress: string
  id: string
  createdDate: string
  lastModifiedDate: string
  events: any[]
}

export interface IOrderRes {
  data: {
    order: {
      orderNumber: number
      paymentType: string
      paymentTypeId: number
      purchaseOrderNumber: string
      subTotal: number
      shippingCharges: number
      shippingChargesWithGst: number
      gst: number
      totalAmountWithGst: number
      createdDate: string
      shippingAddress?: Address
      invoiceAddress: Address
      orderLines: OrderLine[]
      platform: number
      pickupDetails?: PickUpDetails
    }
  }
  messageDescription: string
  messageTitle: string
  messageType: string
  messageCode: string
}

export const getOrderById = async (req: IGetOrderByIdReq) => {
  const data = await httpGET({
    url: `order/${req?.orderId}`
  })
  return data?.data as IOrderRes
}

/* Client side react-query custom hook */
export const useGetOrderById = (orderId: string) => {
  return useQuery({
    queryKey: ['GET_ORDER_BY_ID', orderId],
    queryFn: () => getOrderById({ orderId })
  })
}
