import { CldVideoPlayer } from 'next-cloudinary'
import React, { FC } from 'react'

export interface INextVideoProps {
  autoplay?: 'on-scroll' | 'never' | 'always' | boolean
  className?: string
  height?: string | number
  loop?: boolean
  muted?: boolean
  publicId: string
  preload?: 'none' | 'metadata' | 'auto'
  width?: string | number
  [x: string]: any
}
export const NextVideo: FC<INextVideoProps> = ({
  autoplay = 'on-scroll',
  className,
  height,
  loop = true,
  muted,
  publicId,
  preload,
  width,
  ...restProps
}: INextVideoProps) => {
  return (
    <CldVideoPlayer
      key={publicId}
      autoplay={autoplay}
      className={className}
      height={height ?? 700}
      loop={loop}
      muted={autoplay ? true : muted}
      src={publicId}
      preload={preload ?? 'auto'}
      width={width ?? 1280}
      playsinline
      controls={false}
      hideContextMenu
      {...restProps}
    />
  )
}

export default NextVideo
