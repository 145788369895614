import Link from 'next/link'
import React, { FC } from 'react'
import { Button } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { IComponentMoleculesServicesTextWithVideoBlock } from 'lib/actions'
import { checkURL } from 'lib/utilities'

import { YouTubeIFrame } from 'components/atoms'

export interface ITextWithVideoBlockProps {
  className?: string
  componentId: string
  data: IComponentMoleculesServicesTextWithVideoBlock
}

export const TextWithVideoBlock: FC<ITextWithVideoBlockProps> = ({
  className,
  componentId,
  data,
  ...restProps
}: ITextWithVideoBlockProps) => {
  const {
    originalUrl: buttonOriginalUrl,
    restUrlWithQuery: buttonQuery,
    isInsideUrl: buttonUrlIsInternal
  } = checkURL(data?.Action?.Url ?? '')

  const TextWithVideoBlockClasses = CN(`flex w-full bg-B-25 rounded-[3px]`, className, {
    'p-4 md:!p-6': !data?.Header,
    'px-4 py-6 md:!p-6': data?.Header
  })

  return (
    <div id={`text-with-video-${componentId}`} className={TextWithVideoBlockClasses} {...restProps}>
      <div className='flex flex-col gap-[16px] lg:!gap-[18px] w-full'>
        {data?.Header && <span className='text-h3 font-600 text-B-500'>{data?.Header}</span>}
        {(data?.VideoLink || data?.Description) && (
          <div
            className={CN('w-full', {
              'flex flex-col lg:!grid lg:!grid-cols-2 gap-6 lg:!gap-8': !data?.Header,
              'flex flex-col lg:!grid lg:!grid-cols-2 gap-8': data?.Header && data?.IsVideoLeft,
              'flex flex-col-reverse md:!grid md:!grid-cols-2 gap-8':
                data?.Header && !data?.IsVideoLeft
            })}>
            {data?.VideoLink && data?.IsVideoLeft && (
              <div
                className={CN('w-full', {
                  'h-[236px] md:!h-[356px] lg:!h-[236px]':
                    !data?.Header && (data?.Description || data?.Action?.ButtonText),
                  'h-[212px] md:!h-[356px] lg:!h-[231px]':
                    data?.Header && (data?.Description || data?.Action?.ButtonText)
                })}>
                <YouTubeIFrame youtubeLink={data?.VideoLink} className='w-full h-full' />
              </div>
            )}
            {(data?.Description || data?.Action?.ButtonText) && (
              <div
                className={CN('flex flex-col gap-6 w-full', {
                  'lg:!gap-4': !data?.Header
                })}>
                {data?.Description && (
                  <div
                    data-component-id='content-html-seo-description'
                    dangerouslySetInnerHTML={{ __html: data?.Description }}
                    className='prose mark-down max-w-none'
                  />
                )}
                {data?.Action?.ButtonText && (
                  <Link
                    href={buttonUrlIsInternal ? buttonQuery : buttonOriginalUrl ?? {}}
                    passHref
                    target={buttonUrlIsInternal ? '' : '_blank'}
                    className={CN({
                      'cursor-pointer': buttonOriginalUrl,
                      'cursor-auto pointer-events-none': !buttonOriginalUrl
                    })}>
                    <Button
                      appearance='primary-orange'
                      className='w-fit'
                      size='md'
                      isBlock
                      iconBefore={data?.Action?.IconBefore}
                      iconAfter={data?.Action?.IconAfter}>
                      {data?.Action?.ButtonText}
                    </Button>
                  </Link>
                )}
              </div>
            )}
            {data?.VideoLink && !data?.IsVideoLeft && (
              <div className={CN('w-full h-[220px] md:!h-[221px]')}>
                <YouTubeIFrame youtubeLink={data?.VideoLink} className='w-full h-full' />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default TextWithVideoBlock
