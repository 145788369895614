import { useMutation } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

export interface ICxmlPunchOutReq {
  CartId?: string
  PayloadId?: string
  Timestamp?: string
  FromDomain?: string
  FromIdentity?: string
  SenderDomain?: string
  SenderIdentity?: string
  SenderUserAgent?: string
  BuyerCookie?: string
  browserFormPostURL: string
  ToCredentialXml: any
}

export const cxmlPunchOutCheckOutExternal = async (req: ICxmlPunchOutReq) => {
  const response = await httpPOST({
    url: '/checkout/punchout/cxml',
    body: req
  })
  return response?.data
}

const cxmlPunchOutCheckOutInternal = async (req: { cartId?: string }) => {
  const response = await httpPOST({
    url: `/cart/cxmlCheckout`,
    type: 'internal',
    endpoint: 'api',
    body: req
  })
  return response?.data
}

/* Client side react-query custom hook */
export const useCXMLPunchOutCheckout = () => {
  const { mutateAsync, ...restOptions } = useMutation({
    mutationFn: cxmlPunchOutCheckOutInternal
  })

  // Passing mutateAsync as forget password
  // and restOptions as rest of the options
  return { cxmlPunchOutCheckOutAsync: mutateAsync, ...restOptions }
}
