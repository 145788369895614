import { useRouter } from 'next/router'
import React, { FC, useEffect, useState } from 'react'
import { Button, TextField } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useForgotPassword } from 'lib/actions'
import { useResendActivationLink } from 'lib/actions/auth'
import { useModalContext } from 'lib/contexts/modal-context'

import { OnScreenMessage } from 'components/molecules'

export interface IForgotPasswordProps {
  className?: string
  hideHeader?: boolean
  enableBackToAction?: () => void
}

export const ForgotPassword: FC<IForgotPasswordProps> = ({
  className,
  hideHeader,
  enableBackToAction,
  ...restProps
}: IForgotPasswordProps) => {
  const router = useRouter()
  const { signUpModal, forgotPasswordModal } = useModalContext()
  const [email, setEmail] = useState('')

  const ForgotPasswordClasses = CN(`forgot-password flex flex-col px-[20px] pb-[20px]`, className)

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please enter a valid email address')
      .required('Please insert your email address')
  })

  // Forgot password custom hook
  const {
    data: forgotPasswordResponse,
    forgotPasswordAsync,
    isPending: isLoadingForgotPasswordAction
  } = useForgotPassword()

  const openCreateAccount = () => {
    if (hideHeader) {
      router.push('/auth/register')
    } else {
      forgotPasswordModal?.close()
      signUpModal?.open()
    }
  }

  const {
    data: resendActivationLinkResponse,
    isPending: isLoadingActivationLink,
    resendActivationLinkAsync
  } = useResendActivationLink()

  /* ClientSide: Handle verify account */
  const resendLink = async () => {
    await resendActivationLinkAsync({ emailAddress: email })
  }

  useEffect(() => {
    if (forgotPasswordResponse?.messageType === 'error' && enableBackToAction) {
      enableBackToAction()
    }
  }, [forgotPasswordResponse])

  return (
    <div className={ForgotPasswordClasses} {...restProps}>
      {!forgotPasswordResponse && (
        <>
          {!hideHeader && (
            <span className='text-N-700 text-display-h2-m md:text-display-h2 font-display uppercase mb-[16px]'>
              Have you forgotten your password?
            </span>
          )}
          <span className='text-N-400 text-base mb-[24px]'>
            Please enter your email address below. An email will be sent with a link for you to
            reset your password.
          </span>
          <div className='flex flex-col'>
            <Formik
              initialValues={{
                email: ''
              }}
              isInitialValid={false}
              validationSchema={forgotPasswordSchema}
              onSubmit={async values => {
                setEmail(values.email)
                await forgotPasswordAsync({
                  emailAddress: values.email
                })
              }}>
              {({ errors, touched, handleChange, handleBlur, values, isValid, isSubmitting }) => (
                <Form>
                  <div className='flex flex-col'>
                    <TextField
                      componentId='email'
                      wrapperClassName='mb-[20px] w-full'
                      hint={errors.email && touched.email ? errors.email : ''}
                      hasError={!!(errors.email && touched.email)}
                      label='Email Address'
                      placeholder=''
                      value={values.email}
                      onChange={handleChange('email')}
                      onBlur={handleBlur('email')}
                    />
                    <Button
                      componentId='forgot-password-submit'
                      type='submit'
                      appearance='primary-orange'
                      size='md'
                      isLoading={isSubmitting}
                      disabled={!isValid || isSubmitting || isLoadingForgotPasswordAction}>
                      Submit
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </>
      )}

      {/** Hard corded Message when Forgot Password Is Success */}
      {forgotPasswordResponse?.messageType === 'success' && (
        <OnScreenMessage type='success' header='Reset Email Sent'>
          <div className='text-base font-normal text-N-500'>
            Thank you, If the email address exists, instructions to reset your password have been
            emailed. <br />
            <br />
            If you do not receive this email please contact our Customer Care Centre on{' '}
            <span
              tabIndex={0}
              role='button'
              className='font-medium text-O-500 cursor-pointer'
              onClick={() => {
                window.location.href = 'tel:0800660660'
              }}
              onKeyDown={(e: any) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  window.location.href = 'tel:0800660660'
                }
              }}>
              0800 660 660.
            </span>
          </div>
        </OnScreenMessage>
      )}
      {(forgotPasswordResponse?.messageType === 'error' ||
        forgotPasswordResponse?.messageType === 'warning') &&
        !resendActivationLinkResponse && (
          <OnScreenMessage
            header='ATTENTION!'
            subHeader={forgotPasswordResponse?.messageTitle}
            description={forgotPasswordResponse?.messageDescription}
            actionButtonProps={{
              children:
                forgotPasswordResponse?.messageType === 'warning'
                  ? 'Resend Activation Email'
                  : 'CREATE ACCOUNT',
              componentId:
                forgotPasswordResponse?.messageType === 'warning'
                  ? 'forgotPasswordResendBtn'
                  : 'forgotPasswordCreateAccountBtn',
              onClick:
                forgotPasswordResponse?.messageType === 'warning' ? resendLink : openCreateAccount,
              appearance: 'secondary-orange',
              isLoading:
                forgotPasswordResponse?.messageType === 'warning' ? isLoadingActivationLink : false
            }}
            type={forgotPasswordResponse?.messageType}
          />
        )}
      {resendActivationLinkResponse && !isLoadingActivationLink && resendActivationLinkResponse && (
        <OnScreenMessage
          className='px-[12px] pb-[12px]'
          header={
            resendActivationLinkResponse?.messageType === 'success'
              ? 'Reset Email Sent'
              : 'Attention!'
          }
          type={resendActivationLinkResponse?.messageType}
          subHeader={resendActivationLinkResponse?.messageTitle}
          description={resendActivationLinkResponse?.messageDescription}
        />
      )}
    </div>
  )
}

ForgotPassword.defaultProps = {
  className: undefined
}

export default ForgotPassword
