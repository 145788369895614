/* eslint-disable import/no-extraneous-dependencies */
import React, { FC, useState } from 'react'
import { Column, HeaderGroup, Row, useTable } from 'react-table'
import { Select } from '@nzsb/shopnx-ui'
import CN from 'classnames'

import { Pagination } from 'components/molecules/Pagination'

export interface IDataTableProps {
  className?: string
  columns: Column[]
  data: any
  enablePagination?: boolean
  // Pagination Props - per page component
  perPageSelections?: string[]
  defaultSelectedOption?: string
  onPerPageChange?: (count: number) => void
  // Pagination Props - page numbers component
  showingCount?: number
  totalCount?: number
  paginationProps?: {
    totalPages: number
    pageIndex: number
    pageSize: number
    resultsCount: number
    siblingCount?: number
    limitedPerPageCount?: boolean | undefined
    onPageChange?: (page: number) => void
  }
}

const PAGE_COUNT_SELECTOR_DATA = [
  {
    value: '10',
    label: '10'
  },
  {
    value: '30',
    label: '30'
  },
  {
    value: '50',
    label: '50'
  },
  {
    value: '100',
    label: '100'
  },
  {
    value: '300',
    label: '300'
  }
]

export const DataTable: FC<IDataTableProps> = ({
  className,
  columns,
  defaultSelectedOption,
  data,
  enablePagination,
  perPageSelections,
  showingCount,
  totalCount,
  paginationProps,
  onPerPageChange,
  ...restProps
}: IDataTableProps) => {
  const DataTableClasses = CN(`data-table border-spacing-0 border-collapse w-full`, className)

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data
  })
  const [selectedOption, setSelectedOption] = useState(
    defaultSelectedOption
      ? { label: defaultSelectedOption, value: defaultSelectedOption }
      : PAGE_COUNT_SELECTOR_DATA[0]
  )

  return (
    <div className={CN('w-full', { 'border border-N-50': enablePagination })}>
      <div className='overflow-x-auto'>
        <table {...getTableProps()} className={DataTableClasses} {...restProps}>
          <thead>
            {headerGroups.map(({ id, ...headerGroup }: HeaderGroup, index: number) => {
              return (
                <tr {...headerGroup.getHeaderGroupProps()} key={`${id} - ${index}`}>
                  {headerGroup.headers.map(({ id: colId, ...column }: any) => {
                    return (
                      <th
                        {...column.getHeaderProps()}
                        key={colId}
                        data-component-id='table-header'
                        style={{ display: column?.isVisible ? 'table-cell' : 'none' }} // Set display style based on column visibility
                        className={CN(
                          'py-[8px] px-[16px] text-xs font-700 text-N-800 bg-N-25 leading-[16px] m-0 lea border-b border-b-N-100 box-border capitalize',
                          column?.cellHeaderClassName
                        )}>
                        <span
                          data-component-id={`table-header-${column.render('Header')}`}
                          className='flex'>
                          {column.render('Header')}
                        </span>
                      </th>
                    )
                  })}
                </tr>
              )
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row: Row) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()} key={row.id} className='hover:bg-N-25'>
                  {row.cells.map((cell: any) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={`${cell.row.id}-${cell.column.id}`}
                        data-component-id={`table-cell-${row.id}`}
                        className={CN(
                          'py-[8px] px-[16px] font-medium text-sm m-0 border-b text-N-600 border-b-N-100 leading-[20px]',
                          cell?.column?.cellClassName
                        )}>
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      {enablePagination && (
        <div className='flex justify-between w-full px-[24px] py-[16px]'>
          <div
            data-component-id='page-count-selector'
            className={CN(
              'page-count-selector flex flex-col lg:!flex-row w-full lg:!items-center gap-[8px]',
              className
            )}
            {...restProps}>
            <p data-component-id='page-count-selector-View' className='font-medium text-N-500'>
              View
            </p>
            <Select
              className='w-[100px]'
              isClearable={false}
              isSearchable={false}
              onChange={(selection: any) => {
                setSelectedOption(selection)
                if (onPerPageChange) {
                  onPerPageChange(selection?.value)
                }
              }}
              value={selectedOption}
              data={
                perPageSelections
                  ? perPageSelections.map(option => ({ label: option, value: option }))
                  : paginationProps?.limitedPerPageCount
                    ? PAGE_COUNT_SELECTOR_DATA?.slice(0, 3)
                    : PAGE_COUNT_SELECTOR_DATA
              }
            />
            <p
              data-component-id='page-count-selector-items-per-page-text'
              className='font-medium text-N-500 hidden lg:!block'>
              items per page
            </p>
          </div>
          <div className='flex flex-col lg:!flex-row w-full items-end lg:!items-center justify-end gap-[8px]'>
            <div data-component-id='page-numbers' className='flex page-numbers flex-row'>
              <p className='page-numbers-text font-medium text-N-500 whitespace-nowrap'>
                Showing to {showingCount} of {totalCount} Items
              </p>
            </div>
            <Pagination
              totalPages={paginationProps?.totalPages || 1}
              pageSizes={paginationProps?.pageSize}
              resultsCount={totalCount}
              pageIndex={paginationProps?.pageIndex || 1}
              onPageChange={paginationProps?.onPageChange}
              siblingCount={1}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default DataTable
