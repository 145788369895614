import { useMutation } from '@tanstack/react-query'
import { httpGET } from 'lib/http'

export interface IAccountPayConfirmationRequest {
  orderId: string
}

/* API Request */
export const accountPayConfirmationRequest = async (req: IAccountPayConfirmationRequest) => {
  const response = await httpGET({
    type: 'internal',
    url: `/checkout/accountPayConfirmation?orderId=${req.orderId}`
  })
  return response
}

/* Client side react-query custom hook */
export const useAccountPayConfirmation = () => {
  const { mutateAsync, ...restOptions } = useMutation({
    mutationFn: accountPayConfirmationRequest
  })

  // Passing mutateAsync as use emailVerification
  // and restOptions as rest of the options
  return { accountPayConfirmation: mutateAsync, ...restOptions }
}
