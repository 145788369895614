import { useMutation } from '@tanstack/react-query'
import { httpDELETE } from 'lib/http'

import { useGetCustomerAddresses } from './getCustomerAddresses'

export interface IDeleteAddressResponse {
  messageTitle: string | null
  messageDescription: string | undefined
  messageType: string | undefined
  messageCode: string
  data: any
}

export interface IDeleteAddressReq {
  id: string
}

export const deleteAddress = async (req: IDeleteAddressReq) => {
  const data = await httpDELETE({
    url: `customer/deleteaddress/${req.id}`
  })

  return data?.data as IDeleteAddressResponse
}

export const useDeleteAddress = () => {
  const { getCustomerAddresses } = useGetCustomerAddresses()
  /* Use Query */
  const { data, mutateAsync, ...restOptions } = useMutation({
    mutationFn: async (req: IDeleteAddressReq) => {
      const res = await deleteAddress({ ...req })
      getCustomerAddresses()
      return res
    }
  })

  return { deleteAddressResponse: data, deleteAddressAsync: mutateAsync, ...restOptions }
}
