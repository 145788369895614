import Link from 'next/link'
import React, { FC } from 'react'
import CN from 'classnames'
import { checkURL } from 'lib/utilities'

export interface IFeatureBarItem {
  IconText: string
  DisplayText: string
  URL?: string
}

export interface IFeatureBarSectionProps {
  className?: string
  componentId?: string
  featuresList?: Array<IFeatureBarItem>
}

export const FeatureBarSection: FC<IFeatureBarSectionProps> = ({
  className,
  featuresList = [],
  componentId,
  ...restProps
}: IFeatureBarSectionProps) => {
  const FeatureBarSectionClasses = CN(`${componentId}`, className)

  const mappedFeatureBarItems = featuresList?.map((item: any) => {
    const {
      originalUrl: categoryOriginalUrl,
      restUrlWithQuery: categoryQuery,
      isInsideUrl: categoryUrlIsInternal
    } = checkURL(item?.URL ?? '')
    return {
      ...item,
      url: categoryUrlIsInternal ? categoryQuery : categoryOriginalUrl,
      target: categoryUrlIsInternal ? '' : '_blank'
    }
  })

  return (
    <div className={FeatureBarSectionClasses} {...restProps}>
      {mappedFeatureBarItems?.length > 0 && (
        <div className='flex flex-col md:flex-row justify-between py-[14px] gap-y-4 md:gap-y-0 break-words gap-x-5'>
          {mappedFeatureBarItems?.map((item, index) => (
            /** Feature bar icon */
            <Link
              data-component-id={`${componentId}-icon-${index}`}
              passHref
              href={item?.url ?? {}}
              key={item?.id}
              target={item.target}
              className={CN('flex gap-x-3 items-center md:w-1/3', {
                'cursor-pointer': item?.url,
                'cursor-auto pointer-events-none': !item?.url
              })}>
              {item.IconText && (
                <div className='!w-10 !h-10 rounded-full bg-B-500 flex !justify-center !items-center p-2'>
                  <i className={`${item.IconText} text-white text-[28px]`} />
                </div>
              )}

              {/** Feature bar display text */}
              <p
                data-component-id={`${componentId}-display-text-${index}`}
                className='font-500 text-base text-N-800'>
                {item.DisplayText}
              </p>
            </Link>
          ))}
        </div>
      )}
    </div>
  )
}

export default FeatureBarSection
