import React, { FC } from 'react'
import { Lozenge, LozengeProps, priceFormat } from '@nzsb/shopnx-ui'
import CN from 'classnames'
export interface PriceVariationProps {
  [x: string]: any
  className?: string
  componentId?: string
  productFlag?: 0 | 1 | 2 | 3 | 4 | 5
  lozengeClass?: string
  price: number | string
  gstPrice: number | string
  showGstPricePrimary?: boolean
}

export const PriceVariation: FC<PriceVariationProps> = ({
  className,
  componentId,
  productFlag,
  lozengeClass,
  price,
  gstPrice,
  showGstPricePrimary,
  ...restProps
}: PriceVariationProps) => {
  const PriceVariationClasses = CN(`${componentId}-priceVariation flex flex-col`, className)

  const flagTypeMap = [
    { enum: 0, displayValue: '', appearance: '', variant: '' },
    {
      enum: 1,
      displayValue: 'Clearance',
      appearance: 'outline',
      variant: 'danger'
    },
    { enum: 2, displayValue: 'Deal', appearance: 'fill', variant: 'danger' },
    {
      enum: 3,
      displayValue: 'RESTRICTED SUPPLY',
      appearance: 'fill',
      variant: 'warning-1'
    },
    {
      enum: 4,
      displayValue: 'TEMPORARILY unavailable',
      appearance: 'fill',
      variant: 'neutral'
    },
    { enum: 5, displayValue: 'New', appearance: 'fill', variant: 'info' }
  ]

  return (
    <div className={PriceVariationClasses} {...restProps}>
      <div
        data-component-id={`${componentId}-priceVariation-price-section`}
        className={CN(`flex`, {
          'text-B-500':
            productFlag === 0 || productFlag === 3 || productFlag === 4 || productFlag === 5,
          'text-R-500': productFlag === 1 || productFlag === 2
        })}>
        <span className='text-base font-bold pr-[2px] pt-[4px]'>$</span>

        <span className='flex font-bold text-h2 pr-[2px]'>
          {showGstPricePrimary
            ? gstPrice && priceFormat(gstPrice?.toString()?.split('.')[0], 0)
            : price && priceFormat(price?.toString()?.split('.')[0], 0)}
        </span>

        <span className='text-base font-bold pl-[2px] pt-[4px]'>
          {showGstPricePrimary
            ? Number(gstPrice)?.toFixed(2)?.toString().split('.')[1]
            : Number(price)?.toFixed(2)?.toString().split('.')[1]}
        </span>
      </div>

      <div
        data-component-id={`${componentId}-priceVariation-gst-price-section`}
        className='font-medium text-xs text-N-500'>
        {showGstPricePrimary
          ? price && `$${priceFormat(price, 2)} excl. GST`
          : gstPrice && `$${priceFormat(gstPrice, 2)} incl. GST`}
      </div>

      {productFlag !== undefined && productFlag > 0 && (
        <div className='pt-2'>
          <Lozenge
            className={CN(
              `${componentId}-flag uppercase`,
              {
                'whitespace-nowrap': productFlag === 4
              },
              lozengeClass
            )}
            isFlag={false}
            variant={flagTypeMap[productFlag].variant as LozengeProps['variant']}
            appearance={flagTypeMap[productFlag].appearance as LozengeProps['appearance']}>
            {flagTypeMap[productFlag].displayValue}
          </Lozenge>
        </div>
      )}
    </div>
  )
}

export default PriceVariation
