/* eslint-disable import/no-extraneous-dependencies */
import React, { FC } from 'react'
import CN from 'classnames'

import { NextImage } from 'components/atoms'

export interface IBrandLandingPageLogoProps {
  className?: string
  componentId?: string
  headerImageData?: any
}

export const BrandLandingPageLogo: FC<IBrandLandingPageLogoProps> = ({
  className,
  componentId,
  headerImageData,
  ...restProps
}: IBrandLandingPageLogoProps) => {
  const BrandLandingPageLogoClasses = CN(`brand-landing-page-logo w-full text-center`, className)

  return (
    <div className={BrandLandingPageLogoClasses} {...restProps}>
      <div className='flex justify-center w-full h-[132px]'>
        <NextImage
          nextImgStyle={{
            objectFit: 'fit'
          }}
          data-component-id={`${componentId}-header-logo`}
          imgKey={headerImageData?.data?.id}
          imageUrl={headerImageData?.data?.attributes?.provider_metadata?.public_id}
          system='CMS'
          useWithDimensions={true}
          width={952}
          height={132}
          alt={headerImageData?.data?.attributes?.alternativeText || 'brand-log'}
          {...restProps}
        />
      </div>
    </div>
  )
}

export default BrandLandingPageLogo
