import { useMutation } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

export interface ISearchByProductsReq {
  search: string
  pageIndex?: number
  pageSize?: number
  sort?: {
    sortBy: string
    sortType: string
  }
}

export interface ISearchByProduct {
  id: number
  sku: string
  title: string
  urlSlug: string
}

export interface ISearchByProductsRes {
  data: {
    products: Array<ISearchByProduct>
  }
  messageDescription: null | string
  messageTitle: string
  messageType: string
}

export const fetchProductBySearchResults = async (req: ISearchByProductsReq) => {
  if (req?.search === '') return { data: { products: [] } }
  const data = await httpPOST({
    url: 'getproducts',
    body: req
  })

  return data?.data as ISearchByProductsRes
}

export const useProductBySearch = () => {
  /* Use Query */
  const { data, mutateAsync, ...restOptions } = useMutation({
    mutationFn: fetchProductBySearchResults
  })

  return { productsBySearch: data, ProductsBySearchAsync: mutateAsync, ...restOptions }
}

export default useProductBySearch
