import { useRouter } from 'next/router'
import React, { FC } from 'react'
import { NZLogo } from '@nzsb/shopnx-ui'
import CN from 'classnames'

export interface IMinimalHeaderProps {
  className?: string
  children?: React.ReactNode
  pageTitle: string
}

export const MinimalHeader: FC<IMinimalHeaderProps> = ({
  className,
  children,
  pageTitle,
  ...restProps
}: IMinimalHeaderProps) => {
  const router = useRouter()

  const MinimalHeaderClasses = CN(
    `header py-[24px] bg-white flex flex-col items-center justify-center`,
    className
  )

  return (
    <header className={MinimalHeaderClasses} {...restProps}>
      <NZLogo
        appearance='default'
        variant='horizontal'
        onClick={() => {
          router.push('/')
        }}
        className='cursor-pointer w-auto max-w-[235px] sm:!max-w-[246px] md:max-w-[342px]'
      />
      <h2 className='uppercase text-display-h2-m md:text-display-h2 text-center text-N-700 font-400 font-display'>
        {pageTitle}
      </h2>
    </header>
  )
}

export default MinimalHeader
