import { httpGET } from 'lib/http'

export interface IAddressResponse {
  customerAddress: {
    id: string
    firstName: string
    lastName: string
    contactNumber: string
    businessName: string
    addressDetails: string
    streetAddress: string
    city: string
    suburb: string
    country: string
    postCode: string
    latitude: number
    longitude: number
    attentionTo: string
    isDefulat: boolean
    isBillingAddress: boolean
    isShippingAddress: boolean
  }
}

export const getAddressDetails = async (id: string) => {
  const { data } = await httpGET({
    url: `/addresses/getdetails/${id}`
  })
  return data?.data as IAddressResponse
}
