import React, { FC } from 'react'
import CN from 'classnames'

export interface MobileMenuItemProps {
  className?: string
  componentId?: string
  iconAfterClassName?: string
  iconBefore?: string
  iconBeforeClassName?: string
  isIconAfter?: boolean
  menuItemClassName?: string
  menuItemName?: string
  onMainMenuItemClick?: () => void
}

export const MobileMenuItem: FC<MobileMenuItemProps> = ({
  className,
  componentId,
  iconAfterClassName,
  iconBefore,
  iconBeforeClassName,
  isIconAfter,
  menuItemClassName,
  menuItemName,
  onMainMenuItemClick,
  ...restProps
}: MobileMenuItemProps) => {
  const MobileMenuItemClasses = CN(`w-full px-4 py-[14px] rounded`, className)

  return (
    <div
      tabIndex={0}
      role='button'
      data-component-id={`${componentId}-section`}
      className={MobileMenuItemClasses}
      onClick={onMainMenuItemClick}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          onMainMenuItemClick?.()
        }
      }}
      {...restProps}>
      <div className='flex gap-x-2'>
        <div className='w-5 h-5'>
          {iconBefore && (
            <i
              data-component-id={`${componentId}-section-before-icon`}
              className={`${iconBefore} ${iconBeforeClassName}`}
            />
          )}
        </div>

        <span data-component-id={`${componentId}-section-item-name`} className={menuItemClassName}>
          {menuItemName}
        </span>

        {isIconAfter && (
          <i
            data-component-id={`${componentId}-section-after-icon`}
            className={`nzsbi-chevron-right ${iconAfterClassName}`}
          />
        )}
      </div>
    </div>
  )
}

export default MobileMenuItem
