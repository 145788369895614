import React, { FC } from 'react'
import { GoogleCaptcha } from '@nzsb/shopnx-ui'

import { SignUpForm } from './SignUpForm'

export interface IRegisterProps {
  [x: string]: any
  className?: string
  hideHeader?: boolean
}

export const SignUp: FC<IRegisterProps> = ({ ...restProps }: IRegisterProps) => {
  return (
    <GoogleCaptcha className='w-full' reCaptchaKey={process.env.NEXT_PUBLIC_GOOGLE_SITE_KEY}>
      <SignUpForm {...restProps} />
    </GoogleCaptcha>
  )
}

SignUp.defaultProps = {
  className: undefined
}

export default SignUp
