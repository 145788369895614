import React, { FC } from 'react'
import CN from 'classnames'

import { AddressCard, AddressProps } from 'components/molecules/AddressCard'
export interface InvoiceAddressSectionProps {
  className?: string
  componentId?: string
  data: AddressProps[]
}

export const InvoiceAddressSection: FC<InvoiceAddressSectionProps> = ({
  className,
  componentId,
  data,
  ...restProps
}: InvoiceAddressSectionProps) => {
  const InvoiceAddressSectionClasses = CN(`${componentId}-address-section flex flex-col`, className)

  return (
    <div className={InvoiceAddressSectionClasses} {...restProps}>
      <h3 className='font-600 text-h3'>Invoice Address</h3>
      <span className='text-N-500 font-400 pt-1 pb-8'>
        This is the invoice address for your credit account. If you require a change to this address
        please contact our credit application team on 0800 252 259.
      </span>

      <div className='grid lg:grid-cols-2 gap-5 grid-cols-1'>
        {data?.map(address => (
          <AddressCard key={address.id} componentId={`${componentId}`} data={address} />
        ))}
      </div>
    </div>
  )
}

export default InvoiceAddressSection
