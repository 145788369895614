import React, { FC, useCallback, useMemo, useState } from 'react'
import {
  Button,
  ButtonProps,
  debounce,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  TextField,
  TextFieldProps
} from '@nzsb/shopnx-ui'
import CN from 'classnames'

export interface IRenameCartModalProps {
  className?: string
  title: string
  description?: string
  onClose?: () => void
  onCancel: () => void
  onConfirm: (cartName: string) => Promise<any>
  onSuccess?: () => void
  textFieldProps?: TextFieldProps
  cancelButtonProps?: ButtonProps
  confirmButtonProps?: ButtonProps
  isLoading?: boolean
  confirmButtonTitle?: string
  cancelButtonTitle?: string
}

export const RenameCartModal: FC<IRenameCartModalProps> = ({
  className,
  title,
  description,
  onClose,
  onCancel,
  onConfirm,
  onSuccess,
  textFieldProps,
  isLoading,
  cancelButtonProps,
  confirmButtonTitle = 'RENAME ORDER',
  cancelButtonTitle = 'CANCEL',
  confirmButtonProps
}: IRenameCartModalProps) => {
  //const notify = (props: AlertProps) => Toast(props)
  const [saveCartName, setSaveCartName] = useState('')

  const [alphaNumericError, setAlphaNumericError] = useState(false)
  const [characterLimitError, setCharacterLimitError] = useState(false)
  const [alreadyExistsSameNameError, setAlreadyExistsSameNameError] = useState(false)

  /* Reset local errors */
  const resetErrors = () => {
    setAlphaNumericError(false)
    setCharacterLimitError(false)
    setAlreadyExistsSameNameError(false)
  }

  /* Render error messages order name hint */
  const renderErrorMessagesHint = useMemo(() => {
    if (alphaNumericError) {
      return (
        <span>
          Sorry, only use letters (A-Z, a-z) and numbers (0-9). Special characters are not allowed.
        </span>
      )
    }

    if (characterLimitError) {
      return <span>Sorry, Please keep the order name within 100 characters.</span>
    }

    if (alreadyExistsSameNameError) {
      return <span>Sorry, there is an order already saved with this name. Please try another.</span>
    }
    // Add a default case that returns null
    return null
  }, [alphaNumericError, characterLimitError, alreadyExistsSameNameError])
  /**
   * Close rename modal action handler
   */
  const closeModal = () => {
    setSaveCartName('')
    if (onClose) {
      onClose()
    }
  }

  /**
   * On confirm action handler
   */
  const onConfirmAction = async () => {
    resetErrors()
    const response = await onConfirm(saveCartName)
    if (response !== undefined) {
      if (response?.messageType === 'success') {
        if (onSuccess) {
          onSuccess()
        }

        // notify({
        //   status: response?.data.messageType === 'success' ? 'Success' : 'Error',
        //   alertHeader: response?.data?.messageTitle
        // })

        setSaveCartName('')
      }

      if (
        response?.messageType === 'warning' &&
        response?.messageTitle ===
          'Sorry, there is a order already saved with this name. Please try another.'
      ) {
        setAlreadyExistsSameNameError(true)

        // notify({
        //   status: response?.data.messageType === 'warning' ? 'Warning' : 'Warning',
        //   alertHeader: response?.data?.messageTitle
        // })
      }

      if (response?.messageType === 'error') {
        setAlreadyExistsSameNameError(true)

        // notify({
        //   status: response?.data.messageType === 'error' ? 'Error' : 'Error',
        //   alertHeader: 'Sorry! We couldn’t save this order. Please try again.'
        // })
      }
    }
  }

  /**
   * On cancel action handler
   */
  const onCancelAction = () => {
    closeModal()
    if (onCancel) {
      onCancel()
    }
  }

  /** Run form validation with a delay */
  const debouncedValidation = useCallback(
    debounce((e: any) => {
      resetErrors()
      const numberAndCharacterFormat = /^[A-Za-z0-9 ]+$/
      if (e.target.value.length > 0) {
        if (!numberAndCharacterFormat.test(e.target.value ?? '')) {
          setAlphaNumericError(true)
        } else if (e.target.value.length > 100) {
          setCharacterLimitError(true)
        }
      } else {
        resetErrors()
      }
    }, 500),
    []
  )

  return (
    <Modal
      componentId='orderpad-name-modal'
      isOpen={true}
      className={CN('z-20 w-[380px]', className)}>
      <ModalHeader
        componentId='orderpad-name-modal-header'
        className='border-[#D9D9D5] bg-N-25'
        hasBorder={true}
        heading={title}
        onClose={closeModal}
      />
      <ModalBody componentId='orderpad-name-modal-body' className='p-[20px]'>
        <div className='flex flex-col w-full gap-[16px]'>
          {description && (
            <p className='flex justify-start text-base font-sans font-normal text-N-700'>
              {description}
            </p>
          )}
          <TextField
            wrapperClassName='w-full'
            componentId='orderpad-name-modal-name'
            label='Order Pad Name'
            value={saveCartName}
            onChange={(e: any) => {
              setSaveCartName(e.target.value)
              debouncedValidation(e)
              if (e.target.value.length === 0) {
                resetErrors()
              }
            }}
            hint={renderErrorMessagesHint}
            hasError={alphaNumericError || characterLimitError || alreadyExistsSameNameError}
            {...textFieldProps}
          />
        </div>
      </ModalBody>
      <ModalFooter componentId='orderpad-name-modal-footer'>
        <div className='flex gap-[16px]'>
          <Button
            componentId='orderpad-name-modal-discard-btn'
            appearance='secondary-gray'
            onClick={onCancelAction}
            {...cancelButtonProps}>
            {cancelButtonTitle}
          </Button>
          <Button
            componentId='orderpad-name-modal-update-btn'
            appearance='primary-orange'
            className='mr-2'
            isLoading={isLoading}
            disabled={
              saveCartName.length === 0 ||
              alphaNumericError ||
              characterLimitError ||
              alreadyExistsSameNameError
            }
            onClick={onConfirmAction}
            {...confirmButtonProps}>
            {confirmButtonTitle}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}
