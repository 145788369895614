import React, { FC } from 'react'
import CN from 'classnames'

import { ModalsCollection } from 'components/modals'
import { Footer } from 'components/organisms'
import { Header } from 'components/organisms/Header/Header'

export interface IGlobalLayoutProps {
  [x: string]: any
  className?: string
  children?: React.ReactNode
}

export const GlobalLayout: FC<IGlobalLayoutProps> = ({
  className,
  children,
  ...restProps
}: IGlobalLayoutProps) => {
  const GlobalLayoutClasses = CN(
    `global-layout flex flex-col min-h-[100vh] justify-between`,
    className
  )

  return (
    <div className={GlobalLayoutClasses} {...restProps}>
      <div>
        <Header className='app__header' />
        <main className='app__body'>{children}</main>
        <ModalsCollection />
      </div>
      <Footer />
    </div>
  )
}

export default GlobalLayout
