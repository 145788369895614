import { useCallback } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useAppContext } from 'lib/contexts/app-context'
import { httpGET } from 'lib/http'

export interface IGetCartResponse {
  messageTitle: string | null
  messageDescription: string | null
  messageType: string
  data: IGetCart | any
}

export interface IGetCart {
  cartId: string
  noOfItems: number
}

export const getCart = async (context?: any) => {
  const data = await httpGET({
    url: `/cart/getCart`,
    type: 'internal',
    endpoint: 'api',
    context
  })
  return data as IGetCartResponse
}

export const useGetCart = () => {
  const { cart } = useAppContext()
  const updateQuickCartContext = useCallback((quickCartInfo: IGetCart) => {
    cart?.setQuickCartIconInfo(quickCartInfo)
  }, [])
  return useQuery({
    queryKey: ['GET_CART'],
    queryFn: async (context: any) => {
      const quickCart = await getCart(context)
      updateQuickCartContext({ ...quickCart?.data })
      return quickCart
    },
    enabled: false,
    refetchOnWindowFocus: false
  })
}
