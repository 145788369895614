import { FC } from 'react'
import CN from 'classnames'
import { IAllCategory } from 'lib/actions'

export interface IMenuButtonProps {
  index: number
  menuItem: IAllCategory
  onClickItem?: (item: IAllCategory) => void
  onHoverItem?: (item: IAllCategory, index: number) => void
  selected?: boolean
}

/* MenuButton component */
export const MenuButton: FC<IMenuButtonProps> = ({
  index,
  menuItem,
  onClickItem,
  onHoverItem,
  selected
}: IMenuButtonProps) => {
  return (
    <button
      id={`mega-menu-item-${index}`}
      className={CN(
        'w-full font-400 rounded text-start text-sm px-3 py-2 text-N-700 hover:bg-B-500 hover:text-white focus:shadow-[0px_0px_0px_4px_rgba(160,196,233,0.50)]',
        {
          'bg-B-500 text-white': selected,
          'pointer-events-none': !onClickItem || menuItem.disabled,
          'bg-N-100 text-N-400': menuItem.disabled
        }
      )}
      disabled={menuItem.disabled}
      onMouseOver={() => {
        if (onHoverItem) onHoverItem(menuItem, index)
      }}
      onClick={() => onClickItem && onClickItem(menuItem)}>
      <div className='flex gap-2 items-center justify-between'>
        <div className='flex gap-2 items-center'>
          <div className='w-5 h-5'>
            {menuItem.icon && (
              <i className={CN(`flex items-center justify-center text-[20px] ${menuItem.icon}`)} />
            )}
          </div>

          {menuItem.name}
        </div>
        <i
          className={CN('h-5 w-5 flex items-center justify-center text-[12px] nzsbi-chevron-right')}
        />
      </div>
    </button>
  )
}
