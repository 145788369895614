import React, { FC } from 'react'
import CN from 'classnames'

export interface IOctagonWrapperProps {
  className?: string
  componentId?: string
  icon: string
  disabled?: boolean
  wrapperBackgroundColor?: string
  iconColor?: string
  wrapperBorderColor?: string
}

export const OctagonWrapper: FC<IOctagonWrapperProps> = ({
  className,
  componentId,
  disabled,
  icon,
  wrapperBackgroundColor = 'white',
  iconColor = 'text-B-500',
  wrapperBorderColor = '#D0D5DD',
  ...restProps
}: IOctagonWrapperProps) => {
  const OctagonWrapperClasses = CN(`${componentId}-octagon-wrapper`, className)

  return (
    <div className={OctagonWrapperClasses} {...restProps}>
      <i className={CN(`absolute ${icon} text-[40px] ${iconColor}`, { 'opacity-50': disabled })} />
      <svg viewBox='0 0 64 65' width={64} height={64} fill='red'>
        <mask id='path-1-inside-1_2090_53381' fill={wrapperBackgroundColor}>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M0.5 8L8.5 0L56.5 7.07319e-07L64.5 8V56L56.5 64H8.5L0.5 56V8Z'
          />
        </mask>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0.5 8L8.5 0L56.5 7.07319e-07L64.5 8V56L56.5 64H8.5L0.5 56V8Z'
          fill={wrapperBackgroundColor}
        />
        <path
          d='M8.5 0V-1.20795H7.99965L7.64585 -0.854147L8.5 0ZM0.5 8L-0.354148 7.14585L-0.707947 7.49965V8H0.5ZM56.5 7.07319e-07L57.3541 -0.854147L57.0003 -1.20795H56.5V7.07319e-07ZM64.5 8H65.7079V7.49965L65.3541 7.14585L64.5 8ZM64.5 56L65.3541 56.8541L65.7079 56.5003V56H64.5ZM56.5 64V65.2079H57.0003L57.3541 64.8541L56.5 64ZM8.5 64L7.64585 64.8541L7.99965 65.2079H8.5V64ZM0.5 56H-0.707947V56.5003L-0.354147 56.8541L0.5 56ZM7.64585 -0.854147L-0.354148 7.14585L1.35415 8.85415L9.35415 0.854147L7.64585 -0.854147ZM56.5 -1.20795L8.5 -1.20795V1.20795L56.5 1.20795V-1.20795ZM65.3541 7.14585L57.3541 -0.854147L55.6459 0.854148L63.6459 8.85415L65.3541 7.14585ZM65.7079 56V8H63.2921V56H65.7079ZM57.3541 64.8541L65.3541 56.8541L63.6459 55.1459L55.6459 63.1459L57.3541 64.8541ZM8.5 65.2079H56.5V62.7921H8.5V65.2079ZM-0.354147 56.8541L7.64585 64.8541L9.35415 63.1459L1.35415 55.1459L-0.354147 56.8541ZM-0.707947 8V56H1.70795V8H-0.707947Z'
          fill={wrapperBorderColor}
          mask='url(#path-1-inside-1_2090_53381)'
        />
      </svg>
    </div>
  )
}

export default OctagonWrapper
