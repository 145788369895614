import { useMutation } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

import { useGetCustomerAddresses } from './getCustomerAddresses'

export interface IAddNewAddressResponse {
  messageTitle: string
  messageDescription: string
  messageType: string
  messageCode: string
  data: any
}

export interface IAddNewAddressReq {
  streetAddress: string
  suburb: string
  city: string
  postcode: string
  attentionTo: string
  isDefaultAddress: boolean
}

export const addNewAddress = async (req: IAddNewAddressReq) => {
  const data = await httpPOST({
    url: 'customer/addaddress',
    body: {
      ...req,
      isDefault: req.isDefaultAddress
    }
  })

  return data?.data as IAddNewAddressResponse
}

export const useAddNewAddress = (isEnableListRefetch: boolean = true) => {
  const { getCustomerAddresses } = useGetCustomerAddresses()
  /* Use Query */
  const { data, mutateAsync, ...restOptions } = useMutation({
    mutationFn: async (req: IAddNewAddressReq) => {
      const res = await addNewAddress({ ...req })
      if (isEnableListRefetch) {
        getCustomerAddresses()
      }
      return res
    }
  })

  return { addNewAddressResponse: data, addNewAddressAsync: mutateAsync, ...restOptions }
}
