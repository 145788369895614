import Link from 'next/link'
import React, { FC } from 'react'
import CN from 'classnames'
import { IBannerItem } from 'lib/actions'
import { checkURL } from 'lib/utilities'

import { NextImage } from 'components/atoms'

export interface BrandBannerProps {
  bannerData: IBannerItem
  className?: string
  componentId?: string
  height?: number
  imageCustomClassName?: string
  mobileHeight?: number
  mobileImageCustomClassName?: string
  mobileWidth?: number
  overlayCustomClassName?: string
  useWithDimensions?: boolean
  width?: number
  [x: string]: any
}

export const BrandBanner: FC<BrandBannerProps> = ({
  bannerData,
  className,
  componentId,
  height,
  imageCustomClassName,
  mobileHeight,
  mobileImageCustomClassName,
  mobileWidth,
  overlayCustomClassName,
  useWithDimensions,
  width,
  ...restProps
}: BrandBannerProps) => {
  const BrandBannerClasses = CN(`py-10 sm:py-10 lg:py-[26px] relative`, className)

  const {
    Header,
    SubText,
    DesktopOverlay,
    MobileOverlay,
    URL,
    Desktop: {
      data: { attributes: desktopAttributes }
    },
    Mobile: {
      data: { attributes: mobileAttributes }
    }
  } = bannerData

  const { originalUrl, restUrlWithQuery, isInsideUrl } = checkURL(URL ?? '')

  return (
    <div data-component-id={`${componentId}`} className={BrandBannerClasses} {...restProps}>
      <Link
        href={isInsideUrl ? restUrlWithQuery : originalUrl ?? {}}
        passHref
        target={isInsideUrl ? '' : '_blank'}
        className={CN({
          'cursor-pointer': originalUrl,
          'cursor-auto pointer-events-none': !originalUrl
        })}>
        <div
          data-component-id={`${componentId}-desktop-overlay`}
          className={CN('h-[76px] w-auto lg:h-[272px] relative hidden sm:block', {
            'sm:h-[144px]': useWithDimensions,
            'sm:h-[142px]': !useWithDimensions
          })}>
          {(Header || SubText) && DesktopOverlay ? (
            <div
              className={CN(
                'absolute w-full h-full bg-gradient-to-l from-black z-10 from-40%',
                overlayCustomClassName,
                {
                  'opacity-0': DesktopOverlay === 'None',
                  'opacity-10': DesktopOverlay === 'Very_Light',
                  'opacity-30': DesktopOverlay === 'Light',
                  'opacity-50': DesktopOverlay === 'Medium',
                  'opacity-70': DesktopOverlay === 'Dark'
                }
              )}
            />
          ) : null}

          {useWithDimensions && height && width ? (
            <NextImage
              alt={desktopAttributes?.alternativeText ?? ''}
              className={imageCustomClassName}
              data-component-id={`${componentId}-banner-image`}
              height={height}
              imageUrl={desktopAttributes?.provider_metadata?.public_id ?? ''}
              imgKey={desktopAttributes?.alternativeText ?? ''}
              system='CMS'
              useWithDimensions
              width={width}
              {...restProps}
            />
          ) : (
            <NextImage
              className={imageCustomClassName}
              data-component-id={`${componentId}-brand-banner-image`}
              nextImgStyle={{ objectFit: 'fill' }}
              imgKey={desktopAttributes?.alternativeText ?? ''}
              imageUrl={desktopAttributes?.provider_metadata?.public_id ?? ''}
              system='CMS'
              useWithDimensions={false}
              alt={desktopAttributes?.alternativeText ?? ''}
              {...restProps}
            />
          )}
          <div
            data-component-id={`${componentId}-desktop-header`}
            className='absolute top-[50%] bottom-[50%] sm:right-[22px] lg:right-[43px] text-white flex flex-col justify-center sm:max-w-[216px] lg:max-w-[414px] z-20'>
            <span className='sm:text-display-h2-m md: lg:text-display-h1 font-display'>
              {Header}
            </span>
            <span
              data-component-id={`${componentId}-desktop-subText`}
              className='sm:text-xs md:text-xs lg:text-h3 font-600'>
              {SubText}
            </span>
          </div>
        </div>

        <div
          data-component-id={`${componentId}-mobile-overlay`}
          className='min-h-[250px] w-full relative block sm:hidden'>
          {(Header || SubText) && MobileOverlay ? (
            <div
              className={CN(
                'absolute w-full h-full bg-gradient-to-t from-B-600 z-10 from-40%',
                overlayCustomClassName,
                {
                  'opacity-0': MobileOverlay === 'None',
                  'opacity-10': MobileOverlay === 'Very_Light',
                  'opacity-30': MobileOverlay === 'Light',
                  'opacity-50': MobileOverlay === 'Medium',
                  'opacity-70': MobileOverlay === 'Dark'
                }
              )}
            />
          ) : null}
          {useWithDimensions && mobileHeight && mobileWidth ? (
            <NextImage
              className={mobileImageCustomClassName}
              data-component-id={`${componentId}-banner-image`}
              height={mobileHeight}
              imgKey={mobileAttributes?.alternativeText ?? ''}
              imageUrl={mobileAttributes?.provider_metadata?.public_id ?? ''}
              system='CMS'
              useWithDimensions
              alt={mobileAttributes?.alternativeText ?? ''}
              width={mobileWidth}
              {...restProps}
            />
          ) : (
            <NextImage
              className={imageCustomClassName}
              nextImgStyle={{ objectFit: 'cover' }}
              data-component-id={`${componentId}-brand-banner-image`}
              imgKey={mobileAttributes?.alternativeText ?? ''}
              imageUrl={mobileAttributes?.provider_metadata?.public_id ?? ''}
              system='CMS'
              useWithDimensions={false}
              alt={mobileAttributes?.alternativeText ?? ''}
              {...restProps}
            />
          )}
          <div className='absolute p-[20px] bottom-0 text-white flex flex-col justify-center sm:max-w-[216px] lg:max-w-[414px] z-20'>
            <span
              data-component-id={`${componentId}-mobile-header`}
              className='text-display-h2-m font-display'>
              {Header}
            </span>
            <span
              data-component-id={`${componentId}-mobile-subText`}
              className='text-h3-m font-600'>
              {SubText}
            </span>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default BrandBanner
