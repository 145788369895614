import React, { FC, useEffect, useState } from 'react'

import { OnScreenMessage } from 'components/molecules'

export interface ITooManyAttemptsProps {
  [x: string]: any
  lockedEndTime?: string
}

export const TooManyAttempts: FC<ITooManyAttemptsProps> = ({
  onReTry,
  lockedEndTime = '14:59'
}: ITooManyAttemptsProps) => {
  // const TooManyAttemptsClasses = CN(
  //   `login w-full flex flex-col bg-white gap-[24px] pb-[20px]`,
  //   className
  // )
  const convertToSeconds = (time: string) => {
    const [minutes, seconds] = time.split(':').map(Number)
    return minutes * 60 + seconds || 899
  }

  const [timeLeft, setTimeLeft] = useState(convertToSeconds(lockedEndTime))
  const [countdownString, setCountdownString] = useState('')

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60)
    const secs = seconds % 60
    return { mins, secs }
  }

  useEffect(() => {
    let timer: any

    if (timeLeft > -1) {
      timer = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1)
      }, 1000)

      const { mins, secs } = formatTime(timeLeft)

      let minsString: string = ''

      if (mins) {
        minsString = `${String(mins).padStart(2, '0')} minute${mins > 1 ? 's' : ''}`
      }

      const secsString = `${String(secs).padStart(2, '0')} second${secs !== 1 ? 's' : ''}`

      setCountdownString(`${minsString ? minsString + ' and ' : ''}${secsString}`)
    }

    return () => clearInterval(timer)
  }, [timeLeft])

  return (
    <OnScreenMessage
      className=''
      header='Attention!'
      type='error'
      subHeader='Incorrect sign in details'
      description={`You have entered the incorrect sign in details too many times.
      To help protect your account, you must now wait ${countdownString} before trying again.`}
      actionButtonProps={{
        children: 'TRY AGAIN',
        appearance: 'secondary-orange',
        onClick: onReTry,
        size: 'md'
      }}
    />
  )
}

export default TooManyAttempts
