import React, { FC, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Button } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { IAllCategory } from 'lib/actions'

import { MenuButton } from 'components/atoms'

export interface IAllCategorySideBarProps {
  className?: string
  componentId?: string
  header?: {
    title?: string
    icon?: string
    actionButton?: {
      name: string
      onClick: () => void
    }
  }
  isLoading?: boolean
  items?: Array<IAllCategory>
  onClickItem?: (item: IAllCategory) => void
  onHoverItem: (item: IAllCategory) => void
}

export const AllCategorySideBar: FC<IAllCategorySideBarProps> = ({
  className,
  componentId,
  header,
  isLoading = false,
  items,
  onClickItem,
  onHoverItem,
  ...restProps
}: IAllCategorySideBarProps) => {
  const AllCategorySideBarClasses = CN(
    `${componentId} side-bar-filters md:!flex lg:!flex flex-col h-fit lg:w-[296px] md:w-[246px] gap-3`,
    className
  )

  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <div
      className={AllCategorySideBarClasses}
      {...restProps}
      data-component-id={`${componentId}-all-category-section`}>
      {header && (
        <div
          data-component-id={`${componentId}-all-category-section-header`}
          className='flex w-full px-3 pt-3 pb-2 border-t border-t-B-200 gap-x-2 justify-between'>
          <div
            data-component-id={`${componentId}-all-category-section-header-icon`}
            className='flex items-center gap-2'>
            {header.icon && (
              <i
                className={CN(
                  `h-5 w-5 flex items-center justify-center text-[16px] text-N-600 ${header?.icon}`
                )}
              />
            )}
            <span
              data-component-id={`${componentId}-all-category-section-header-title`}
              className='flex capitalize !text-N-800 font-700 text-base'>
              {header.title}
            </span>
          </div>
          {header.actionButton && (
            <Button
              isRingOffset={false}
              componentId='all-category-section-header-see-all-button'
              appearance='link-gray'
              size='sm'
              onClick={header.actionButton.onClick}>
              {header.actionButton.name}
            </Button>
          )}
        </div>
      )}
      {isLoading ? (
        <Skeleton
          data-component-id={`${componentId}-all-category-section-loader`}
          count={5}
          width={'100%'}
          height={36}
          containerClassName='flex flex-col'
        />
      ) : (
        <div
          data-component-id={`${componentId}-all-category-section-items`}
          className='flex flex-col w-full gap-3 bg-white overflow-y-auto md:h-[650px] lg:h-[calc(90vh-96px)]'>
          {items?.map((item: IAllCategory, index: number) => (
            <MenuButton
              data-component-id={`${componentId}-all-category-section-item-${index}`}
              index={index}
              selected={activeIndex === index}
              menuItem={item}
              key={`item-${index}`}
              onClickItem={onClickItem}
              onHoverItem={(itemData: IAllCategory) => {
                onHoverItem(itemData)
                setActiveIndex(index)
              }}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default AllCategorySideBar
