import { useMutation } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

export interface IGetProductVariants {
  id: string | number
  SessionId: string
  cartId: string
}

/* API Request */
export const getProductVariants = async (req: IGetProductVariants) => {
  try {
    const response = await httpPOST({
      url: `products/getproductvariants`,
      body: req
    })
    /** When Needed to handle the Notification with default behavior ['Info' | 'Success' | 'Warning' | 'Error'] use handleResponseToast(response) */
    // handleResponseToast(response?.data)

    return response?.data
  } catch (error) {
    return { error }
  }
}

/* Client side react-query custom hook */
export const useGetProductVariants = () => {
  const { mutateAsync, ...restOptions } = useMutation({
    mutationFn: getProductVariants
  })

  // Passing mutateAsync as link To CreditAccount
  // and restOptions as rest of the options
  return { getVariants: mutateAsync, ...restOptions }
}
