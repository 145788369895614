export const getUserType = (roles: any) => {
  if (
    roles?.includes('FULL_TRADE') ||
    roles?.includes('LIMITED_TRADE') ||
    roles?.includes('PUNCH_OUT_USER')
  ) {
    return 'linked'
  } else if (roles?.includes('NON_TRADE')) {
    return 'webcard'
  } else {
    return 'guest'
  }
}

export default getUserType
