import React, { FC } from 'react'
import CN from 'classnames'

export interface IMinimalSubHeaderProps {
  Breadcrumb?: any
  className?: string
  componentId?: string
  description?: string
  title?: string
}

export const MinimalSubHeader: FC<IMinimalSubHeaderProps> = ({
  Breadcrumb,
  className,
  componentId,
  description,
  title,
  ...restProps
}: IMinimalSubHeaderProps) => {
  const MinimalSubHeaderClasses = CN(
    `minimal-sub-header section container flex flex-col pt-[24px] gap-[24px]`,
    className
  )

  return (
    <div className='bg-white'>
      <section className={MinimalSubHeaderClasses} {...restProps}>
        <div className='flex flex-col'>
          {Breadcrumb}

          <span
            data-component-id={`${componentId}-title`}
            className='max-w-full font-normal truncate text-display-h1-m lg:text-display-h1 font-display text-N-800'>
            {title}
          </span>
          {description && (
            <span
              data-component-id={`${componentId}-description`}
              className='pt-1 mb-8 text-base font-400 text-N-700'>
              {description}
            </span>
          )}
        </div>
      </section>
    </div>
  )
}

MinimalSubHeader.defaultProps = {
  className: undefined,
  componentId: undefined,
  title: undefined
}

export default MinimalSubHeader
