import React, { FC } from 'react'
import CN from 'classnames'

import { BreadcrumbItem, BreadcrumbItemProps } from './BreadcrumbItem'

export interface BreadcrumbProps {
  [x: string]: any
  basePath?: string
  className?: string
  data: BreadcrumbItemProps[]
}

export const Breadcrumb: FC<BreadcrumbProps> = ({
  basePath,
  className,
  data,
  ...restProps
}: BreadcrumbProps) => {
  const BreadcrumbClasses = CN(
    `breadcrumb flex items-center flex-wrap gap-[4px] normal-case text-N-700`,
    className
  )

  const getTruncatedData = () => {
    if (data.length < 6) return data
    /*
     * 1. Get first two objects from data
     * 2. Get last two objects from data
     * 3. Add ellipsis
     * 4. Prepare new array
     * 5. Return new array
     */
    const firstTwo = data.slice(0, 2) // 1
    const lastTwo = data.slice(data.length - 2, data.length) // 2
    const ellipsis = {
      id: 'ellipsis',
      isElipsis: true,
      label: '...'
    } // 3
    const newData = [...firstTwo, ellipsis, ...lastTwo] // 4
    return newData // 5
  }

  return (
    <div data-component-id='breadcrumb' className={BreadcrumbClasses} {...restProps}>
      {getTruncatedData().map((item: BreadcrumbItemProps, index: number) => {
        return (
          <BreadcrumbItem
            key={item?.id}
            isLast={getTruncatedData().length - 1 === index}
            index={index}
            truncatedData={data.slice(2, data.length - 2)}
            slug={index !== 0 ? item?.slug : '/'}
            basePath={basePath}
            {...item}
          />
        )
      })}
    </div>
  )
}

export default Breadcrumb
