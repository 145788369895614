import Link from 'next/link'
import React, { FC } from 'react'
import { Button } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { IComponentMoleculesServicesTextBlock } from 'lib/actions'
import { checkURL } from 'lib/utilities'

export interface SpecialistServicesTextBlockProps {
  className?: string
  componentId?: string
  textBlockData?: IComponentMoleculesServicesTextBlock
}

export const SpecialistServicesTextBlock: FC<SpecialistServicesTextBlockProps> = ({
  className,
  componentId,
  textBlockData,
  ...restProps
}: SpecialistServicesTextBlockProps) => {
  const {
    originalUrl: buttonOriginalUrl,
    restUrlWithQuery: buttonQuery,
    isInsideUrl: buttonUrlIsInternal
  } = checkURL(textBlockData?.Action?.Url ?? '')

  const processDescription = () => {
    if (textBlockData?.Description) {
      const paragraphs = textBlockData?.Description?.split('\n\n')

      const roundedUpVal = Math.ceil(paragraphs?.length / 2)

      return [
        paragraphs.slice(0, roundedUpVal).join('\n\n'),
        paragraphs.slice(roundedUpVal).join('\n\n')
      ]
    }
  }

  const descriptionColumns = processDescription()

  const SpecialistServicesTextBlockClasses = CN('flex flex-col w-full', className)

  return (
    <div
      data-component-id={`${componentId}-content`}
      className={SpecialistServicesTextBlockClasses}
      {...restProps}>
      {/** Specialist Services Text Block Header */}
      {textBlockData?.Header && (
        <span
          data-component-id={`${componentId}-content-header`}
          className='font-700 text-h2 text-B-500'>
          {textBlockData?.Header}
        </span>
      )}

      {/** Specialist Services Text Block Sub Header */}
      {textBlockData?.SubHeader && (
        <span
          data-component-id={`${componentId}-content-sub-header`}
          className='font-600 text-h3 text-N-800 pt-1'>
          {textBlockData?.SubHeader}
        </span>
      )}

      {/** Specialist Services Text Block Description with multi columns*/}
      {textBlockData?.IsMultiColumn && textBlockData?.Description && descriptionColumns && (
        <div
          data-component-id={`${componentId}-content-description-with-multi-columns`}
          className={CN('gap-8 w-full whitespace-pre-wrap pt-6 lg:pt-8', {
            'grid grid-cols-1 lg:grid-cols-2': descriptionColumns[1]
          })}>
          <div
            data-component-id={`${componentId}-content-description-column-1`}
            className='text-N-700 text-base font-400'>
            {descriptionColumns[0]}
          </div>

          <div
            data-component-id={`${componentId}-content-description-column-2`}
            className='text-N-700 text-base font-400'>
            {descriptionColumns[1]}
          </div>
        </div>
      )}

      {/** Specialist Services Text Block Description with no columns*/}
      {!textBlockData?.IsMultiColumn && textBlockData?.Description && (
        <div
          data-component-id={`${componentId}-content-description-with-no-columns`}
          className='font-400 text-base text-N-700'>
          {textBlockData?.Description}
        </div>
      )}

      {textBlockData?.Action?.ButtonText && (
        <>
          {/** Specialist Services Text Block Action Button for Tab And Mobile View */}
          <Link
            href={buttonUrlIsInternal ? buttonQuery : buttonOriginalUrl ?? {}}
            passHref
            target={buttonUrlIsInternal ? '' : '_blank'}
            className={CN('visible lg:hidden', {
              'cursor-pointer': buttonOriginalUrl,
              'cursor-auto pointer-events-none': !buttonOriginalUrl
            })}>
            <Button
              appearance='primary-orange'
              isRingOffset={false}
              componentId={`${componentId}-content-action-button-tab-and-mobile-view`}
              className='mt-6'
              isBlock
              size='md'
              iconAfter={textBlockData?.Action?.IconAfter ?? ''}
              iconBefore={textBlockData?.Action?.IconBefore ?? ''}>
              {textBlockData?.Action?.ButtonText}
            </Button>
          </Link>

          {/** Specialist Services Text Block Action Button for Desktop View View */}
          <Link
            href={buttonUrlIsInternal ? buttonQuery : buttonOriginalUrl ?? {}}
            passHref
            target={buttonUrlIsInternal ? '' : '_blank'}
            className={CN('hidden lg:!block', {
              'cursor-pointer': buttonOriginalUrl,
              'cursor-auto pointer-events-none': !buttonOriginalUrl
            })}>
            <Button
              appearance='primary-orange'
              componentId={`${componentId}-content-action-button-desktop-view`}
              className='mt-6 w-fit !px-[54px]'
              isRingOffset={false}
              size='md'
              iconAfter={textBlockData?.Action?.IconAfter ?? ''}
              iconBefore={textBlockData?.Action?.IconBefore ?? ''}>
              {textBlockData?.Action?.ButtonText}
            </Button>
          </Link>
        </>
      )}
    </div>
  )
}

export default SpecialistServicesTextBlock
