import { useRouter } from 'next/router'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import { IHeaderContext } from 'types/context'
import { PermissionMenuProps } from 'types/myAccount'
import { useSession } from 'lib/actions/session/getSession'
import useWindowFocus from 'lib/hooks/useWindowFocus'
import { FINANCE_MENU, ORDERING_MENU, SETTINGS_MENU } from 'lib/static/myAccount'

import { useAppContext } from './app-context'
import { useModalContext } from './modal-context'

const HeaderContext = createContext({} as IHeaderContext)

export function HeaderWrapper({ children, values }: any) {
  const [ordering, setOrdering] = useState<PermissionMenuProps[]>(ORDERING_MENU)
  const [finance, setFinance] = useState<PermissionMenuProps[]>(FINANCE_MENU)
  const [settings, setSettings] = useState<PermissionMenuProps[]>(SETTINGS_MENU)

  const router = useRouter()

  const { blockUI } = useModalContext()
  const { isLoggedIn, session: appSession } = useAppContext()

  /** Handle Punch Out */
  const inIframe = () => {
    try {
      return window.self !== window.top
    } catch (e) {
      return true
    }
  }

  const { session, isSuccess, getSession } = useSession()

  const uiCheck = () => {
    const isIframe = inIframe()

    if (isSuccess) {
      if (isIframe) {
        /** When User In an Iframe */
        if (session?.isPunchOut && isLoggedIn) {
          blockUI.close()
        } else {
          blockUI.open()
        }
      } else if (session?.isPunchOut && session?.isIframe) {
        /** When User Not In an Iframe */
        blockUI.open()
      } else {
        blockUI.close()
      }
    }
  }

  useEffect(() => {
    getSession()
  }, [router])

  useEffect(() => {
    uiCheck()
  }, [isLoggedIn, session])

  useEffect(() => {
    if (isLoggedIn) {
      const { businessName, fullName, emailAddress, accountCode } = session?.user?.data || {}
      const loginMethod = appSession?.punchOutMethod || (emailAddress ? 'WEB' : '')

      datadogRum.setUser({
        businessName,
        name: fullName,
        email: emailAddress,
        accountCode,
        loginMethod
      })
    }
  }, [session, isLoggedIn])

  const windowFocused = useWindowFocus()

  useEffect(() => {
    if (windowFocused) {
      uiCheck()
    }
  }, [windowFocused])

  const sharedState: IHeaderContext = {
    permissions: {
      ordering,
      finance,
      settings,
      setOrdering,
      setFinance,
      setSettings
    }
  }

  return (
    <HeaderContext.Provider value={{ ...sharedState, ...values }}>
      {children}
    </HeaderContext.Provider>
  )
}

export function useHeaderContext() {
  return useContext(HeaderContext)
}
