import Link from 'next/link'
import React, { FC } from 'react'
import { Button } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { checkURL } from 'lib/utilities'

import { NextImage, YouTubeIFrame } from 'components/atoms'

export interface BrandHomeDescriptionProps {
  actionButton?: {
    buttonText: string
    url: string
    iconBefore?: string
    iconAfter?: string
  }
  className?: string
  componentId?: string
  description?: string
  footerNote?: string
  header: string
  imageData?: {
    desktop: {
      data: {
        attributes: {
          alternativeText: string
          url: string
          provider: any
          provider_metadata: any
          name: string
        }
      }
    }
    mobile: {
      data: {
        attributes: {
          alternativeText: string
          url: string
          provider: any
          provider_metadata: any
          name: string
        }
      }
    }
    url?: string
    bannerHeader?: string
    bannerSubHeader?: string
    desktopOverlay?: 'None' | 'Very_Light' | 'Light' | 'Medium' | 'Dark'
    mobileOverlay?: 'None' | 'Very_Light' | 'Light' | 'Medium' | 'Dark'
  }
  isFooterCenter?: boolean
  isMultiColumn?: boolean
  // type 1 -> without video and button
  // type 2 -> with video and button
  // type 3 -> without video, button and footerNote
  // type 4 -> with only button and footerNote in center
  // type 5 -> with video, banner and description but visual order change in tab and mobile
  type?: 1 | 2 | 3 | 4 | 5
  videoUrl?: string
}

export const BrandHomeDescription: FC<BrandHomeDescriptionProps> = ({
  actionButton,
  className,
  componentId,
  description,
  footerNote,
  header,
  imageData,
  isFooterCenter,
  isMultiColumn = true,
  type = 1,
  videoUrl,
  ...restProps
}: BrandHomeDescriptionProps) => {
  const brandHomeDescriptionClasses = CN(
    `${componentId}-brand-home-description flex flex-col bg-B-25 p-6 gap-8 rounded-[3px] break-words`,
    className
  )

  const {
    originalUrl: buttonOriginalUrl,
    restUrlWithQuery: buttonQuery,
    isInsideUrl: buttonUrlIsInternal
  } = checkURL(actionButton?.url ?? '')

  const {
    originalUrl: bannerOriginalUrl,
    restUrlWithQuery: bannerQuery,
    isInsideUrl: bannerUrlIsInternal
  } = checkURL(imageData?.url ?? '')

  const processDescription = () => {
    if (description) {
      let midIndex = 0
      const paragraphs = description.split('\n\n')
      // Calculate the total number of words in the description
      const totalWords = paragraphs.reduce(
        (acc, paragraph) => acc + paragraph.split(/\s+/).length,
        0
      )

      // Calculate the midpoint by word count instead of paragraph count
      let wordCount = 0

      for (let i = 0; i < paragraphs.length; i++) {
        const paragraphWords = paragraphs[i].split(/\s+/).length
        wordCount += paragraphWords

        if (wordCount >= totalWords / 2) {
          midIndex = i
          break
        }
      }
      return [
        paragraphs.slice(0, midIndex + 1).join('\n\n'),
        paragraphs.slice(midIndex + 1).join('\n\n')
      ]
    }
  }

  const descriptionColumns = processDescription()

  return (
    <div className={brandHomeDescriptionClasses} {...restProps}>
      {/* header */}
      {header && <div className='text-N-800 font-700 text-h2-m lg:!text-h2 px-3'>{header}</div>}

      {/* without video and button or without video, button and des3 or with only button and footerNote in center */}
      {(type === 1 || type === 3 || type === 4) && (
        <>
          {/* description in top */}
          {description &&
            descriptionColumns &&
            (isMultiColumn ? (
              <div className='grid grid-cols-1 sm:grid-cols-2 gap-8 px-3 w-full whitespace-pre-wrap'>
                <div className='text-N-700 text-base font-400'>{descriptionColumns[0]}</div>
                <div className='text-N-700 text-base font-400'>{descriptionColumns[1]}</div>
              </div>
            ) : (
              <div className='text-N-700 text-base font-400 px-3 w-full whitespace-pre-wrap'>
                {description}
              </div>
            ))}

          {/* description image */}
          {imageData?.desktop && imageData?.mobile && (
            <Link
              href={bannerUrlIsInternal ? bannerQuery : bannerOriginalUrl ?? {}}
              passHref
              target={bannerUrlIsInternal ? '' : '_blank'}
              className={CN({
                'cursor-pointer': bannerOriginalUrl,
                'cursor-auto pointer-events-none': !bannerOriginalUrl
              })}>
              {/* desktop image */}
              <div className='w-auto relative hidden sm:block h-[220px] sm:h-[134px] lg:h-[258px]'>
                {(imageData?.bannerHeader || imageData?.bannerSubHeader) &&
                imageData?.desktopOverlay ? (
                  <div
                    className={CN(
                      'absolute w-full h-full bg-gradient-to-l from-black z-10 from-40%',
                      {
                        'opacity-0': imageData?.desktopOverlay === 'None',
                        'opacity-10': imageData?.desktopOverlay === 'Very_Light',
                        'opacity-30': imageData?.desktopOverlay === 'Light',
                        'opacity-50': imageData?.desktopOverlay === 'Medium',
                        'opacity-70': imageData?.desktopOverlay === 'Dark'
                      }
                    )}
                  />
                ) : null}
                <NextImage
                  data-component-id={`${componentId}-brand-home-image`}
                  nextImgStyle={{ objectFit: 'fill' }}
                  imgKey={imageData?.desktop?.data?.attributes?.alternativeText}
                  imageUrl={imageData?.desktop?.data?.attributes?.provider_metadata?.public_id}
                  system='CMS'
                  useWithDimensions={false}
                  alt={
                    imageData?.desktop?.data?.attributes?.alternativeText ||
                    imageData?.desktop?.data?.attributes?.name ||
                    'brand-home-desktop-image'
                  }
                  {...restProps}
                />
                <div className='absolute top-[50%] bottom-[50%] md:right-[18px] lg:right-[32px] text-white flex flex-col justify-center sm:max-w-[216px] lg:max-w-[414px] z-20'>
                  {imageData?.bannerHeader && (
                    <span className='sm:text-display-h2-m md: lg:text-display-h1 font-display'>
                      {imageData?.bannerHeader}
                    </span>
                  )}
                  {imageData?.bannerSubHeader && (
                    <span className='sm:text-xs md:text-xs lg:text-h3 font-600'>
                      {imageData?.bannerSubHeader}
                    </span>
                  )}
                </div>
              </div>
              {/* mobile image */}
              <div className='w-auto relative sm:!hidden h-[220px] sm:h-[134px] lg:h-[258px]'>
                {(imageData?.bannerHeader || imageData?.bannerSubHeader) &&
                  imageData?.mobileOverlay && (
                    <div
                      className={CN(
                        'absolute w-full h-full bg-gradient-to-t from-B-600 z-10 from-40%',
                        {
                          'opacity-0': imageData?.mobileOverlay === 'None',
                          'opacity-10': imageData?.mobileOverlay === 'Very_Light',
                          'opacity-30': imageData?.mobileOverlay === 'Light',
                          'opacity-50': imageData?.mobileOverlay === 'Medium',
                          'opacity-70': imageData?.mobileOverlay === 'Dark'
                        }
                      )}
                    />
                  )}
                <NextImage
                  data-component-id={`${componentId}-brand-home-image`}
                  nextImgStyle={{ objectFit: 'fill' }}
                  imgKey={imageData?.mobile?.data?.attributes?.alternativeText}
                  imageUrl={imageData?.mobile?.data?.attributes?.provider_metadata?.public_id}
                  system='CMS'
                  useWithDimensions={false}
                  alt={
                    imageData?.mobile?.data?.attributes?.alternativeText ||
                    imageData?.mobile?.data?.attributes?.name ||
                    'brand-home-mobile-image'
                  }
                  {...restProps}
                />
                <div className='absolute p-[20px] bottom-0 text-white flex flex-col justify-center sm:max-w-[216px] lg:max-w-[414px] z-20'>
                  {imageData?.bannerHeader && (
                    <span className='text-display-h2-m font-display'>
                      {imageData?.bannerHeader}
                    </span>
                  )}
                  {imageData?.bannerSubHeader && (
                    <span className='text-h3-m font-600'>{imageData?.bannerSubHeader}</span>
                  )}
                </div>
              </div>
            </Link>
          )}

          {/* footerNote in bottom */}
          {type === 1 && footerNote && (
            <div className='text-N-700 text-sm font-400 px-3'>{footerNote}</div>
          )}

          {/* bottom button */}
          {type === 4 && (
            <div className='flex flex-col gap-3'>
              {footerNote && (
                <div className='text-N-700 text-sm font-400 text-center'>{footerNote}</div>
              )}

              {actionButton?.buttonText && (
                <Link
                  href={buttonUrlIsInternal ? buttonQuery : buttonOriginalUrl ?? {}}
                  passHref
                  target={buttonUrlIsInternal ? '' : '_blank'}
                  className={CN({
                    'cursor-pointer': buttonOriginalUrl,
                    'cursor-auto pointer-events-none': !buttonOriginalUrl
                  })}>
                  <div className='flex justify-center'>
                    <Button
                      appearance='primary-orange'
                      className='w-fit'
                      size='lg'
                      iconBefore={actionButton?.iconBefore}
                      iconAfter={actionButton?.iconAfter}>
                      {actionButton?.buttonText}
                    </Button>
                  </div>
                </Link>
              )}
            </div>
          )}
        </>
      )}

      {/* with video and button*/}
      {(type === 2 || type === 5) && (
        <>
          {/* description image */}
          {imageData?.desktop && imageData?.mobile && (
            <Link
              href={bannerUrlIsInternal ? bannerQuery : bannerOriginalUrl ?? {}}
              passHref
              target={bannerUrlIsInternal ? '' : '_blank'}
              className={CN({
                'cursor-pointer': bannerOriginalUrl,
                'cursor-auto pointer-events-none': !bannerOriginalUrl
              })}>
              {/* desktop image */}
              <div className='h-[220px] sm:h-[134px] lg:h-[258px] w-auto relative hidden sm:!block'>
                {(imageData?.bannerHeader || imageData?.bannerSubHeader) &&
                imageData?.desktopOverlay ? (
                  <div
                    className={CN(
                      'absolute w-full h-full bg-gradient-to-l from-black z-10 from-40%',
                      {
                        'opacity-0': imageData?.desktopOverlay === 'None',
                        'opacity-10': imageData?.desktopOverlay === 'Very_Light',
                        'opacity-30': imageData?.desktopOverlay === 'Light',
                        'opacity-50': imageData?.desktopOverlay === 'Medium',
                        'opacity-70': imageData?.desktopOverlay === 'Dark'
                      }
                    )}
                  />
                ) : null}
                <NextImage
                  data-component-id={`${componentId}-brand-home-image`}
                  nextImgStyle={{ objectFit: 'fill' }}
                  imgKey={imageData?.desktop?.data?.attributes?.alternativeText}
                  imageUrl={imageData?.desktop?.data?.attributes?.provider_metadata?.public_id}
                  system='CMS'
                  useWithDimensions={false}
                  alt={
                    imageData?.desktop?.data?.attributes?.alternativeText ||
                    imageData?.desktop?.data?.attributes?.name ||
                    'brand-home-desktop-image'
                  }
                  {...restProps}
                />
                <div className='absolute top-[50%] bottom-[50%] md:right-[18px] lg:right-[32px] text-white flex flex-col justify-center sm:max-w-[216px] lg:max-w-[414px] z-20'>
                  {imageData?.bannerHeader && (
                    <span className='sm:text-display-h2-m md: lg:text-display-h1 font-display'>
                      {imageData?.bannerHeader}
                    </span>
                  )}
                  {imageData?.bannerSubHeader && (
                    <span className='sm:text-xs md:text-xs lg:text-h3 font-600'>
                      {imageData?.bannerSubHeader}
                    </span>
                  )}
                </div>
              </div>
              {/* mobile image */}
              <div className='h-[220px] sm:h-[134px] lg:h-[258px] w-auto relative sm:!hidden'>
                {(imageData?.bannerHeader || imageData?.bannerSubHeader) &&
                  imageData?.mobileOverlay && (
                    <div
                      className={CN(
                        'absolute w-full h-full bg-gradient-to-t from-B-600 z-10 from-40%',
                        {
                          'opacity-0': imageData?.mobileOverlay === 'None',
                          'opacity-10': imageData?.mobileOverlay === 'Very_Light',
                          'opacity-30': imageData?.mobileOverlay === 'Light',
                          'opacity-50': imageData?.mobileOverlay === 'Medium',
                          'opacity-70': imageData?.mobileOverlay === 'Dark'
                        }
                      )}
                    />
                  )}
                <NextImage
                  data-component-id={`${componentId}-brand-home-image`}
                  nextImgStyle={{ objectFit: 'fill' }}
                  imgKey={imageData?.mobile?.data?.attributes?.alternativeText}
                  imageUrl={imageData?.mobile?.data?.attributes?.provider_metadata?.public_id}
                  system='CMS'
                  useWithDimensions={false}
                  alt={
                    imageData?.mobile?.data?.attributes?.alternativeText ||
                    imageData?.mobile?.data?.attributes?.name ||
                    'brand-home-mobile-image'
                  }
                  {...restProps}
                />
                <div className='absolute p-[20px] bottom-0 text-white flex flex-col justify-center sm:max-w-[216px] lg:max-w-[414px] z-20'>
                  {imageData?.bannerHeader && (
                    <span className='text-display-h2-m font-display'>
                      {imageData?.bannerHeader}
                    </span>
                  )}
                  {imageData?.bannerSubHeader && (
                    <span className='text-h3-m font-600'>{imageData?.bannerSubHeader}</span>
                  )}
                </div>
              </div>
            </Link>
          )}
          <div
            className={CN('lg:!grid lg:!grid-cols-2 flex flex-col-reverse gap-8 px-3', {
              '!flex-col': type === 5
            })}>
            {/* description in middle left */}
            {description && (
              <div className='text-N-700 text-base font-400 whitespace-pre-wrap'>{description}</div>
            )}
            {/* video iframe */}
            {videoUrl && (
              <div className='!h-[152px] sm:!h-[276px] lg:!h-[200px]'>
                <YouTubeIFrame youtubeLink={videoUrl} className='w-full h-full' />
              </div>
            )}
          </div>
          {/* footerNote in bottom */}
          {footerNote && (
            <div
              className={CN('text-N-700 text-sm font-400 px-3', {
                'text-center': isFooterCenter
              })}>
              {footerNote}
            </div>
          )}
          {/* bottom button */}
          {actionButton?.buttonText && (
            <Link
              href={buttonUrlIsInternal ? buttonQuery : buttonOriginalUrl ?? {}}
              passHref
              target={buttonUrlIsInternal ? '' : '_blank'}
              className={CN({
                'cursor-pointer': buttonOriginalUrl,
                'cursor-auto pointer-events-none': !buttonOriginalUrl
              })}>
              <div className='flex justify-center'>
                <Button
                  appearance='primary-orange'
                  className='w-fit'
                  size='lg'
                  iconBefore={actionButton?.iconBefore}
                  iconAfter={actionButton?.iconAfter}>
                  {actionButton?.buttonText}
                </Button>
              </div>
            </Link>
          )}
        </>
      )}
    </div>
  )
}

export default BrandHomeDescription
