import Link from 'next/link'
import { FC } from 'react'
import { Button } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { HomeServicesBlock } from 'lib/actions'
import { checkURL } from 'lib/utilities'

import { NextImage } from 'components/atoms'

export interface SpecialistServicesBlockProps {
  className?: string
  componentId?: string
  specialistServices?: HomeServicesBlock
  [x: string]: any
}

export const SpecialistServicesBlock: FC<SpecialistServicesBlockProps> = ({
  className,
  componentId,
  specialistServices,
  ...restProps
}: SpecialistServicesBlockProps) => {
  const SpecialistServicesBlockClasses = CN(`${componentId} flex flex-col gap-[24px]`, className)

  const {
    originalUrl: actionOriginalUrl,
    restUrlWithQuery: actionRestUrlWithQuery,
    isInsideUrl: actionIsInsideUrl
  } = checkURL(specialistServices?.Action?.URL ?? '')

  return (
    <div className={SpecialistServicesBlockClasses} {...restProps}>
      <div
        data-component-id={`${componentId}-header`}
        className='flex flex-col lg:!flex-row lg:justify-between'>
        <span
          data-component-id={`${componentId}-header-title`}
          className='font-600 text-h3 text-N-800'>
          {specialistServices?.HomeServicesBlockHeader}
        </span>

        {specialistServices?.Action && (
          <Link
            href={actionIsInsideUrl ? actionRestUrlWithQuery : actionOriginalUrl ?? {}}
            passHref
            target={actionIsInsideUrl ? '' : '_blank'}>
            <Button
              appearance='link'
              className='w-fit pt-2 lg:pt-0'
              componentId={`${componentId}-header-action-btn`}
              iconAfter='nzsbi-chevron-right'
              isRingOffset={false}
              size='md'
              {...specialistServices?.Action}>
              {specialistServices?.Action?.DisplayText}
            </Button>
          </Link>
        )}
      </div>

      <div
        data-component-id={`${componentId}-items-container`}
        className='flex gap-x-4 md:gap-x-5 lg:gap-x-8 max-w-[1280px] overflow-x-auto no-scrollbar'>
        {Array.isArray(specialistServices?.ServicesList?.data) &&
          specialistServices?.ServicesList?.data?.map((item: any, index: number) => (
            <div
              key={item?.attributes?.CardImage?.data?.id}
              className='border-[1px] border-N-100 rounded shadow-sm w-[296px] min-w-[296px] sm:min-w-[296px] relative bg-white'
              {...restProps}>
              <Link
                aria-label={`${item?.attributes?.Slug}`}
                href={`services/${item?.attributes?.Slug}`}>
                <div data-component-id={`${componentId}-item-${index}`} className='px-2 pt-2'>
                  <div
                    data-component-id={`${componentId}-item-${index}-image-section`}
                    className='relative h-[300px]'>
                    <NextImage
                      nextImgStyle={{ objectFit: 'cover', padding: 'opx 8px opx 8px' }}
                      data-component-id={`${componentId}-item-${index}-image`}
                      imgKey={item?.attributes?.CardImage?.data?.id ?? ''}
                      imageUrl={
                        item?.attributes?.CardImage?.data?.attributes?.provider_metadata?.public_id
                      }
                      system='CMS'
                      useWithDimensions={false}
                      alt={item?.attributes?.CardImage?.data?.attributes?.alternativeText ?? ''}
                    />
                  </div>
                </div>
              </Link>

              {/** link Section*/}
              <div className='flex p-4'>
                <Link
                  href={`services/${item?.attributes?.Slug}`}
                  data-component-id={`${componentId}-item-${index}-detail-section-header`}
                  className='font-700 text-N-700 text-display-body'>
                  {item?.attributes?.Header}
                </Link>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

export default SpecialistServicesBlock
