import { useMutation } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

export interface ICreateOrderResponse {
  messageTitle: string | null
  messageDescription: string | null
  messageType: string
  data: any
}

export interface IOrderDetails {
  cartId: string
  emailAddress: string
  paymentType: 'accountpay' | 'card'
  firstName: string
  lastName: string
  contactNumber: string
  businessName?: string
  invoiceAddress: {
    city: string
    postcode: string
    searchingAddress: string
    streetAddress: string
    suburb: string
  }
  deliveryMethod: 'pickup' | 'delivery'
  //pickupDetails is null for delivery
  pickupDetails?: {
    emailAddress: string
    branchName: string
    branchCode: string
    streetAddress: string
    postCode: string
    city: string
    suburb: string
    pickupOption?: 0 | 1 | 2 //SamedayPickup = 0,PickupTommorrow = 1,SelectedDate = 2
    preferredDate?: Date
    firstName: string
    lastName: string
    contactNumber: string
  }
  orderNote: string
  orderShippingAddress?: {
    streetAddress: string
    city: string
    suburb: string
    postCode: string
    attentionTo: string
  }
  deliveryInstructions: string
  purchaseOrderNumber: string
}

/**
 * Created to place an order
 * @param productIds
 * @param dealsCount
 * @returns
 */
export const createOrder = async (orderDetails: IOrderDetails) => {
  const { data } = await httpPOST({
    url: `order/create`,
    body: {
      ...orderDetails
    }
  })
  return data
}

export const useCreateOrder = () => {
  return useMutation({
    mutationFn: createOrder
  })
}
