import React, { createContext, useContext, useState } from 'react'
import { ISideBarContext } from 'types/context'

const SideBarContext = createContext({} as ISideBarContext)

export function SideBarWrapper({ children, values }: any) {
  /** Authentication States */
  const [isQuickCartOpen, setIsQuickCartOpen] = useState(false)

  const sharedState: ISideBarContext = {
    allSideBars: {
      close: () => {}
    },
    quickCartSidebar: {
      isOpen: isQuickCartOpen,
      set: setIsQuickCartOpen,
      open: () => setIsQuickCartOpen(true),
      close: () => setIsQuickCartOpen(false)
    }
  }

  return (
    <SideBarContext.Provider value={{ ...sharedState, ...values }}>
      {children}
    </SideBarContext.Provider>
  )
}

export function useSideBarContext() {
  return useContext(SideBarContext)
}
