import React, { FC } from 'react'
import { priceFormat, Thumbnail, ThumbnailProps } from '@nzsb/shopnx-ui'
import CN from 'classnames'

export interface CheckOutProdItemCardProps {
  [x: string]: any
  className?: string
  componentId?: string
  currentPrice?: number | string
  eachPrice?: number | string
  eachPriceWithGst?: number | string
  isShowGstPricePrimary?: boolean
  nextImage?: any
  onTitleClick: () => void
  quantity: number
  sku?: string
  thumbnail?: ThumbnailProps
  title: string
  uom: string
  withGst?: number | string
}

export const CheckOutProdItemCard: FC<CheckOutProdItemCardProps> = ({
  className,
  componentId,
  currentPrice,
  eachPrice,
  eachPriceWithGst,
  isShowGstPricePrimary,
  nextImage,
  onTitleClick,
  quantity,
  sku,
  thumbnail,
  title,
  uom,
  withGst,
  ...restProps
}: CheckOutProdItemCardProps) => {
  const ProdMyOrderItemCardClasses = CN(`flex w-full gap-2 justify-between`, className)

  return (
    <div
      data-component-id='check-out-prod-item-card'
      className={ProdMyOrderItemCardClasses}
      {...restProps}>
      <div className='flex w-full gap-[6px]'>
        {/* Thumbnail */}
        <div className='flex min-w-[32px] w-[32px] h-[32px] rounded-sm'>
          {nextImage || <Thumbnail isZoomable={false} {...thumbnail} componentId={componentId} />}
        </div>

        {/* Product title and sku */}
        <div className='flex w-full flex-col'>
          <h2
            className={CN(`${componentId}-title font-sans text-xs font-medium text-N-700`, {
              'cursor-pointer select-none': onTitleClick
            })}
            onClick={onTitleClick}
            aria-hidden='true'>
            {title}
          </h2>
          {sku && (
            <div className={`${componentId}-sku font-sans text-xs font-normal text-N-400`}>
              {sku}
            </div>
          )}
        </div>
      </div>

      <div className='flex items-end w-fit min-w-fit justify-end flex-col gap-1'>
        <span
          className={`${componentId}-current-price-view font-sans text-xs font-medium text-N-700`}>
          ${priceFormat(isShowGstPricePrimary ? withGst ?? 0 : currentPrice ?? 0, 2)}
        </span>

        <div className='flex flex-col items-end w-fit font-sans text-xs font-400 text-N-400'>
          <span className={`${componentId}-gst-price-view`}>
            {'Qty: '}
            {quantity}
          </span>

          <span className={`${componentId}-each-price-view`}>
            {priceFormat(isShowGstPricePrimary ? eachPriceWithGst ?? 0 : eachPrice ?? 0, 2)} {uom}
          </span>
        </div>
      </div>
    </div>
  )
}

export default CheckOutProdItemCard
