import { useMutation } from '@tanstack/react-query'
import { httpPatch } from 'lib/http'

import { loggingRequest } from '../logger/loggingRequest'

export interface IUpdateCardDetailsReq {
  sessionId: string
}

export const updateCardDetailsCheckOut = async (req: IUpdateCardDetailsReq) => {
  loggingRequest({
    level: 'info',
    message: `[Module: checkout/payment-gateway]
    \nAction - Update card details BE API called
    \nSession ID - ${req.sessionId}`
  })

  const response = await httpPatch({
    url: `order/update_card_details/${req.sessionId}`
  })
  return response?.data
}

/* Client side react-query custom hook */
export const useUpdateCardDetailsCheckout = () => {
  return useMutation({
    mutationFn: updateCardDetailsCheckOut
  })
}
