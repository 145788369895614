/* eslint-disable import/no-extraneous-dependencies */
import { FC, useCallback, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { useResizeObserver } from '@wojtekmaj/react-hooks'
import type { PDFDocumentProxy } from 'pdfjs-dist'

import 'core-js/proposals/promise-with-resolvers'

import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'

/* PDF rendering options */
const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/'
}

/* Resize observer options */
const resizeObserverOptions = {}

/* Interface for props in PdfViewer component */
export interface IPdfViewerProps {
  url: string
}

/* PdfViewer component */
export const PdfViewer: FC<IPdfViewerProps> = ({ url }: IPdfViewerProps) => {
  /* Set the worker source for pdfjs */
  if (typeof window !== 'undefined') {
    pdfjs.GlobalWorkerOptions.workerSrc = new URL(
      'pdfjs-dist/build/pdf.worker.min.mjs',
      import.meta.url
    ).toString()
  }

  /* State for the number of pages in the PDF */
  const [numPages, setNumPages] = useState<number>()

  /* State for the container reference and width */
  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null)
  const [containerWidth, setContainerWidth] = useState<number>()

  /* Callback function for resize observer */
  const onResize = useCallback<ResizeObserverCallback>(entries => {
    const [entry] = entries

    if (entry) {
      /* Update the container width when it changes */
      setContainerWidth(entry.contentRect.width)
    }
  }, [])

  /* Attach resize observer to the container reference */
  useResizeObserver(containerRef, resizeObserverOptions, onResize)

  /* Callback function when the PDF document is loaded successfully */
  const onDocumentLoadSuccess = ({ numPages: nextNumPages }: PDFDocumentProxy): void => {
    /* Update the number of pages in the state */
    setNumPages(nextNumPages)
  }

  /* Render the PdfViewer component */
  return (
    <div className='w-full flex justify-center' ref={setContainerRef} id='pdf-viewer'>
      {/* PdfViewer component using react-pdf */}
      <Document file={url} onLoadSuccess={onDocumentLoadSuccess} options={options}>
        {/* Generate Page components for each page in the PDF */}
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} width={containerWidth} />
        ))}
      </Document>
    </div>
  )
}
