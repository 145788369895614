import React, { FC, forwardRef, InputHTMLAttributes, useEffect, useRef, useState } from 'react'
import CN from 'classnames'

export interface PhoneInputWithCountryCodeProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  componentId?: string
  countryDetails?: Array<{
    country: string
    code: string
    flag: string
  }>
  defaultCountry?: {
    country: string
    code: string
    flag: string
  }
  disabled?: boolean
  hasError?: boolean
  hint?: string | React.ReactNode
  iconAfter?: string
  onClickCountryFlag?: (e: PhoneInputWithCountryCodeProps['defaultCountry']) => void
  onClickIconAfter?: () => void
  inputSize?: 'sm' | 'md'
  label?: string
  placeholder?: string
  readOnly?: boolean
  required?: boolean
  wrapperClassName?: string
  [x: string]: any
}

export const PhoneInputWithCountryCode: FC<PhoneInputWithCountryCodeProps> = forwardRef(
  (
    {
      className,
      componentId,
      countryDetails,
      disabled = false,
      hasError = false,
      hint,
      iconAfter,
      id,
      inputSize = 'md',
      label,
      onClickCountryFlag,
      onClickIconAfter,
      placeholder,
      readOnly = false,
      required = false,
      defaultCountry,
      wrapperClassName,
      ...restProps
    }: PhoneInputWithCountryCodeProps,
    ref: any
  ) => {
    const [selectedCountry, setSelectedCountry] = useState(defaultCountry ?? countryDetails?.[0])
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
      setSelectedCountry(defaultCountry)
    }, [defaultCountry])

    const divRef = useRef<HTMLDivElement>(null)

    // Function to handle the outside click
    const handleClickOutside = (event: { target: any }) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    useEffect(() => {
      // Add event listener for clicks
      document.addEventListener('mousedown', handleClickOutside)

      // Cleanup event listener on component unmount
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [])

    /* Wrapper classes */
    const PhoneInputWithCountryCodeWrapperClasses = CN(
      `phone-input-with-country-code__wrapper inline-flex flex-col gap-[4px] group`,
      { 'cursor-not-allowed': disabled },
      wrapperClassName
    )

    /* Label classes */
    const PhoneInputWithCountryCodeLabelClasses = CN(
      'phone-input-with-country-code__label select-text text-N-700 font-500 flex items-center w-full cursor-pointer',
      {
        'text-sm leading-[20px]': inputSize === 'sm',
        'text-base leading-[24px]': inputSize === 'md',
        'after:content-["*"] after:ml-[2px] after:text-R-500 after:inline-flex after:items-center after:mt-[4px] after:leading-[0]':
          required,
        'cursor-not-allowed': disabled
      }
    )

    /* Hint classes */
    const PhoneInputWithCountryCodeHintClasses = CN(
      'phone-input-with-country-code__hint select-text text-N-500 text-xs font-400 flex items-center w-full leading-[16px] cursor-default',
      {
        'text-R-500': hasError
      }
    )

    /* Input classes */
    const PhoneInputWithCountryCodeClasses = CN(
      `phone-input-with-country-code form-input rounded-[4px] inline-flex items-center text-N-700 border outline-none hover:bg-N-25 transition-all w-full`,
      {
        'focus:border-B-200 focus:shadow-input-focus focus-visible:border-B-200 focus-visible:shadow-input-focus':
          !hasError,
        '!border-R-500 !shadow-input-focus-error focus-visible:border-R-500 focus-visible:shadow-input-focus-error':
          hasError,
        'text-sm px-[12px] h-[40px] leading-[20px]': inputSize === 'sm',
        'text-base px-[12px] h-[44px] leading-[24px]': inputSize === 'md',
        'placeholder-N-400 placeholder-font-400': placeholder,
        'cursor-default': readOnly,
        'bg-N-100 border-N-200 pointer-events-none': disabled,
        'bg-white border-N-100': !disabled,
        '!pl-[85px]': countryDetails && countryDetails?.length > 1,
        '!pl-[75px]': countryDetails && countryDetails?.length <= 1,
        'pr-[40px]': iconAfter
      },
      className
    )

    const dropdownClasses = CN(
      'bg-white rounded-b-[2px] rounded-t-[1px] shadow-md overflow-hidden w-full min-w-[278px] absolute z-40',
      {
        'top-[43px]': inputSize === 'sm',
        'top-[45px]': inputSize === 'md'
      }
    )

    const DropdownMenuItemClasses = CN(
      `dropdown-menu-item py-[8px] px-[16px] text-sm text-N-700 cursor-pointer bg-white hover:bg-N-50 active:bg-B-25 focus:bg-B-25 w-full text-left focus:outline-none focus:text-B-500`
    )

    return (
      <div
        className={PhoneInputWithCountryCodeWrapperClasses}
        data-component-id='phone-input-with-country-code'>
        {label && (
          <label
            data-component-id={`phone-input-with-country-code-label-${componentId}`}
            aria-label='phone-input-with-country-code-label'
            className={PhoneInputWithCountryCodeLabelClasses}
            dangerouslySetInnerHTML={{ __html: label || '' }}
            htmlFor={id || 'phone-input-with-country-code__input'}
          />
        )}

        <div className='relative flex items-center'>
          {countryDetails && (
            <>
              <button
                id='phone-input-with-country-code-icon-before'
                aria-label={`phone-input-with-country-code-icon-before-${componentId}`}
                data-component-id={`phone-input-with-country-code-icon-before-${componentId}`}
                type='button'
                className={CN('flex items-center justify-center absolute pl-3 gap-[4px]', {
                  'cursor-pointer': onClickCountryFlag
                })}
                onClick={() => setIsOpen(!isOpen)}>
                <img src={selectedCountry?.flag} alt='flag' width={25} />
                {countryDetails.length > 1 && (
                  <i
                    className={CN(
                      `text-[8px] text-N-500 flex items-center justify-center ${isOpen ? 'nzsbi-chevron-up' : 'nzsbi-chevron-down'}`
                    )}
                  />
                )}
                <span
                  className={CN('text-N-800 pl-1', {
                    'text-sm': inputSize === 'sm',
                    'text-base': inputSize === 'md'
                  })}>
                  {selectedCountry?.code}
                </span>
              </button>
              {isOpen && countryDetails.length > 1 && (
                <div ref={divRef} className={dropdownClasses}>
                  {countryDetails.map(
                    (country: { country: string; code: string; flag: string }) => (
                      <li key={`country-${country}`} className='flex w-full'>
                        <button
                          data-component-id={`${componentId}-${country.country}`}
                          className={DropdownMenuItemClasses}
                          key={id}
                          onClick={() => {
                            setSelectedCountry(country)
                            if (onClickCountryFlag) onClickCountryFlag(country)
                            setIsOpen(false)
                          }}>
                          {`${country.country} (${country.code})`}
                        </button>
                      </li>
                    )
                  )}
                </div>
              )}
            </>
          )}

          <input
            data-component-id={`phone-input-with-country-code-input-${componentId}`}
            aria-label={`phone-input-with-country-code-input-${componentId}`}
            className={PhoneInputWithCountryCodeClasses}
            disabled={disabled}
            id={id || 'phone-input-with-country-code__input'}
            placeholder={placeholder}
            readOnly={readOnly}
            type='number'
            ref={ref}
            {...restProps}
          />

          {iconAfter && (
            <button
              data-component-id={`phone-input-with-country-code-icon-after-${componentId}`}
              id='phone-input-with-country-code-icon-after'
              aria-label={`phone-input-with-country-code-icon-after-${componentId}`}
              type='button'
              className={CN('absolute right-3', {
                'cursor-pointer': onClickIconAfter
              })}
              onClick={onClickIconAfter}>
              <i className={CN(`text-[20px] text-N-500 flex items-center ${iconAfter}`)} />
            </button>
          )}
        </div>

        {hint && typeof hint === 'string' && (
          <span
            data-component-id={`phone-input-with-country-code-hint-${componentId}`}
            className={PhoneInputWithCountryCodeHintClasses}
            dangerouslySetInnerHTML={{ __html: hint || '' }}
          />
        )}

        {hint && typeof hint !== 'string' && (
          <div
            data-component-id={`phone-input-with-country-code-hint-${componentId}`}
            className={PhoneInputWithCountryCodeHintClasses}>
            {hint}
          </div>
        )}
      </div>
    )
  }
)

PhoneInputWithCountryCode.displayName = 'PhoneInputWithCountryCode'

export default PhoneInputWithCountryCode
