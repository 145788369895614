import React, { FC } from 'react'
import { Modal, ModalBody, ModalHeader } from '@nzsb/shopnx-ui'
import { useModalContext } from 'lib/contexts/modal-context'

import { ChangePassword } from 'components/forms/ChangePassword'

export interface ChangePasswordModalProps {
  className?: string
}

export const ChangePasswordModal: FC<ChangePasswordModalProps> = ({
  className,
  ...restProps
}: ChangePasswordModalProps) => {
  const { changePasswordModal } = useModalContext()

  return (
    <Modal
      componentId='change-password'
      isOpen={changePasswordModal.isOpen}
      onClickOverlay={changePasswordModal.close}
      onClose={changePasswordModal.close}
      className='w-[444px] flex-shrink-0'
      {...restProps}>
      <ModalHeader
        componentId='change-password'
        onClose={changePasswordModal.close}
        heading={null}
        hasBorder={false}
      />
      <ModalBody componentId='sign-in' className='!pt-[0] px-[20px] !pb-[20px] cursor-auto'>
        <ChangePassword />
      </ModalBody>
    </Modal>
  )
}

export default ChangePasswordModal
