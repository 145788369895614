import { useRouter } from 'next/router'
import React, { FC } from 'react'
import CN from 'classnames'
import { useModalContext } from 'lib/contexts/modal-context'

import { MobileMenuSubHeader } from '../MobileMenuSubHeader'

export interface MobileMenuSubItemProps {
  className?: string
  componentId?: string
  mobileMenuItemsDetails?: {
    level: number
    selectedItem: string
  }
  mobileMenuSubItems?: {
    promotions?: Array<{
      subMenuItemName: string
      url: string
    }>
    catalogues?: Array<{
      subMenuItemName: string
      url: string
    }>
  }
  onBackClick?: () => void
  selectedMainMenuItem?: string
  subItemsList?: Array<{
    subMenuItemName: string
    url: string
  }>
}

export const MobileMenuSubItem: FC<MobileMenuSubItemProps> = ({
  className,
  componentId,
  mobileMenuItemsDetails,
  mobileMenuSubItems,
  onBackClick,
  selectedMainMenuItem,
  subItemsList,
  ...restProps
}: MobileMenuSubItemProps) => {
  const { mainMenuSidePane } = useModalContext()

  const router = useRouter()

  const MobileMenuSubItemClasses = CN(`w-full pt-7`, className)

  const routeMenuItems = (item: any) => {
    mainMenuSidePane?.close()
    if (item?.subMenuItemName === 'Current Promotions') {
      router.push('/current-promotions')
    } else if (item?.subMenuItemName === 'Deals') {
      router.push('/deals')
    } else if (item?.subMenuItemName === 'Clearance') {
      router.push('/clearance')
    } else {
      window.open(
        item?.url,
        '_blank',
        'toolbar=1,location=0,directories=0,status=0,menubar=1,scrollbars=1,resizable=1'
      )
    }
  }

  return (
    <>
      <MobileMenuSubHeader
        subHeaderTitle={mobileMenuItemsDetails?.selectedItem}
        onBackClick={onBackClick}
      />
      <div
        data-component-id={`${componentId}-section`}
        className={MobileMenuSubItemClasses}
        {...restProps}>
        {subItemsList?.map((item: any, index: number) => (
          <div
            data-component-id={`${componentId}-section-item-${index}`}
            key={`section-item-${index}`}
            className='flex justify-between items-center px-3 py-2'>
            <span
              tabIndex={0}
              role='button'
              className='font-500 text-sm text-N-700 px-3 py-2'
              data-component-id={`${componentId}-section-item-${index}-item-name`}
              onClick={() => {
                routeMenuItems(item)
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  routeMenuItems(item)
                }
              }}>
              {item?.subMenuItemName}
            </span>
          </div>
        ))}
      </div>
    </>
  )
}

export default MobileMenuSubItem
