import { useMutation } from '@tanstack/react-query'
import { httpPUT } from 'lib/http'

import { useGetCart } from './useGetCart'

export interface IRemoveItemsResponse {
  messageTitle: string
  messageDescription: string
  messageType: string
  data: string
}

export const removeAllItems = async (cartId: string) => {
  const { data } = await httpPUT({
    url: `cart/removeitems/${cartId}`
  })
  return data as IRemoveItemsResponse
}

export const useRemoveCartItems = () => {
  const { refetch: fetchGetCart } = useGetCart()
  return useMutation({
    mutationFn: async (cartId: string) => {
      const res = await removeAllItems(cartId)
      fetchGetCart()
      return res
    }
  })
}
