import { useRouter } from 'next/router'
import React, { FC } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from '@nzsb/shopnx-ui'
import { useSaveCartItems } from 'lib/actions/cart/useSaveCartItems'
import { useAppContext } from 'lib/contexts/app-context'
import { useModalContext } from 'lib/contexts/modal-context'
import { useSideBarContext } from 'lib/contexts/sideBar-context'

import { RenameCartModal } from '../RenameCartModal'

export interface ISaveCartModalProps {
  className?: string
}

export const SaveCartModal: FC<ISaveCartModalProps> = ({
  className,
  ...restProps
}: ISaveCartModalProps) => {
  const router = useRouter()
  const { cart } = useAppContext()

  const { quickCartSidebar } = useSideBarContext()
  const { myOrderPadModals } = useModalContext()

  const { saveCartItemsAsync, isPending: isLoadingCartUpdate } = useSaveCartItems()

  const saveCartItemsAction = async (cartName: string) => {
    const saveCartItemRequest = {
      cartId: cart?.cartId,
      name: cartName
    }
    return saveCartItemsAsync(saveCartItemRequest)
  }

  const closeSuccessModal = () => {
    myOrderPadModals?.closeSaveSuccessModal()
  }

  const { saveCartModal } = useModalContext()

  return (
    <>
      {saveCartModal?.isOpen && (
        <RenameCartModal
          title={'Save Cart'}
          description='Name and save your cart. This will create an Order Pad which you can view and re-order from by visiting your My Order Pad page in your My Account section.'
          textFieldProps={{
            label: 'Order Name'
          }}
          onCancel={function (): void {
            saveCartModal?.close()
          }}
          onClose={() => {
            saveCartModal?.close()
          }}
          onConfirm={saveCartItemsAction}
          confirmButtonTitle='SAVE CART'
          isLoading={isLoadingCartUpdate}
          onSuccess={() => {
            saveCartModal?.close()
            myOrderPadModals?.openSaveSuccessModal()
          }}
        />
      )}

      <Modal
        componentId='save-cart-modal'
        isOpen={myOrderPadModals.isOpenSaveSuccessModal}
        className='z-20 w-[380px]'
        {...restProps}>
        <ModalHeader
          componentId='save-cart-modal-header'
          className='border-[#D9D9D5] bg-N-25'
          hasBorder={true}
          heading='Save Cart'
          onClose={closeSuccessModal}
        />

        <ModalBody componentId='save-cart-modal-body' className='p-[20px]'>
          <div className='flex flex-col w-full gap-4'>
            <div className='flex gap-2 flex-col'>
              <div className='flex flex-row gap-2'>
                <i className='flex items-center nzsbi-check-1 text-G-500 text-[42px]' />
                <div className='font-display text-display-h2 font-normal text-N-800'>SUCCESS</div>
              </div>
              <p className='flex justify-start text-base font-sans font-normal text-N-700'>
                This order has been saved successfully. You can view all your saved orders in my
                order pad.
              </p>
            </div>
            <div className='flex w-full flex-col gap-2'>
              <Button
                componentId='save-cart-go-to-my-order-btn'
                appearance='primary-orange'
                contentClassName='mr-2 w-full'
                onClick={() => {
                  // go to my order pad page
                  myOrderPadModals?.closeSaveSuccessModal()
                  quickCartSidebar?.close()
                  // change router
                  router.push({
                    pathname: '/my-account/ordering/my-orderpad'
                  })
                }}>
                GO TO MY ORDER PAD
              </Button>
              <Button
                componentId='save-cart-close-this-window-btn'
                appearance='secondary-gray'
                contentClassName='mr-2 w-full'
                onClick={() => {
                  myOrderPadModals?.closeSaveSuccessModal()
                  quickCartSidebar?.close()
                }}>
                CLOSE THIS WINDOW
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}
