import { useRouter } from 'next/router'
import React, { FC, useEffect, useState } from 'react'
import { Button } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { BRAND_PAGE_TOP_FILTER_BUTTONS } from 'lib/static/globalStaticData'

export interface IBrandFilterSectionProps {
  className?: string
  componentId?: string
  onClickFilteringBtn?: any
  defaultSelectedIndex: number
}

export const BrandFilterSection: FC<IBrandFilterSectionProps> = ({
  className,
  componentId,
  defaultSelectedIndex,
  ...restProps
}: IBrandFilterSectionProps) => {
  const BrandFilterSectionClasses = CN(
    `${componentId}-brand-filter-list flex flex-row py-[12px] lg:!py-[24px] overflow-x-auto gap-x-2`,
    className
  )

  const router = useRouter()

  const [selectedBrandFilteringBtnIndex, setSelectedBrandFilteringBtnIndex] =
    useState(defaultSelectedIndex)

  const onClickFilteringBtn = (index: number) => {
    setSelectedBrandFilteringBtnIndex(index)
    if (BRAND_PAGE_TOP_FILTER_BUTTONS[index].value) {
      router.push(BRAND_PAGE_TOP_FILTER_BUTTONS[index].value || '')
    }
  }

  useEffect(() => {
    const index = BRAND_PAGE_TOP_FILTER_BUTTONS?.findIndex(
      (item: any) => item?.value === router?.asPath?.split('/')?.pop()
    )

    setSelectedBrandFilteringBtnIndex(index)
  }, [router?.asPath?.split('/')?.pop()])

  return (
    <div
      className={BrandFilterSectionClasses}
      {...restProps}
      data-component-id='brand-filter-section'>
      {BRAND_PAGE_TOP_FILTER_BUTTONS?.map((item: any, index: number) => (
        <Button
          className='cursor-not-allowed whitespace-nowrap'
          isRingOffset={false}
          key={`${item.label ?? ''}-${index}`}
          componentId='brand-page-filtering-button'
          size='xs'
          appearance={
            selectedBrandFilteringBtnIndex === index ? 'primary-deep-blue' : 'secondary-gray'
          }
          onClick={() => {
            onClickFilteringBtn(index)
          }}>
          {item.label}
        </Button>
      ))}
    </div>
  )
}

export default BrandFilterSection
