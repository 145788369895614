import { useRouter } from 'next/router'
import { useEffect } from 'react'

export const useWarnIfUnsavedChanges = (
  unsavedChanges: boolean,
  callback: (url?: string) => boolean
) => {
  const router = useRouter()
  useEffect(() => {
    const confirmationMessage = 'Changes you made may not be saved.'
    const beforeUnloadHandler = () => {
      return confirmationMessage // Gecko + Webkit, Safari, Chrome etc.
    }
    const beforeRouteHandler = (url: string) => {
      // to inform NProgress or something ...
      router.events.emit('routeChangeError', 'your error message', 'your-url', {
        shallow: false
      })

      // Calling the action needs to be executed after the pause
      callback(url)
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw `Route change to "${url}" was aborted (this error can be safely ignored). See https://github.com/zeit/next.js/issues/2476.`
    }

    if (unsavedChanges) {
      window.addEventListener('beforeunload', beforeUnloadHandler)
      router.events.on('routeChangeStart', beforeRouteHandler)
    } else {
      window.removeEventListener('beforeunload', beforeUnloadHandler)
      router.events.off('routeChangeStart', beforeRouteHandler)
    }
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler)
      router.events.off('routeChangeStart', beforeRouteHandler)
    }
  }, [unsavedChanges])
}
