/* eslint-disable @typescript-eslint/no-shadow */
import { useMutation } from '@tanstack/react-query'
import { httpGET } from 'lib/http'

export interface IOciPunchOutReq {
  CartId?: string
}

const ociPunchOutCheckOut = async (req: IOciPunchOutReq) => {
  const data = await httpGET({
    url: `checkout/punchout/oci/${req?.CartId}`
  })
  return data?.data
}

/* Client side react-query custom hook */
export const useOciPunchOutCheckout = () => {
  const { mutateAsync, ...restOptions } = useMutation({
    mutationFn: ociPunchOutCheckOut
  })

  // Passing mutateAsync as forget password
  // and restOptions as rest of the options
  return { ociPunchOutCheckOutAsync: mutateAsync, ...restOptions }
}
