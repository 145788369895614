import React, { FC } from 'react'
import { priceFormat } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { useAppContext } from 'lib/contexts/app-context'

export interface IOrderSummeryCardProps {
  className?: string
  componentId?: string
  subTotal?: number
  shippingCharges?: number
  showGstPricePrimary?: boolean
  gst?: number
  orderTotal?: number
}

export const OrderSummeryCard: FC<IOrderSummeryCardProps> = ({
  className,
  componentId,
  subTotal,
  shippingCharges,
  showGstPricePrimary = false,
  gst,
  orderTotal,
  ...restProps
}: IOrderSummeryCardProps) => {
  const OrderSummeryCardClasses = CN(
    `${componentId} flex flex-col border-[1px] border-N-100 w-full`,
    className
  )
  const { session } = useAppContext()
  return (
    <div className={OrderSummeryCardClasses} {...restProps}>
      <div className='flex flex-col bg-white px-[16px] p-[16px]'>
        <span className={`${componentId}-title text-xs font-bold text-B-500 capitalize`}>
          ORDER SUMMARY
        </span>
        <div className='flex flex-col py-[16px] gap-[12px]'>
          <div className={`${componentId}-subTotal flex flex-row justify-between`}>
            <span className={`${componentId}-subTotal-text text-sm font-normal text-N-700`}>
              Sub-total
            </span>
            {subTotal && (
              <span className={`${componentId}-subTotal-value text-sm font-medium text-N-700`}>
                ${priceFormat(subTotal)}
              </span>
            )}
          </div>

          {!session?.isPunchOut && (
            <div
              className={`${componentId}-shipping-charges flex flex-row justify-between text-sm`}>
              <span className={`${componentId}-shipping-charges-text font-normal text-N-700`}>
                Shipping Charges
              </span>

              <span className={`${componentId}-shipping-charges-value font-medium text-N-700`}>
                ${priceFormat(shippingCharges ?? 0)}
              </span>
            </div>
          )}

          <div className={`${componentId}-gstPrice flex flex-row justify-between`}>
            <span className={`${componentId}-gstPrice-text text-sm font-normal text-N-700`}>
              GST
            </span>
            {gst && (
              <span className={`${componentId}-gstPrice-value text-sm font-medium text-N-700`}>
                ${priceFormat(gst)}
              </span>
            )}
          </div>
        </div>
      </div>
      <div className='flex flex-col bg-B-25 px-[16px] py-[16px]'>
        <div className={`${componentId}-orderTotal flex flex-row justify-between`}>
          <span className={`${componentId}-orderTotal-text text-sm font-bold text-N-700`}>
            Order Total (NZD {showGstPricePrimary && 'incl.GST'})
          </span>
          {orderTotal && (
            <span className={`${componentId}-orderTotal-value text-sm font-bold text-N-700`}>
              ${priceFormat(orderTotal)}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export default OrderSummeryCard
