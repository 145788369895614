import React, { FC } from 'react'
import CN from 'classnames'

export interface INavigationProps {
  className?: string
}

export const Navigation: FC<INavigationProps> = ({ className, ...restProps }: INavigationProps) => {
  const NavigationClasses = CN(`navigation bg-[#3467A5] py-[8px] text-white`, className)

  return (
    <div className={NavigationClasses} {...restProps}>
      <div className='container text-sm'>Todo: Navigation</div>
    </div>
  )
}

export default Navigation
