import { useMutation } from '@tanstack/react-query'
import { httpDELETE } from 'lib/http'

export interface IDeleteFavoriteResponse {
  messageTitle: string
  messageDescription: string
  messageType: string
  data: string
}

export const deleteToFavorite = async (webId: string, context?: any) => {
  const { data } = await httpDELETE({
    url: `account/removefavourite/${webId}`,
    context
  })
  return data?.data as IDeleteFavoriteResponse
}

export const useDeleteFavoriteProduct = () => {
  return useMutation({
    mutationFn: deleteToFavorite
  })
}
