import Link from 'next/link'
import React, { FC } from 'react'
import { Dropdown } from '@nzsb/shopnx-ui'
import CN from 'classnames'

export interface BreadcrumbItemProps {
  basePath?: string
  className?: string
  isDisabled?: boolean
  id: string | number
  index?: number
  isElipsis?: boolean
  isLast?: any
  label: string
  slug?: string
  truncatedData?: any[]
}

export interface ItemPartialProps {
  [x: string]: any
  className?: string
  index?: number
  isDisabled?: boolean
  isElipsis?: boolean
  isLast?: boolean
  label?: string
  slug?: string
}

const ItemPartial: FC<ItemPartialProps> = ({
  basePath,
  className,
  index,
  isDisabled = false,
  isElipsis,
  isLast,
  label,
  slug,
  ...restProps
}: ItemPartialProps) => {
  const ItemPartialClasses = CN(
    `breadcrumb-item inline-flex items-center text-xs items-center gap-[4px] cursor-pointer`,
    {
      'text-N-700': !isLast && index !== 0,
      'text-B-500 font-medium': isLast && index !== 0,
      '!cursor-not-allowed': isDisabled,
      '!cursor-default': isLast
    },
    className
  )
  return (
    <div className={ItemPartialClasses} {...restProps}>
      <Link
        href={basePath ? `/${basePath}/${slug}` : slug ?? ''}
        className={CN({ '!pointer-events-none': isDisabled || isLast })}>
        <span
          className={CN({
            'underline underline-offset-2 decoration-N-400 hover:text-N-800': !isLast || index === 0
          })}>
          {label}
        </span>
        {!isLast && <i className='ri-arrow-right-s-line no-underline' />}
      </Link>
    </div>
  )
}

export const BreadcrumbItem: FC<BreadcrumbItemProps> = ({
  basePath,
  className,
  id,
  isElipsis,
  isLast,
  label,
  slug,
  index,
  truncatedData,
  ...restProps
}: BreadcrumbItemProps) => {
  const mappedData = truncatedData?.map(({ label: objLabel, ...objRestProps }: any) => {
    return {
      title: objLabel,
      ...objRestProps
    }
  })

  if (isElipsis) {
    return (
      <Dropdown
        list={mappedData}
        className='mt-[8px] min-w-[160px]'
        CustomToggle={() =>
          ItemPartial({
            isElipsis,
            label,
            isLast,
            slug,
            index
          })
        }
      />
    )
  }
  return (
    <ItemPartial
      data-component-id='breadcrumb-item'
      isElipsis={isElipsis}
      isLast={isLast}
      label={label}
      slug={slug}
      basePath={basePath}
      index={index}
      {...restProps}
    />
  )
}

export default BreadcrumbItem
