import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { FC, useEffect, useRef } from 'react'
import Skeleton from 'react-loading-skeleton'
import { AlertProps, Button, ProductSearchCard, Tabs, Toast } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { ISearchBarProduct, useAddToCart, useProductSearch } from 'lib/actions'
import { useAppContext } from 'lib/contexts/app-context'
import { useModalContext } from 'lib/contexts/modal-context'
import { checkRoleBasedProperties } from 'lib/utilities'

import { NextImage } from 'components/atoms'

export interface ProductSearchDropDownProps {
  className?: string
}

const RenderImage = (productData: any) => {
  return (
    <div className='relative h-[72px] w-[72px]'>
      <NextImage
        imgKey={`${productData?.sku}-${productData?.id}`}
        width={72}
        height={72}
        imageUrl={productData?.image?.url || 'non-existing-image'}
        alt={productData?.image?.alt || productData?.title}
      />
    </div>
  )
}

const ProductCard = (product: ISearchBarProduct) => {
  const { addToCartAsync, isPending: isLoadingAddToCartButton } = useAddToCart()

  const notify = (props: AlertProps) => Toast(props)

  const { productVariants, user } = useAppContext()
  const { bulkOrderModal } = useModalContext()

  const { showGstPricePrimary } = checkRoleBasedProperties(user?.data?.roles)

  const { searchResultDropdown } = useModalContext()

  const router = useRouter()

  const redirectToProductDetails = (slug: any) => {
    router.push(`/product/${slug}`)
    searchResultDropdown.close()
  }

  return (
    <ProductSearchCard
      key={product.sku}
      title={product?.title}
      price={{ price: product?.price, withGst: product?.priceWithGst }}
      tooltipId={product?.isDesktop ? `tab-${product?.sku}` : `dgdfg-${product?.sku}`}
      loading={false}
      sku={product?.sku}
      isShowCatalogIcon={product?.isCatalogue}
      isShowGstPricePrimary={showGstPricePrimary}
      isWorkSafeTraining={product?.isEnrolled}
      productFlag={product?.productFlag}
      nextImage={RenderImage(product)}
      onTitleClick={() => {
        redirectToProductDetails(product?.urlSlug)
      }}
      isLoadingAddToCart={isLoadingAddToCartButton}
      onClickAddToCart={() => {
        if (product?.isEnrolled) {
          router.push(`/worksafe-training/${product?.urlSlug}`)
        } else if (product?.hasVariant) {
          bulkOrderModal.set(true)
          productVariants.setProduct(product)
        } else {
          addToCartAsync({ productId: Number(product?.id), quantity: 1 }).then(res => {
            if (res?.data?.validationMessage) {
              notify({
                status: res?.data?.validationMessage?.messageType,
                alertHeader: res?.data?.validationMessage?.title,
                alertBody: res?.data?.validationMessage?.message
              })
            }
          })
        }
      }}
    />
  )
}

const ProductSearchDropDown: FC<ProductSearchDropDownProps> = ({
  className
}: ProductSearchDropDownProps) => {
  const dropDownBodyClass = CN(
    'search-dropdown-body bg-white p-[16px] bg-white sm:min-h-[400px] md:max-h-[400px] relative shadow-lg',
    className
  )

  const { searchResults, searchProductsAsync, isPending } = useProductSearch()
  const { searchResultDropdown } = useModalContext()

  const router = useRouter()

  const bodyRef = useRef<HTMLDivElement>(null)

  const onOverlayClick = () => {
    searchResultDropdown.close()
  }

  const onClickAddToCart = () => {}

  const seeAllAction = () => {
    router.push(`/search?q=${searchResultDropdown.searchKeyword}`)
    searchResultDropdown.close()
  }

  useEffect(() => {
    if (searchResultDropdown?.searchKeyword !== '') {
      searchProductsAsync({
        searchText: searchResultDropdown?.searchKeyword,
        pageIndex: 1,
        pageSize: 10
      })
    } else {
      searchResultDropdown.close()
    }
  }, [searchResultDropdown?.searchKeyword])

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (bodyRef?.current && !bodyRef.current?.contains(event.target)) {
        onOverlayClick()
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [bodyRef])

  const onSuggestionClick = (suggestionValue: string) => {
    router.push(`/search?q=${suggestionValue}`)
    searchResultDropdown.close()
  }

  const ProductSection = (props: { isDesktop?: boolean }) => {
    const { isDesktop } = props
    return (
      <>
        <div className='flex flex-row justify-between items-center mr-[12px] mb-[12px]'>
          <div className='text-xs font-bold text-B-500 uppercase px-[12px] mb-[8px]'>Products</div>
          <Button
            isRingOffset={false}
            componentId={
              isDesktop ? 'search-drop-down-see-all-desktop' : 'search-drop-down-see-all-mobile'
            }
            appearance='link'
            size='xs'
            onClick={seeAllAction}>
            See All
          </Button>
        </div>
        <div className='flex flex-col gap-[12px]'>
          {isPending && (
            <>
              {[1, 2, 3].map(item => (
                <ProductSearchCard
                  loading
                  key={item}
                  price={{}}
                  onClickAddToCart={onClickAddToCart}
                />
              ))}
            </>
          )}
          {(searchResults?.data?.products?.length === 0 ||
            searchResults?.data?.products === null) && (
            <div className='text-xs font-medium test-N-500'>No products found</div>
          )}
          {searchResults?.data?.products?.map(product => (
            <ProductCard key={product.id} {...product} isDesktop={isDesktop} />
          ))}
        </div>
      </>
    )
  }

  const CategorySection = () => {
    return (
      <>
        <div className='text-xs font-bold text-B-500 uppercase px-[12px] mb-[8px]'>
          Product Categories
        </div>
        <div className='flex flex-col gap-[4px] text-sm font-normal text-N-700 md:border-l-N-100 md:mr-[32px]'>
          {isPending ? (
            <div className='w-full md:w-[128px] lg:w-[188px] md:pr-[32px]'>
              <Skeleton
                count={7}
                width={'100%'}
                height={24}
                containerClassName='flex flex-col gap-[0px] px-[12px]'
              />
            </div>
          ) : (
            <>
              {(searchResults?.data?.categories?.length === 0 ||
                searchResults?.data?.categories === null) && (
                <div className='text-xs font-medium test-N-500'>No product categories found</div>
              )}
              {searchResults?.data?.categories?.map(category => (
                <Link
                  key={category?.id}
                  href={`/category/${category?.urlSlug}`}
                  className='text-sm hover:bg-B-25 px-[12px] py-[4px]'>
                  {category?.name}
                </Link>
              ))}
            </>
          )}
        </div>
      </>
    )
  }

  const SuggestionsSection = () => {
    return (
      <>
        <div className='text-xs font-bold text-B-500 uppercase px-[12px] mb-[8px]'>Suggestions</div>
        <div className='flex flex-col gap-[4px] text-sm font-normal text-N-700 md:border-l-N-100 md:mr-[32px]'>
          {isPending ? (
            <div className='w-full lg:max-w-[188px] md:pr-[32px]'>
              <Skeleton
                count={7}
                width={'100%'}
                height={24}
                containerClassName='flex flex-col gap-[0px]'
              />
            </div>
          ) : (
            <>
              {(searchResults?.data?.keywords?.length === 0 ||
                searchResults?.data?.keywords === null) && (
                <div className='text-xs font-medium test-N-500'>No suggestions found</div>
              )}
              {searchResults?.data?.keywords?.map(keyWord => (
                <button
                  key={keyWord?.name}
                  className='text-left text-sm py-[4px] px-[12px] hover:bg-B-25 cursor-pointer'
                  onClick={() => onSuggestionClick(keyWord?.name)}>
                  {keyWord?.name}
                </button>
              ))}
            </>
          )}
        </div>
      </>
    )
  }

  const AllCombinations = (props: { isDesktop?: boolean; className?: string }) => {
    const { isDesktop, className: classes } = props

    return (
      <div className={CN('flex flex-col md:flex-row gap-[16px] md:gap-[32px] xs:hidden', classes)}>
        {/* Categories Section */}
        <div className='pb-[16px] md:border-b-0 md:border-r border-b border-b-N-100 md:border-r-N-100 md:w-[25%] md:h-[372px] overflow-y-auto'>
          <CategorySection />
        </div>
        <div className='pb-[16px] md:border-b-0 md:border-r border-b border-b-N-100 md:border-r-N-100 md:w-[25%] md:h-[372px] overflow-y-auto'>
          <SuggestionsSection />
        </div>
        <div className='md:w-[50%] h-[372px] overflow-y-auto'>
          <ProductSection isDesktop={isDesktop} />
        </div>
      </div>
    )
  }

  if (!searchResultDropdown.isOpen) {
    return null
  }

  return (
    <div className='absolute z-50 w-full h-full dropDownModalMain section'>
      <div className='lg:!container md:!mt-[4px] lg:!mt-[-52px] z-50 mt-[8px]'>
        <div
          className={CN(
            'bg-white h-[20px] w-[20px] bg-red rotate-45 absolute z-0 shadow-sm top-[-3px] lg:top-[-60px] left-[20%] md:left-[50%] rounded-tl-[4px]'
          )}
          id='arrow'
        />
        {/* Body of the drop down */}
        <div className={dropDownBodyClass} ref={bodyRef}>
          <Tabs
            className={'md:hidden'}
            activeTabId={4}
            tabDetails={[
              {
                content: (
                  <div className='mt-[20px]'>
                    <AllCombinations />
                  </div>
                ),
                id: 1,
                title: 'All'
              },
              {
                content: (
                  <div className='mt-[20px]'>
                    <CategorySection />
                  </div>
                ),
                id: 2,
                title: 'Product Categories'
              },
              {
                content: (
                  <div className='mt-[20px]'>
                    <SuggestionsSection />
                  </div>
                ),
                id: 3,
                title: 'Suggestions'
              },
              {
                content: (
                  <div className='mt-[20px]'>
                    <ProductSection />
                  </div>
                ),
                id: 4,
                title: 'Products'
              }
            ]}
          />

          <AllCombinations isDesktop className='hidden md:!flex' />
        </div>
      </div>
    </div>
  )
}

export const ProductSearchDropDownMemo = React.memo(ProductSearchDropDown)

export default React.memo(ProductSearchDropDown)
