import React, { FC, useState } from 'react'
import { Accordion } from '@nzsb/shopnx-ui'
import CN from 'classnames'

import { YouTubeIFrame } from 'components/atoms'

export interface IVideoGuideProps {
  className?: string
  componentId?: string
  videos?: Array<{
    id?: string
    link: string
    description: string
  }>
}

export const VideoGuide: FC<IVideoGuideProps> = ({
  className,
  componentId,
  videos,
  ...restProps
}: IVideoGuideProps) => {
  const VideoGuideClasses = CN(`video-guide bg-B-25 rounded`, className)

  const [expandedVideoGuides, setExpandedVideoGuides] = useState<false | number>(0)

  return (
    <div className={VideoGuideClasses} {...restProps}>
      <Accordion
        className='w-full !border-t-0 !border-b-0'
        headerClasses='!normal-case !font-500 !text-base !text-B-500'
        isArrowIcons={false}
        setExpanded={setExpandedVideoGuides}
        expanded={expandedVideoGuides}
        item={{
          id: 0,
          content: (
            <div
              data-component-id={`${componentId}-content-body`}
              className='flex flex-col px-[4px] py-[8px] gap-[24px]'>
              <div className='flex flex-col justify-center gap-[24px]'>
                {videos?.map(video => (
                  <div className='flex flex-col gap-y-[24px] h-full' key={video.id}>
                    <YouTubeIFrame youtubeLink={video.link} className='w-full h-[393px]' />
                    <span className='flex font-serif font-normal text-base text-N-700'>
                      {video.description}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ),
          title: `VIDEO GUIDES (${videos?.length})`
        }}
        variant='blue'
      />
    </div>
  )
}

export default VideoGuide
