import { useMutation } from '@tanstack/react-query'
import { useAppContext } from 'lib/contexts/app-context'
import { httpPOST } from 'lib/http'

import { useGetCart } from './useGetCart'

export interface IAddItemsToCartResponse {
  messageTitle: string | null
  messageDescription: string | null
  messageType: string
  data: any
}

export interface IAddItemsToCartReq {
  cartItems: Array<{
    productId: number
    variantId?: number
    quantity: number
  }>
  cartId?: string
}

export const AddItemsToCart = async (req: IAddItemsToCartReq) => {
  const data = await httpPOST({
    url: 'cart/additems',
    body: req
  })

  return data?.data as IAddItemsToCartResponse
}

export const useAddItemsToCart = () => {
  /** Get cart Id */
  const { cart } = useAppContext()
  const { refetch: fetchGetCart } = useGetCart()

  /* Use Query */
  const { data, mutateAsync, ...restOptions } = useMutation({
    mutationFn: async (req: IAddItemsToCartReq) => {
      const res = await AddItemsToCart({ ...req, cartId: cart?.cartId })
      fetchGetCart()
      return res
    }
  })

  return { addItemsToCartResponse: data, addItemsToCartAsync: mutateAsync, ...restOptions }
}
