import { useMutation } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

export interface ILinkToCreditAccount {
  accountNumber: string
  delivarySequence: string
}

/* API Request */
export const linkToCreditAccountRequest = async (req: ILinkToCreditAccount) => {
  try {
    const response = await httpPOST({
      url: 'account/linktocreditaccount',
      body: req
    })
    /** When Needed to handle the Notification with default behavior ['Info' | 'Success' | 'Warning' | 'Error'] use handleResponseToast(response) */
    // handleResponseToast(response?.data)
    return response?.data
  } catch (error) {
    return { error }
  }
}

/* Client side react-query custom hook */
export const useLinkToCreditAccountRequest = () => {
  const { mutateAsync, ...restOptions } = useMutation({
    mutationFn: linkToCreditAccountRequest
  })

  // Passing mutateAsync as link To CreditAccount
  // and restOptions as rest of the options
  return { linkToCreditAccount: mutateAsync, ...restOptions }
}
