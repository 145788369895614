import { useMutation } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

export interface IPunchOutRequest {
  Token?: string
  Username?: string
  Password?: string
  isIframe?: boolean
  punchOutDetails?: {
    payloadID?: string
    timestamp?: string
    fromDomain?: string
    fromIdentity?: string
    toDomain?: string
    toIdentity?: string
    senderDomain?: string
    senderIdentity?: string
    senderUserAgent?: string
    buyerCookie?: string
    browserFormPostURL?: string
    to?: string
    ociHookURL?: string
    ociToken?: string
  }
}

/* API Request */
export const signInRequestWithRefreshToken = async (req: IPunchOutRequest) => {
  const response = await httpPOST({
    type: 'internal',
    url: '/punchOut/login',
    body: req
  })
  return response
}

/* Client side react-query custom hook */
export const useSignInWithRefreshToken = () => {
  const { mutateAsync, data, ...restOptions } = useMutation({
    mutationFn: signInRequestWithRefreshToken
  })

  // Passing mutateAsync as signIn
  // and restOptions as rest of the options
  return { signInWithRefreshToken: mutateAsync, data, ...restOptions }
}
