import React, { FC, useEffect } from 'react'
import { Button, Overlay } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { useLogout } from 'lib/actions/auth/logout'
import { useAppContext } from 'lib/contexts/app-context'
import { callMatrix } from 'lib/utilities/matrix'
export interface IPunchOutErrorProps {
  className?: string
}

export const PunchOutError: FC<IPunchOutErrorProps> = ({
  className,
  ...restProps
}: IPunchOutErrorProps) => {
  const PunchOutErrorClasses = CN(`punch-out-error z-[70] mx-10`, className)
  const { logout } = useLogout()
  const { session } = useAppContext()
  /* Call Matrix */
  useEffect(() => {
    if (session?.punchOutMethod) {
      callMatrix({
        metricName: `punchOut.${session?.punchOutMethod}.checkout`,
        tags: [`client:${session?.user?.data?.accountCode || 'unknown'}`, `status:error`]
      })
    }
  }, [session?.punchOutMethod])

  return (
    <Overlay className='flex items-center justify-center'>
      <div className={PunchOutErrorClasses} {...restProps}>
        <div className='punch-out-error-body flex flex-col justify-center px-2 md:!px-[40px] py-[20px] items-center bg-white border border-N-100 rounded'>
          <i className='punch-out-error-icon flex items-center nzsbi-error-1 text-R-500 text-[64px]' />

          <div className='punch-out-error-title mt-[12px] text-h2 font-bold text-N-800 text-center'>
            Sorry, Punchout Error has Occurred
          </div>

          <div className='punch-out-error-text-1 mt-[4px] text-base text-N-700 font-normal text-center'>
            An error has occurred and we were unable to send your cart back to your procurement
            environment.
          </div>

          <div className='w-full md:!w-[690px] flex flex-col md:flex-row punch-out-error-text-2 text-base text-N-700 font-normal !text-center'>
            Please close this session and try again. If the issue persists please contact us on{' '}
            <a
              href={`tel:'0800 660 660'`}
              className='punch-out-error-contact-no underline underline-offset-2 text-base text-N-700 font-500 text-center'>
              {` 0800 660 660`}
            </a>
          </div>
          <Button
            componentId='punch-out-error-close-session-btn'
            isRingOffset={false}
            onClick={() => {
              logout()
            }}
            className='mt-0 md:mt-6'
            appearance='primary-orange'>
            Close Session
          </Button>
        </div>
      </div>
    </Overlay>
  )
}

export default PunchOutError
