import { useMutation } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

export interface IConfirmEmail {
  userId: string
  emailToken: string
}

/* API Request */
export const confirmEmailRequest = async (req: IConfirmEmail) => {
  const response = await httpPOST({
    type: 'internal',
    url: '/auth/verifyEmail',
    body: req
  })
  return response
}

/* Client side react-query custom hook */
export const useConfirmEmail = () => {
  const { mutateAsync, ...restOptions } = useMutation({
    mutationFn: confirmEmailRequest
  })

  // Passing mutateAsync as use emailVerification
  // and restOptions as rest of the options
  return { confirmEmail: mutateAsync, ...restOptions }
}
