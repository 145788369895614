import { useMutation } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

const courseEnroll = async (courseDetails: any) => {
  const response = await httpPOST({
    url: '/product/course_enrollment',
    body: {
      ...courseDetails
    }
  })

  return response?.data
}

export const useCourseEnroll = () => {
  const { mutateAsync, ...restOptions } = useMutation({
    mutationFn: courseEnroll
  })

  return { courseEnrollAsync: mutateAsync, ...restOptions }
}
