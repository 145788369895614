import { useMutation } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

export interface IForgotPasswordInfo {
  emailAddress: string
}

export interface IForgotPasswordResponse {
  data: any
  messageDescription: null | string
  messageTitle: string
  messageType: string
}

const forgotPassword = async ({ emailAddress }: IForgotPasswordInfo) => {
  try {
    const response = await httpPOST({
      endpoint: 'identity',
      url: '/auth/forgetPassword',
      body: {
        emailAddress
      }
    })
    /** When Needed to handle the Notification with default behavior ['Info' | 'Success' | 'Warning' | 'Error'] use handleResponseToast(response) */
    // handleResponseToast(response?.data)
    return response?.data
  } catch (error) {
    return { error }
  }
}

/* Client side react-query custom hook */
export const useForgotPassword = () => {
  const { mutateAsync, ...restOptions } = useMutation({
    mutationFn: forgotPassword
  })

  // Passing mutateAsync as forgot password
  // and restOptions as rest of the options
  return { forgotPasswordAsync: mutateAsync, ...restOptions }
}
