import router from 'next/router'
import React, { FC } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from '@nzsb/shopnx-ui'
import { useModalContext } from 'lib/contexts/modal-context'

import { NextImage } from 'components/atoms'

export interface IComingSoonModalProps {
  className?: string
  [x: string]: any
}

export const ComingSoonModal: FC<IComingSoonModalProps> = ({
  className,
  ...restProps
}: IComingSoonModalProps) => {
  const { comingSoonModal } = useModalContext()

  const onClickFeedBack = () => {
    router.push('/feedback')
    comingSoonModal.close()
  }

  return (
    <Modal
      isOpen={comingSoonModal?.isOpen}
      className='z-20 !w-[380px] md:!w-[620px]'
      {...restProps}>
      <ModalHeader hasBorder={false} heading={null} onClose={comingSoonModal?.close} />

      <ModalBody className='p-[20px] pt-0'>
        <div className='flex flex-col justify-center items-center text-center'>
          <div className='flex flex-col items-center justify-center relative w-[245px] h-[200px]'>
            <NextImage
              data-component-id='coming-soon-image'
              imgKey='img-busy-coding_fqzre8'
              system='General'
              imageUrl='img-busy-coding_fqzre8.png'
              useWithDimensions={false}
              alt='Busy Coding'
            />
          </div>
          <p className='font-display font-400 text-display-h1-m text-N-800 pt-8 uppercase'>
            We Hope You are enjoying our new website
          </p>

          <p className='font-400 text-base text-N-500 pt-2'>
            Sorry, we are currently building this page to bring you an improved experience.
          </p>
        </div>
      </ModalBody>

      <ModalFooter>
        <div className='flex gap-[16px]'>
          <Button appearance='secondary-gray' size='xs' onClick={comingSoonModal?.close}>
            CLOSE
          </Button>

          <Button appearance='primary-orange' size='xs' onClick={onClickFeedBack}>
            FEEDBACK
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default ComingSoonModal
