import { useQuery } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

export interface IGetOrderPadsResponse {
  messageTitle: string | null
  messageDescription: string | null
  messageType: string
  data: {
    resultCount: number
    pageIndex: number
    pageSize: number
    totalPages: number
    orderPads: [
      {
        orderPadId: '3fa85f64-5717-4562-b3fc-2c963f66afa6'
        orderPadName: 'string'
        orderPadCount: 0
        total: 0
        items: [
          {
            id: '3fa85f64-5717-4562-b3fc-2c963f66afa6'
            webId: 0
            name: 'string'
            sku: 'string'
            isRestricted: true
            isClearance: true
            isTemporaryUnavailable: true
            isDeal: true
            isNewlyAdded: true
            productFlag: 0
            quantity: 0
            price: 0
            priceWithGST: 0
            totalPrice: 0
            totalPriceWithGST: 0
          }
        ]
      }
    ]
  }
}

export interface IOrderPadItem {
  id: string
  webId: number
  productId: number
  variantId: number
  name: string
  sku: string
  isRestricted: boolean
  isClearance: boolean
  isTemporaryUnavailable: boolean
  isDeal: boolean
  isNewlyAdded: boolean
  productFlag: number
  quantity: number
  price: number
  priceWithGst: number
  stock: number
  totalPrice: number
  totalPriceWithGST: number
}

export interface IOrderPad {
  orderPadId: string
  orderPadName: string
  orderPadCount: number
  total: number
  shippingCharge?: number
  shippingChargeWithGst?: number
  grossAmount?: number
  gstAmount?: number
  totalAmount?: number
  items: IOrderPadItem[]
}

export interface IGetOrderPadRequest {
  searchText: string
  pageIndex: number
  pageSize: number
}

export const getOrderPads = async (
  req: any = {
    searchText: '',
    pageIndex: 1,
    pageSize: 10
  },
  context?: any
) => {
  const { data } = await httpPOST({
    url: `getorderpads`,
    context,
    body: req as IGetOrderPadRequest
  })
  return data as IGetOrderPadsResponse
}

export const useGetOrderPads = () => {
  return useQuery({ queryKey: ['GET_ORDER_PADS'], queryFn: () => getOrderPads() })
}
