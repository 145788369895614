/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { FC, useEffect, useState } from 'react'
import { Button } from '@nzsb/shopnx-ui'
import CN from 'classnames'

export interface IPerPageItemCountProps {
  className?: string
  activePageSize: number
  buttonValues: number[]
  componentId?: string
  onChange?: any
  onclick?: any
  pageIndex?: number
  totalCount?: any
  enableBlockButton?: boolean
}

export const PerPageItemCount: FC<IPerPageItemCountProps> = ({
  className,
  activePageSize,
  buttonValues,
  componentId,
  onChange,
  onclick,
  pageIndex = 1,
  totalCount,
  enableBlockButton,
  ...restProps
}: IPerPageItemCountProps) => {
  const PerPageItemCountClasses = CN(
    `${componentId}-per-page-item-count flex flex-col w-full sm:w-fit gap-y-[8px]`,
    className
  )

  const [active, setActive] = useState<any>(buttonValues?.indexOf(activePageSize))

  useEffect(() => {
    setActive(buttonValues?.indexOf(activePageSize))
  }, [activePageSize])

  useEffect(() => {
    if (onChange) onChange(buttonValues[active], pageIndex)
  }, [active, pageIndex])

  return (
    <div className={PerPageItemCountClasses} {...restProps}>
      {
        <p className='font-medium text-N-600'>
          {Number(pageIndex) * Number(buttonValues[active]) - Number(buttonValues[active]) + 1} -{' '}
          {Number(pageIndex) * Number(buttonValues[active]) >= totalCount
            ? totalCount
            : Number(pageIndex) * Number(buttonValues[active])}{' '}
          of {totalCount} Products
        </p>
      }

      <div className='flex flex-row items-start gap-x-[8px]'>
        {buttonValues?.map((value, id) => {
          return (
            <Button
              componentId={`${componentId}-per-page-item-count-button-${id}`}
              key={id}
              disabled={(active !== id && totalCount === 0) || totalCount + 29 < value}
              className={CN('flex justify-center w-[47px] focus:ring-offset-0 focus:ring-0', {
                'w-full': enableBlockButton
              })}
              appearance={active === id ? 'primary-deep-blue' : 'secondary-gray'}
              type='button'
              size='sm'
              onClick={() => {
                setActive(id)
                onclick && onclick(value)
              }}>
              {value}
            </Button>
          )
        })}
      </div>
    </div>
  )
}

export default PerPageItemCount
