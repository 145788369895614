/* eslint-disable import/no-extraneous-dependencies */
import Link from 'next/link'
import React, { FC } from 'react'
import { Button } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { checkURL } from 'lib/utilities'

import { NextImage } from 'components/atoms'

export interface IFeaturedCategoryBlockProps {
  categoryItemClassName?: string
  categoryItems?: {
    id?: string
    Header?: string
    Action?: { id?: string; DisplayText?: string; URL?: string }
    Categories?: { id?: string; DisplayText?: string; URL?: string }[]
  }
  className?: string
  componentId?: string
}

export const FeaturedCategoryBlock: FC<IFeaturedCategoryBlockProps> = ({
  categoryItemClassName,
  categoryItems,
  className,
  componentId,
  ...restProps
}: IFeaturedCategoryBlockProps) => {
  const FeaturedCategoryBlockClasses = CN(
    `featured-category-block bg-white w-full break-words`,
    className
  )

  const {
    originalUrl: actionOriginalUrl,
    restUrlWithQuery: actionQuery,
    isInsideUrl: actionUrlIsInternal
  } = checkURL(categoryItems?.Action?.URL ?? '')

  const mappedItems = categoryItems?.Categories?.map((item: any) => {
    const {
      originalUrl: categoryOriginalUrl,
      restUrlWithQuery: categoryQuery,
      isInsideUrl: categoryUrlIsInternal
    } = checkURL(item?.URL ?? '')
    return {
      ...item,
      url: categoryUrlIsInternal ? categoryQuery : categoryOriginalUrl,
      target: categoryUrlIsInternal ? '' : '_blank'
    }
  })

  return (
    <div className={FeaturedCategoryBlockClasses} {...restProps}>
      <div className='flex flex-col sm:flex-row md:flex-row gap-y-3 sm:gap-y-0 md:gap-y-0 sm:justify-between md:justify-between'>
        <span className='font-700 md:font-700 lg:!font-600 text-h3-m md:text-h3-m lg:!text-h3 text-N-800'>
          {categoryItems?.Header}
        </span>

        {categoryItems?.Action?.DisplayText && (
          <Link
            href={actionUrlIsInternal ? actionQuery : actionOriginalUrl ?? {}}
            passHref
            target={actionUrlIsInternal ? '' : '_blank'}
            className={CN({
              'cursor-pointer': actionOriginalUrl,
              'cursor-auto pointer-events-none': !actionOriginalUrl
            })}>
            <Button
              className='w-fit'
              size='md'
              isBlock={false}
              isRingOffset={false}
              componentId={`${componentId}-see-all`}
              appearance='link'
              iconAfter='nzsbi-chevron-right'>
              {categoryItems?.Action?.DisplayText}
            </Button>
          </Link>
        )}
      </div>

      <div className='grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-4 pt-6 gap-x-4 sm:gap-x-5 lg:gap-x-8 gap-y-5'>
        {mappedItems?.map((item: any, index: number) => {
          return (
            <Link
              key={item?.url}
              href={item?.url ?? {}}
              passHref
              target={item.target}
              className={CN({
                'cursor-pointer': item?.url,
                'cursor-auto pointer-events-none': !item?.url
              })}>
              <div
                key={item?.id}
                className={CN(
                  'border border-N-100 rounded-sm flex max-w-[190px] sm:max-w-[151px] md:max-w-[216px] h-[176px] sm:h-[192px] md:h-[188px] relative shadow-sm justify-center items-center',
                  categoryItemClassName
                )}>
                <NextImage
                  nextImgStyle={{
                    padding: '36px 16px 54px 16px',
                    objectFit: 'contain'
                  }}
                  data-component-id={`${componentId}-category-item-${index}`}
                  imgKey={item?.Thumbnail?.data?.id}
                  imageUrl={item?.Thumbnail?.data?.attributes?.provider_metadata?.public_id}
                  system='CMS'
                  useWithDimensions={false}
                  alt={
                    item?.Thumbnail?.data?.attributes?.alternativeText
                      ? item?.Thumbnail?.data?.attributes?.alternativeText
                      : ''
                  }
                  {...restProps}
                />

                <span className='font-500 text-base text-N-700 absolute bottom-0 mb-[10px] w-full text-center line-clamp-2'>
                  {item?.DisplayText}
                </span>
              </div>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default FeaturedCategoryBlock
