export const flagTypeMap = [
  { enum: 0, displayValue: '', appearance: '', variant: '' },
  {
    enum: 1,
    displayValue: 'Clearance',
    appearance: 'outline',
    variant: 'danger'
  },
  { enum: 2, displayValue: 'Deal', appearance: 'fill', variant: 'danger' },
  {
    enum: 3,
    displayValue: 'RESTRICTED SUPPLY',
    appearance: 'fill',
    variant: 'warning-1'
  },
  {
    enum: 4,
    displayValue: 'TEMPORARILY unavailable',
    appearance: 'fill',
    variant: 'neutral'
  },
  { enum: 5, displayValue: 'New', appearance: 'fill', variant: 'info' }
]

/** These are IRS define strings.. these enums will be change in future when strings are change from IRS */
export const productLinkTypes = {
  RESOURCES: 'Resources',
  SAFETY_DATA_SHEETS: 'Safety Data Sheets',
  SIZE_GUIDE: 'Size Guide',
  VIDEO_GUIDE: 'Video Guide'
}
