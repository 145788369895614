import { useMutation } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

/* API Request */
export const emailVerificationRequest = async (emailAddress: string) => {
  try {
    const response = await httpPOST({
      endpoint: 'identity',
      url: 'auth/verifyemail',
      body: { emailAddress }
    })

    return response
  } catch (error) {
    return { error }
  }
}

/* Client side react-query custom hook */
export const useEmailVerification = () => {
  const { mutateAsync, ...restOptions } = useMutation({
    mutationFn: emailVerificationRequest
  })

  // Passing mutateAsync as use emailVerification
  // and restOptions as rest of the options
  return { emailVerification: mutateAsync, ...restOptions }
}
