import Link from 'next/link'
import React, { FC } from 'react'
import { Button } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { HomeExpertAdviceBlock } from 'lib/actions'
import { checkURL } from 'lib/utilities'

import { NextImage } from 'components/atoms'

export interface ExpertAdviceSectionProps {
  className?: string
  componentId?: string
  expertAdviceItems?: HomeExpertAdviceBlock
}

export const ExpertAdviceSection: FC<ExpertAdviceSectionProps> = ({
  className,
  componentId,
  expertAdviceItems,
  ...restProps
}: ExpertAdviceSectionProps) => {
  //const router = useRouter()

  const mappedExpertAdvices = expertAdviceItems?.Advices?.data?.map((item: any) => {
    const {
      originalUrl: advicesOriginalUrl,
      restUrlWithQuery: advicesQuery,
      isInsideUrl: advicesUrlIsInternal
    } = checkURL(item?.attributes?.CardAction?.URL ?? '')
    return {
      ...item,
      url: advicesUrlIsInternal ? advicesQuery : advicesOriginalUrl,
      target: advicesUrlIsInternal ? '' : '_blank'
    }
  })

  const {
    originalUrl: actionOriginalUrl,
    restUrlWithQuery: actionRestUrlWithQuery,
    isInsideUrl: actionIsInsideUrl
  } = checkURL(expertAdviceItems?.Action?.URL ?? '')

  const ExpertAdviceSectionClasses = CN(className)

  return (
    <div className={ExpertAdviceSectionClasses} {...restProps}>
      {/** Expert Advice Header Section */}
      <div
        data-component-id={`${componentId}-header`}
        className='flex flex-col lg:!flex-row lg:justify-between'>
        <span
          data-component-id={`${componentId}-header-title`}
          className='font-600 text-h3 text-N-800'>
          {expertAdviceItems?.HomeExpertAdviceBlockHeader}
        </span>

        {expertAdviceItems?.Action && (
          <Link
            href={actionIsInsideUrl ? actionRestUrlWithQuery : actionOriginalUrl ?? {}}
            passHref
            target={actionIsInsideUrl ? '' : '_blank'}>
            <Button
              appearance='link'
              className='w-fit pt-2 lg:pt-0'
              componentId={`${componentId}-header-action-btn`}
              iconAfter='nzsbi-chevron-right'
              isRingOffset={false}
              size='md'
              {...expertAdviceItems?.Action}>
              {expertAdviceItems?.Action?.DisplayText}
            </Button>
          </Link>
        )}
      </div>

      {/** Expert Advice Items Section */}
      <div
        data-component-id={`${componentId}-items-container`}
        className='flex gap-x-8 max-w-[1280px] overflow-x-auto no-scrollbar'>
        {Array.isArray(expertAdviceItems?.Advices?.data) &&
          mappedExpertAdvices?.map((item: any, index: number) => (
            <div
              key={item?.attributes?.CardImage?.data?.id}
              className='border-[1px] border-N-100 rounded shadow-sm w-[293px] md:w-[296px] min-w-[293px] md:min-w-[296px] mt-6 relative'
              {...restProps}>
              {/** Expert Advice Item Image Section */}
              <Link
                aria-label={`expert-advice-${item?.attributes?.CardImage?.data?.id}`}
                href='/coming-soon'>
                <div data-component-id={`${componentId}-item-${index}`} className='px-2 pt-2'>
                  <div
                    data-component-id={`${componentId}-item-${index}-image-section`}
                    className='relative h-[304px]'>
                    <NextImage
                      nextImgStyle={{ objectFit: 'fit', padding: 'opx 8px opx 8px' }}
                      data-component-id={`${componentId}-item-${index}-image`}
                      imgKey={item?.attributes?.CardImage?.data?.id ?? ''}
                      imageUrl={
                        item?.attributes?.CardImage?.data?.attributes?.provider_metadata?.public_id
                      }
                      system='CMS'
                      useWithDimensions={false}
                      alt={item?.attributes?.CardImage?.data?.attributes?.alternativeText ?? ''}
                    />
                  </div>
                </div>
              </Link>

              {/** Expert Advice Items Detail Section*/}
              <div
                data-component-id={`${componentId}-item-${index}-detail-section`}
                className='flex flex-col p-4 mb-4'>
                <Link
                  href='/coming-soon'
                  data-component-id={`${componentId}-item-${index}-detail-section-header`}
                  className='font-700 text-base text-N-700'>
                  {item?.attributes?.Header}
                </Link>

                <span
                  data-component-id={`${componentId}-item-${index}-detail-section-description`}
                  className='font-400 text-base text-N-700 pt-2 pb-4 break-words max-w-[293px] md:max-w-[296px]'>
                  {item?.attributes?.Description}
                </span>
              </div>

              {/** Expert Advice Item Action Button */}
              <Link passHref href={item?.url ?? {}} target={item.target}>
                <Button
                  componentId={`${componentId}-item-${index}-detail-section-action-btn`}
                  appearance='link'
                  isRingOffset={false}
                  className='w-fit absolute bottom-0 p-4'
                  size='md'>
                  {item?.attributes?.CardAction?.DisplayText}
                </Button>
              </Link>
            </div>
          ))}
      </div>
    </div>
  )
}

export default ExpertAdviceSection
