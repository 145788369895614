/* eslint-disable @typescript-eslint/naming-convention */
import { setCookie } from 'cookies-next'
import axios from 'axios'
import { IEndpointType, IHttpGET, IHttpPOST } from 'types/http'

/**
 * getAxiosInstance - Get axios instance
 * @param endpoint - [string] Which instance to call 'api' | 'matrix' | 'identity' | 'cms >> default is api
 * @param baseURL - [string] Which base url to call. This is only needed when you want to call a different base url
 * @param type - [string] internal | external >> default is external
 * @returns - [object] axios instance
 * @eg: getAxiosInstance('api', 'http://localhost:3000')
 */
export const getAxiosInstance = (endpoint: IEndpointType, baseURL?: string, type?: any) => {
  return axios.create({
    withCredentials: true,
    baseURL: `${process.env.NEXT_PUBLIC_INTERNAL_API_URI}/api`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'x-access-point': type === 'internal' ? 'internal' : 'external',
      'Access-Control-Allow-Origin': '*',
      'x-end-point': endpoint
    }
  })
}

/* Prepare API call headers */
export const getConfig = ({ config, accessToken, refreshToken }: any) => {
  const acTkn = config?.headers?.accessToken || accessToken
  const rfTkn = config?.headers?.refreshToken || refreshToken
  return {
    ...config,
    headers: {
      ...config?.headers,
      'x-access-token': acTkn,
      'x-refresh-token': rfTkn
    }
  }
}

/**
 * handleRememberMeWithCookies - Handle remember me with cookies
 * @param headers - [object] headers object
 * @param data - [object] data object
 * @param req - [object] req object
 * @returns - [void] void
 * @eg: handleRememberMeWithCookies(headers, data, req)
 */
const handleRememberMeWithCookies = async (headers: any, data: any, req: any) => {
  /* Set cookies 🍪 */
  if (headers['Set-Cookie']) {
    headers['Set-Cookie'].forEach((cookie: string) => {
      const [key, value] = cookie.split('=')
      setCookie(key, value, {
        path: '/',
        sameSite: 'none',
        secure: true,
        httpOnly: true
      })
    })
  }
  if (data?.session?.type === 'gotNewToken' && req) {
    try {
      req.session.accessToken = data?.session?.newToken?.accessToken
      req.session.refreshToken = data?.session?.newToken?.refreshToken
      await req.session.save()
    } catch (error) {
      console.log('error', error)
    }
  }
}

/**
 * httpGET - GET data from API
 * @param url - [string] url
 * @param params - [object] Which contains headers
 * @param endpoint - [string] Which instance to call 'api' | 'matrix' | 'identity' | 'cms >> default is api
 * @param baseURL - [string] Which base url to call. This is only needed when you want to call a different base url
 * @param context - [object] context object from getServerSideProps or getStaticProps
 * @param type - [string] internal | external >> default is external
 * @returns - [object] response object
 * @eg: httpGET({ url: '/test' })
 */
export async function httpGET({
  url,
  params = {},
  endpoint = 'api',
  baseURL = undefined,
  context = undefined,
  type = 'external'
}: IHttpGET) {
  const { req }: any = context || {}
  /* Prepare axios options with auth header bearer token  */
  const options = getConfig({
    config: params,
    accessToken: req?.session?.accessToken || '',
    refreshToken: req?.session?.refreshToken || ''
  })

  /* Run the API call */
  const { data, headers } = await getAxiosInstance(endpoint, baseURL, type).get(
    url,
    options // << -- Headers are here
  )
  await handleRememberMeWithCookies(headers, data, req)
  /* Return data */
  return data
}

/**
 * httpPOST - POST data to API
 * @param url - [string] url
 * @param params - [object] Which contains headers
 * @param body - [object] Which contains body
 * @param endpoint - [string] Which instance to call 'api' | 'matrix' | 'identity' | 'cms >> default is api
 * @param baseURL - [string] Which base url to call. This is only needed when you want to call a different base url
 * @param context - [object] context object from getServerSideProps or getStaticProps
 * @param type - [string] internal | external >> default is external
 * @returns - [object] response object
 * @eg: httpPOST({ url: '/test', body: { test: 1 } })
 */
export async function httpPOST({
  url,
  params = {},
  body = undefined,
  endpoint = 'api',
  baseURL = undefined,
  context = undefined,
  type = 'external'
}: IHttpPOST) {
  const { req }: any = context || {}

  /* Prepare axios options with auth header bearer token  */
  const options = getConfig({
    config: params,
    accessToken: req?.session?.accessToken || '',
    refreshToken: req?.session?.refreshToken || ''
  })

  /* Run the API call */
  const { data, headers } = await getAxiosInstance(endpoint, baseURL, type).post(url, body, options)

  await handleRememberMeWithCookies(headers, data, req)
  /* Return data */
  return data
}

/**
 * httpPUT - PUT data to API
 * @param url - [string] url
 * @param params - [object] Which contains headers
 * @param body - [object] Which contains body
 * @param endpoint - [string] Which instance to call 'api' | 'matrix' | 'identity' | 'cms >> default is api
 * @param baseURL - [string] Which base url to call. This is only needed when you want to call a different base url
 * @param context - [object] context object from getServerSideProps or getStaticProps
 * @returns - [object] response object
 * @eg: httpPUT({ url: '/test', body: { test: 1 } })
 */
export const httpPUT = async ({
  url,
  params = {},
  body = undefined,
  endpoint = 'api',
  baseURL = undefined,
  context = undefined
}: IHttpPOST) => {
  const { req }: any = context || {}

  /* Prepare axios options with auth header bearer token  */
  const options = getConfig({
    config: params,
    accessToken: req?.session?.accessToken || '',
    refreshToken: req?.session?.refreshToken || ''
  })

  /* Run the API call */
  const { data, headers } = await getAxiosInstance(endpoint, baseURL).put(url, body, options)

  await handleRememberMeWithCookies(headers, data, req)
  /* Return data */
  return data
}

/**
 * httpPUT - PUT data to API
 * @param url - [string] url
 * @param params - [object] Which contains headers
 * @param body - [object] Which contains body
 * @param endpoint - [string] Which instance to call 'api' | 'matrix' | 'identity' | 'cms >> default is api
 * @param baseURL - [string] Which base url to call. This is only needed when you want to call a different base url
 * @param context - [object] context object from getServerSideProps or getStaticProps
 * @returns - [object] response object
 * @eg: httpPUT({ url: '/test', body: { test: 1 } })
 */
export const httpPatch = async ({
  url,
  params = {},
  body = undefined,
  endpoint = 'api',
  baseURL = undefined,
  context = undefined
}: IHttpPOST) => {
  const { req }: any = context || {}

  /* Prepare axios options with auth header bearer token  */
  const options = getConfig({
    config: params,
    accessToken: req?.session?.accessToken || '',
    refreshToken: req?.session?.refreshToken || ''
  })

  /* Run the API call */
  const { data, headers } = await getAxiosInstance(endpoint, baseURL).patch(url, body, options)

  await handleRememberMeWithCookies(headers, data, req)
  /* Return data */
  return data
}

/**
 * httpDELETE - DELETE data from API
 * @param url - [string] url
 * @param params - [object] Which contains headers
 * @param endpoint - [string] Which instance to call 'api' | 'matrix' | 'identity' | 'cms >> default is api
 * @param baseURL - [string] Which base url to call. This is only needed when you want to call a different base url
 * @param context - [object] context object from getServerSideProps or getStaticProps
 * @returns - [object] response object
 * @eg: httpDELETE({ url: '/test' })
 */
export const httpDELETE = async ({
  url,
  params = {},
  endpoint = 'api',
  baseURL = undefined,
  context = undefined
}: IHttpPOST) => {
  const { req }: any = context || {}

  /* Prepare axios options with auth header bearer token  */
  const options = getConfig({
    config: params,
    accessToken: req?.session?.accessToken || '',
    refreshToken: req?.session?.refreshToken || ''
  })

  /* Run the API call */
  const { data, headers } = await getAxiosInstance(endpoint, baseURL).delete(url, options)

  await handleRememberMeWithCookies(headers, data, req)
  /* Return data */
  return data
}
