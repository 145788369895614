import { useMutation } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

import { useGetOrderPads } from './useGetOrderPads'

export interface IUpdateOrderPadItemsResponse {
  messageTitle: string | null
  messageDescription: string | null
  messageType: string
  data: any
}

export interface IUpdateOrderPadItemsReq {
  items: Array<{
    productId: number
    variantId?: number
    quantity: number
  }>
  id?: string
}

export const updateOrderPadItems = async (req: IUpdateOrderPadItemsReq) => {
  const data = await httpPOST({
    url: `orderpad/update`,
    body: req
  })

  return data?.data as IUpdateOrderPadItemsResponse
}

export const useUpdateOrderPadItems = () => {
  const { refetch: fetchGetOrderPad } = useGetOrderPads()

  const { data, mutateAsync, ...restOptions } = useMutation({
    mutationFn: async (req: IUpdateOrderPadItemsReq) => {
      await updateOrderPadItems(req)
      return fetchGetOrderPad()
    }
  })

  return {
    updateOrderPadItemsResponse: data,
    updateOrderPadItemsAsync: mutateAsync,
    ...restOptions
  }
}
