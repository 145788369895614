import Link from 'next/link'
import React, { FC } from 'react'
import CN from 'classnames'

import { OctagonWrapper } from 'components/atoms'

export interface IMyAccountTitleCardProps {
  cardTitle: string
  className?: string
  description?: string
  disabled?: boolean
  href?: string
  icon: string
  onClick?: () => void
}

export const MyAccountTitleCard: FC<IMyAccountTitleCardProps> = ({
  cardTitle,
  className,
  description,
  disabled,
  href,
  icon,
  onClick,
  ...restProps
}: IMyAccountTitleCardProps) => {
  const MyAccountTitleCardClasses = CN(
    `my-account-title-card bg-white border-[1px] border-N-100 rounded px-[20px] pb-[24px] w-full cursor-pointer`,
    { 'hover:shadow-md': !disabled },
    { 'border-N-25 !cursor-not-allowed': disabled },
    className
  )

  if (disabled || onClick) {
    return (
      <div
        tabIndex={0}
        role='button'
        className={MyAccountTitleCardClasses}
        onClick={onClick}
        onKeyDown={e => {
          if (onClick && e.key === 'Enter') {
            onClick()
          }
        }}
        {...restProps}>
        <span>
          <OctagonWrapper
            componentId='my-account-title-card'
            icon={icon}
            className='absolute flex items-center justify-center mt-[-20px]'
            disabled={disabled}
          />
        </span>

        <div
          className={CN(
            `my-account-title-card-${cardTitle} text-h3 font-semibold text-N-800 mb-[16px] mt-[60px] capitalize`,
            {
              'opacity-50': disabled
            }
          )}>
          {cardTitle}
        </div>
        <div
          className={CN(
            `my-account-title-card-${cardTitle}-description text-base font-normal text-N-700`,
            {
              'opacity-50': disabled
            }
          )}>
          {description}
        </div>
      </div>
    )
  }

  if (href) {
    return (
      <Link
        href={href === '/request-item' ? '/coming-soon' : href}
        className={MyAccountTitleCardClasses}
        {...restProps}>
        <span>
          <OctagonWrapper
            componentId='my-account-title-card'
            icon={icon}
            className='absolute flex items-center justify-center mt-[-20px]'
            disabled={disabled}
          />
        </span>

        <div
          className={CN(
            `my-account-title-card-${cardTitle} text-h3 font-semibold text-N-800 mb-[16px] mt-[60px] capitalize`,
            {
              'opacity-50': disabled
            }
          )}>
          {cardTitle}
        </div>
        <div
          className={CN(
            `my-account-title-card-${cardTitle}-description text-base font-normal text-N-700`,
            {
              'opacity-50': disabled
            }
          )}>
          {description}
        </div>
      </Link>
    )
  }
}

export default MyAccountTitleCard
