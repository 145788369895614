import Link from 'next/link'
import React, { FC } from 'react'
import { Button } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import {
  IComponentMoleculesServicesBrandBlockCategories,
  IComponentMoleculesServicesThumbnailBlock
} from 'lib/actions'
import { checkURL } from 'lib/utilities'

import { NextImage } from 'components/atoms'

export interface SpecialistServicesProductCategoryBlockProps {
  categoryBlockData?: IComponentMoleculesServicesThumbnailBlock
  className?: string
  componentId?: string
}

export const SpecialistServicesProductCategoryBlock: FC<
  SpecialistServicesProductCategoryBlockProps
> = ({
  categoryBlockData,
  className,
  componentId,
  ...restProps
}: SpecialistServicesProductCategoryBlockProps) => {
  /** Checking For Content Header Action Button */
  const {
    originalUrl: actionOriginalUrl,
    restUrlWithQuery: actionQuery,
    isInsideUrl: actionUrlIsInternal
  } = checkURL(categoryBlockData?.Action?.URL ?? '')

  /** Checking For Product Category Item */
  const mappedItems = categoryBlockData?.Categories?.map(
    (item: IComponentMoleculesServicesBrandBlockCategories) => {
      const {
        originalUrl: categoryOriginalUrl,
        restUrlWithQuery: categoryQuery,
        isInsideUrl: categoryUrlIsInternal
      } = checkURL(item?.URL ?? '')
      return {
        ...item,
        url: categoryUrlIsInternal ? categoryQuery : categoryOriginalUrl,
        target: categoryUrlIsInternal ? '' : '_blank'
      }
    }
  )

  const SpecialistServicesProductCategoryBlockClasses = CN(className)

  return (
    <div
      data-component-id={`${componentId}-content`}
      className={SpecialistServicesProductCategoryBlockClasses}
      {...restProps}>
      <div className='flex flex-col sm:flex-row md:flex-row gap-y-3 sm:gap-y-0 md:gap-y-0 sm:justify-between md:justify-between'>
        {/** Specialist Services Product Category Block Header */}
        {categoryBlockData?.Header && (
          <span
            data-component-id={`${componentId}-content-header`}
            className='font-600 text-h3 text-N-800'>
            {categoryBlockData?.Header}
          </span>
        )}

        {/** Specialist Services Product Category Block Header Action Button */}
        {categoryBlockData?.Action?.DisplayText && (
          <Link
            href={actionUrlIsInternal ? actionQuery : actionOriginalUrl ?? {}}
            passHref
            target={actionUrlIsInternal ? '' : '_blank'}
            className={CN({
              'cursor-pointer': actionOriginalUrl,
              'cursor-auto pointer-events-none': !actionOriginalUrl
            })}>
            <Button
              className='w-fit'
              size='md'
              isBlock={false}
              isRingOffset={false}
              componentId={`${componentId}-header-action-button`}
              appearance='link'
              iconAfter='nzsbi-chevron-right'>
              {categoryBlockData?.Action?.DisplayText}
            </Button>
          </Link>
        )}
      </div>

      {/** Specialist Services Product Category Block Items */}
      {mappedItems && (
        <div className='grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-4 pt-8 gap-x-4 sm:gap-x-5 lg:gap-x-8 gap-y-6 sm:gap-y-6 lg:gap-y-8'>
          {mappedItems?.map((item, index: number) => {
            return (
              <Link
                key={item?.url}
                href={item?.url ?? {}}
                passHref
                target={item?.target}
                className={CN({
                  'cursor-pointer': item?.url,
                  'cursor-auto pointer-events-none': !item?.url
                })}>
                <div className='max-w-[190px] sm:max-w-[151px] md:max-w-[187px] h-full shadow-sm justify-center items-center p-4'>
                  <div className='flex justify-center items-start relative w-full '>
                    <NextImage
                      nextImgStyle={{
                        padding: '16px 16px 54px 16px',
                        objectFit: 'contain'
                      }}
                      data-component-id={`${componentId}-category-item-${index}`}
                      imgKey={item?.Thumbnail?.data?.id}
                      imageUrl={item?.Thumbnail?.data?.attributes?.provider_metadata?.public_id}
                      system='CMS'
                      useWithDimensions
                      width={150}
                      height={116}
                      alt={
                        item?.Thumbnail?.data?.attributes?.alternativeText
                          ? item?.Thumbnail?.data?.attributes?.alternativeText
                          : ''
                      }
                      {...restProps}
                    />
                  </div>

                  <div
                    data-component-id={`${componentId}-category-item-${index}-name`}
                    className='text-base font-500 text-N-700 text-center line-clamp-2 pt-4'>
                    {item?.DisplayText}
                  </div>
                </div>
              </Link>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default SpecialistServicesProductCategoryBlock
