import { httpGET } from 'lib/http'

export interface IBranchDetails {
  branchCode: string
  branchName: string
  city: string
  postCode: string
  streetAddress: string
  suburb: string
}

export interface IRegionDetails {
  regionName: string
  branches: Array<IBranchDetails>
}

export interface IGetPickupLocationsResponse {
  messageTitle: string
  messageDescription: string
  messageType: string
  messageCode?: string
  data?: {
    resultCount: number
    stockRoomsCount: number
    pickupLocations: Array<IRegionDetails>
  }
}

export const getPickupLocations = async (cartId: string, context?: any) => {
  const { data } = await httpGET({
    url: `/order/pickup_locations/${cartId}`,
    context
  })

  return data as IGetPickupLocationsResponse
}
