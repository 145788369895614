import React, { FC } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { useLogout } from 'lib/actions/auth/logout'
import { useAppContext } from 'lib/contexts/app-context'
import { useModalContext } from 'lib/contexts/modal-context'

export interface IBlockUiModalProps {
  className?: string
}

export const BlockUIModal: FC<IBlockUiModalProps> = ({
  className,
  ...restProps
}: IBlockUiModalProps) => {
  const { blockUI } = useModalContext()
  const { logout } = useLogout()
  const { session } = useAppContext()

  const LoginClasses = CN(`login w-full flex flex-col bg-white gap-[24px]`, className)

  return (
    <Modal
      componentId='invalid-session'
      isOpen={blockUI.isOpen}
      className='w-[600px] flex-shrink-0'
      {...restProps}>
      <ModalHeader componentId='invalid-session' heading={null} hasBorder={false} />
      <ModalBody componentId='invalid-session' className='!pt-[0] !pb-[20px] cursor-auto'>
        <div data-component-id='sign-in' className={LoginClasses} {...restProps}>
          <>
            {/* Heading */}
            <div className='login__heading flex flex-col'>
              <div className='flex fle-col items-start mb-[16px]'>
                <i className='flex items-center nzsbi-error-1 text-R-500 text-[48px]' />
                <h3
                  data-component-id='sign-in-header'
                  className='text-N-700 text-display-h2 font-display uppercase'>
                  {session?.isPunchOut && session?.isIframe
                    ? 'Invalid Session'
                    : 'PUNCHOUT SESSION HAS EXPIRED'}
                </h3>
              </div>

              {session?.isPunchOut && session?.isIframe ? (
                <span className='text-N-500 text-base pb-[24px]'>
                  Please note you are currently connected to your Punchout Catalogue in another tab
                  or window. <br /> <br />
                  Please close this tab or window and return to your Punchout session to continue
                  ordering.
                </span>
              ) : (
                <span className='text-N-500 text-base pb-[24px]'>
                  Access to your Punchout Catalogue has expired or is invalid.
                  <br /> <br /> Please return to your procurement application to start a new
                  connection to your Punchout Catalogue to continue ordering.
                </span>
              )}

              {session?.isPunchOut && session?.isIframe && (
                <Button
                  componentId='sign-in-create-account'
                  isRingOffset={false}
                  appearance='secondary-orange'
                  size='md'
                  onClick={() => {
                    logout()
                  }}>
                  Sign Out
                </Button>
              )}
            </div>
          </>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default BlockUIModal
