import { useMutation } from '@tanstack/react-query'
import { httpPUT } from 'lib/http'

export interface IAddToFavoriteResponse {
  messageTitle: string
  messageDescription: string
  messageType: string
  data: string
}

export const addToFavorite = async (webId: string, context?: any) => {
  const { data } = await httpPUT({
    url: `account/addfavourite/${webId}`,
    context
  })
  return data?.data as IAddToFavoriteResponse
}

export const useAddToFavoriteProduct = () => {
  return useMutation({
    mutationFn: addToFavorite
  })
}
