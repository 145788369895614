import { useMutation } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

export interface ISignUp {
  firstName: string
  lastName: string
  emailAddress: string
  mobileNumber: string
  companyName: string
  password: string
  isSubscribed: boolean
  captchaToken: string
}

/* API Request */
export const signUpRequest = async (req: ISignUp) => {
  try {
    const response = await httpPOST({
      url: 'auth/signup',
      endpoint: 'identity',
      body: req
    })

    // handleResponseToast(response?.data)
    return response?.data
  } catch (error) {
    return { error }
  }
}

/* Client side react-query custom hook */
export const useSignUp = () => {
  const { mutateAsync, ...restOptions } = useMutation({
    mutationFn: signUpRequest
  })

  // Passing mutateAsync as use signUp
  // and restOptions as rest of the options
  return { signUp: mutateAsync, ...restOptions }
}
