import React, { FC, useState } from 'react'
import { Accordion } from '@nzsb/shopnx-ui'
import CN from 'classnames'

export interface ITechnicalSpecificationsProps {
  className?: string
  componentId?: string
  specifications: Array<{
    id?: string
    specTerm: string
    specDescription: string
  }>
}

export const TechnicalSpecifications: FC<ITechnicalSpecificationsProps> = ({
  className,
  componentId,
  specifications,
  ...restProps
}: ITechnicalSpecificationsProps) => {
  const TechnicalSpecificationsClasses = CN(`technical-specifications bg-B-25 rounded`, className)

  const [expandedTechnicalSpecification, setExpandedTechnicalSpecification] = useState<
    false | number
  >(0)

  return (
    <div className={TechnicalSpecificationsClasses} {...restProps}>
      <Accordion
        className='w-full !border-t-0 !border-b-0'
        headerClasses='!normal-case !font-500 !text-base !text-B-500'
        isArrowIcons={false}
        setExpanded={setExpandedTechnicalSpecification}
        expanded={expandedTechnicalSpecification}
        item={{
          id: 0,
          content: (
            <div
              data-component-id={`${componentId}-content-body`}
              className='flex flex-col px-[4px] py-[8px] gap-[12px]'>
              <div className='grid grid-cols-[max-content_auto] gap-x-[32px] gap-y-[12px]'>
                <span className='min-w-[140px] w-fit font-serif font-bold text-xs text-N-800 uppercase'>
                  Term
                </span>
                <span className='font-serif font-bold text-xs text-B-800 uppercase'>
                  Description
                </span>

                {specifications.map(spec => (
                  <>
                    <span className='min-w-[140px] w-fit font-serif font-medium text-base text-N-700'>
                      {spec.specTerm}
                    </span>
                    <span className='font-serif font-normal text-base text-N-700'>
                      {spec.specDescription}
                    </span>
                  </>
                ))}
              </div>
            </div>
          ),
          title: 'TECHNICAL SPECIFICATIONS'
        }}
        variant='blue'
      />
    </div>
  )
}

export default TechnicalSpecifications
