import React from 'react'

import { ForgotPasswordModal } from 'components/modals/ForgotPasswordModal'
import { SignInModal } from 'components/modals/SignInModal'
import { VerifyAccountModal } from 'components/modals/VerifyAccountModal'

import { BlockUIModal } from './BlockUiModal'
import { BulkOrderModal } from './BulkOrderModal'
import { ChangePasswordModal } from './ChangePasswordModal'
import { ComingSoonModal } from './ComingSoonModal'
import { ExpectedDispatchModal } from './ExpectedDisptachModal'
import { LinkToCreditAccountModal } from './LinkToCreditAccountModal'
import { NoStockPickUpModal, PickupModal } from './PickupModal'
import { ResetPasswordModal } from './ResetPasswordModal'
import { SaveCartModal } from './SaveCartModal'
import { SearchAddressModal } from './SearchAddressModal'
import { SignUpModal } from './SignUpModal'

export const ModalsCollection = () => {
  return (
    <div className='body--modal-container'>
      <SignInModal />
      <ResetPasswordModal />
      <ForgotPasswordModal />
      <SignUpModal />
      <VerifyAccountModal />
      <LinkToCreditAccountModal />
      <BlockUIModal />
      <BulkOrderModal />
      <ExpectedDispatchModal />
      <PickupModal />
      <NoStockPickUpModal />
      <ComingSoonModal />
      <SaveCartModal />
      <ChangePasswordModal />
      <SearchAddressModal />
    </div>
  )
}

export default ModalsCollection
