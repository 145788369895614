import React, { FC } from 'react'
import CN from 'classnames'

export interface WorkSafeTrainingHowThisWorksSectionProps {
  className?: string
  componentId?: string
}

export const WorkSafeTrainingHowThisWorksSection: FC<WorkSafeTrainingHowThisWorksSectionProps> = ({
  className,
  componentId,
  ...restProps
}: WorkSafeTrainingHowThisWorksSectionProps) => {
  const WorkSafeTrainingHowThisWorksSectionClasses = CN(
    `w-full flex flex-col bg-white rounded shadow-sm h-fit static`,
    className
  )

  return (
    <div className={WorkSafeTrainingHowThisWorksSectionClasses} {...restProps}>
      <span
        data-component-id={`${componentId}-how-this-works-title`}
        className='font-700 text-base text-N-800'>
        How this Works
      </span>

      <span
        className='pt-2 font-400 text-sm text-N-700'
        data-component-id={`${componentId}-how-this-works-description`}>
        Please complete this form with all the required details and we’ll reply back to you to
        confirm your training. If you have any questions please contact us on;
      </span>

      <div className='flex flex-col gap-2 pt-2'>
        <div
          aria-hidden={true}
          onClick={() => {
            window.location.href = `tel:0800 967 572`
          }}
          data-component-id={`${componentId}-how-this-works-tel`}
          className='flex gap-x-2 cursor-pointer'>
          <i
            className='nzsbi-phone text-[20px] text-O-500'
            data-component-id={`${componentId}-how-this-works-tel-icon`}
          />
          <span
            className='font-400 text-sm text-O-500'
            data-component-id={`${componentId}-how-this-works-tel-no`}>
            0800 967 572
          </span>
        </div>
        <div
          aria-hidden={true}
          onClick={() => {
            window.location.href = `mailto:training@nzsafetyblackwoods.co.nz`
          }}
          data-component-id={`${componentId}-how-this-works-mail`}
          className='flex gap-x-2 cursor-pointer'>
          <i
            className='nzsbi-email text-[20px] text-O-500'
            data-component-id={`${componentId}-how-this-works-mail-icon`}
          />
          <span
            className='font-400 text-sm text-O-500 underline'
            data-component-id={`${componentId}-how-this-works-mail-address`}>
            training@nzsafetyblackwoods.co.nz
          </span>
        </div>
      </div>

      <span
        data-component-id={`${componentId}-invoice-and-payment-title`}
        className='font-700 text-base text-N-800 pt-5'>
        Invoicing & Payment
      </span>

      <span
        data-component-id={`${componentId}-invoice-and-payment-description`}
        className='pt-2 font-400 text-sm text-N-700'>
        Invoicing on your NZ Safety Blackwoods account for account holders, as per existing terms
        and conditions with NZ Safety Blackwoods. Non account holders can open an NZ Safety
        Blackwoods account from our website or can pay on invoice prior to the course delivery by
        credit card, our Administration team will be in touch to complete this process.
      </span>
    </div>
  )
}

export default WorkSafeTrainingHowThisWorksSection
