import { useMutation } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

export interface IDeleteOrderPadResponse {
  messageTitle: string
  messageDescription: string
  messageType: string
  data: string
}

export const deleteOrderPad = async (orderPadId: string, context?: any) => {
  const { data } = await httpPOST({
    url: `orderpad/delete`,
    context,
    body: {
      id: orderPadId
    }
  })
  return data?.data as IDeleteOrderPadResponse
}

export const useDeleteOrderPad = () => {
  return useMutation({
    mutationFn: deleteOrderPad
  })
}
