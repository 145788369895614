import React, { FC } from 'react'
import { ProdCardProps } from '@nzsb/shopnx-ui'
import CN from 'classnames'

import { ProductCard } from '../cards/ProductCard'

export interface ProductListProps {
  componentId?: string
  isPromotions: boolean
  productClassName?: string
  productListClassName?: string
  products?: ProdCardProps[]
  title?: string
}

export const CategoryProductList: FC<ProductListProps> = ({
  componentId,
  isPromotions,
  productClassName,
  productListClassName,
  products,
  title,
  ...restProps
}: ProductListProps) => {
  const ProductListClasses = CN(`${componentId}-product-list grid gap-6`, productListClassName)

  return (
    <div className={ProductListClasses} {...restProps}>
      <h2 className='text-h3-m font-700 font-sans text-N-700 2xl:!text-h3 2xl:!font-600 '>
        {title}
      </h2>
      <div className='flex overflow-x-auto no-scrollbar'>
        <div className='flex lg:!gap-8 md:gap-5 gap-4 items-start justify-start'>
          {products?.map((product: ProdCardProps) => (
            <ProductCard
              key={`${product.id}-product-card`}
              className='min-w-[296px]'
              product={product}
              appearance='vertical'
              isShowStock={!isPromotions}
              isShowStoreDetails={!isPromotions}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default CategoryProductList
