import { useQuery } from '@tanstack/react-query'
import { httpGET, httpPOST } from 'lib/http'

export interface IRegionBranches {
  region: string
  island: string
  branches: IBranchInfo[]
}

export interface IBranchInfo {
  stock: number
  isStockAvailable: boolean
  name: string
  code: string
  street: string
  suburb: string
  city: string
  postCode: string
  description: string
  phoneNumber: string
  latitude: string
  longitude: string
  isActive: boolean
  canPickUp: boolean
  openTradeHours: Array<{
    day: string
    openingTime: string
    closingTime: string
    isOpened: boolean
  }>
}

/* API Request */
export const getProductPickupDetails = async (id?: number | string) => {
  if (id) {
    const startTime = performance.now()
    const { data: response } = await httpGET({
      url: `products/getstock/${id}`
    })

    const branches = (response?.data || []) as IRegionBranches[]
    const groupedByIsland = branches?.reduce<{ [key: string]: any }>((acc, obj) => {
      const island = obj.island
      acc[island] = acc[island] || [] // Initialize island group if it doesn't exist
      acc[island].push(obj)
      return acc
    }, {})

    const groupedSelectOptions = Object.keys(groupedByIsland).map((island: string) => {
      return {
        label: `${island} island`,
        options: groupedByIsland[island].map((region: any) => ({
          label: region.region,
          value: region.region
        }))
      }
    })

    const endTime = performance.now()
    const timeTaken = endTime - startTime

    await httpPOST({
      url: 'logging/logSender',
      body: {
        level: 'info',
        message: `Time taken for get product pickup information: ${timeTaken}ms`
      }
    })
    return { branches, groupedSelectOptions }
  }
  return { branches: [], groupedSelectOptions: [] }
}

/* Client side react-query custom hook */
export const useGetProductPickupDetails = (id?: number | string) => {
  const { refetch: fetchPickupDetails, ...restOptions } = useQuery({
    queryKey: ['pickup-details', id],
    queryFn: () => getProductPickupDetails(id)
  })

  return { fetchPickupDetails, ...restOptions }
}
