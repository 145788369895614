import { useMutation } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

import { useGetCart } from './useGetCart'

export interface IUpdateCartItemsResponse {
  messageTitle: string | null
  messageDescription: string | null
  messageType: string
  data: any
}

export interface IUpdateCartItemsReq {
  cartItems: Array<{
    productId: number
    variantId?: number
    quantity: number
  }>
  cartId?: string
}

export const updateCartItems = async (req: IUpdateCartItemsReq) => {
  const data = await httpPOST({
    url: `cart/updateitems`,
    body: req
  })

  return data?.data as IUpdateCartItemsResponse
}

export const useUpdateCartItems = () => {
  const { refetch: fetchGetCart } = useGetCart()

  const { data, mutateAsync, ...restOptions } = useMutation({
    mutationFn: async (req: IUpdateCartItemsReq) => {
      const res = await updateCartItems(req)
      fetchGetCart()
      return res
    }
  })

  return { updateCartItemsResponse: data, updateCartItemsAsync: mutateAsync, ...restOptions }
}
