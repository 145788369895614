import React, { FC, useEffect, useState } from 'react'
import {
  AlertProps,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Toast
} from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { useAddItemsToCart, useGetSizeGuideByProductId } from 'lib/actions'
import { useAppContext } from 'lib/contexts/app-context'
import { useModalContext } from 'lib/contexts/modal-context'
import useWindowDimensions from 'lib/hooks/useWindowDimensions'

import { ProductVariants } from 'components/organisms/ProductVariants'

export interface IBulkOrderModalProps {
  className?: string
}

export const BulkOrderModal: FC<IBulkOrderModalProps> = ({
  className,
  ...restProps
}: IBulkOrderModalProps) => {
  const BulkOrderModalClasses = CN(`Bulk-order-modal w-[444px] flex-shrink-0`, className)
  const [selectedProductTotalCount, setSelectedProductTotalCount] = React.useState(0)
  const { width } = useWindowDimensions()

  const { bulkOrderModal } = useModalContext()
  const { productVariants } = useAppContext()

  const { addItemsToCartAsync, isPending: isLoadingCartAdd } = useAddItemsToCart()

  // const [showTick, setShowTick] = useState<boolean>(false)
  const [isOpenDiscardSavings, setIsOpenDiscardSavings] = useState(false)
  // const previousLoading = usePrevious(isLoadingCartAdd)

  const notify = (props: AlertProps) => Toast(props)

  // useEffect(() => {
  //   if (previousLoading && !isLoadingCartAdd) {
  //     setShowTick(true)
  //   }
  // }, [isLoadingCartAdd, previousLoading])

  // useEffect(() => {
  //   if (isLoadingCartAdd) {
  //     setShowTick(true)
  //   }
  // }, [isLoadingCartAdd])

  useEffect(() => {
    const total = productVariants.selectedProducts.reduce(
      (acc: any, curr: any) => acc + curr.cartQty,
      0
    )
    setSelectedProductTotalCount(total)
  }, [productVariants.selectedProducts])

  const bulkOrderAction = () => {
    const cartItems = productVariants.selectedProducts.map((item: any) => ({
      variantId: item?.id,
      quantity: item?.cartQty,
      productId: productVariants.product?.id
    }))
    addItemsToCartAsync({ cartItems: cartItems }).then(res => {
      if (res?.data?.validationMessages) {
        res?.data?.validationMessages?.map((error: any) => {
          notify({
            status: error?.messageType,
            alertHeader: error?.title,
            alertBody: error?.message
          })
        })
      }
      setIsOpenDiscardSavings(false)
      bulkOrderModal.close()
    })
  }

  const handleClearAll = () => {
    const updatedData: any = productVariants.tableData.map((row: any) => {
      return {
        ...row,
        // [columnId]: value,
        // Modify other cell based on the edited cell
        cartQty: 0, // Example: Increment the value of another cell
        stock: row.stock,
        isClearBtnVisible: false
      }
    })
    productVariants.setTableData(updatedData)
  }

  // Opening discard savings modal
  const openDiscardSavings = () => {
    if (productVariants?.selectedProducts?.length === 0) {
      bulkOrderModal.close()
    } else {
      setIsOpenDiscardSavings(true)
    }
  }

  // Closing discard savings modal
  const closeDiscardSavings = () => {
    setIsOpenDiscardSavings(false)
  }

  // Complete closing of quick cart sidebar
  const discardChanges = () => {
    closeDiscardSavings()
    bulkOrderModal.close()
  }

  const { data: szeGuideData } = useGetSizeGuideByProductId(productVariants?.product?.id)

  // get selected product details for find size chart link
  const getProdDetails = async () => {
    const SizeGuideLink = szeGuideData?.data?.link ?? ''

    if (SizeGuideLink) {
      window.open(SizeGuideLink, '_blank')
    }
  }

  return (
    <>
      <Modal
        componentId='product-variants'
        isOpen={bulkOrderModal.isOpen}
        onClickOverlay={openDiscardSavings}
        onClose={bulkOrderModal.close}
        className={BulkOrderModalClasses}
        size='extraLarge'
        {...restProps}>
        <ModalHeader
          componentId='bulk-order'
          onClose={() => openDiscardSavings()}
          heading={''}
          hasBorder={false}
        />
        <ModalBody
          componentId='bulk-order'
          className='!pt-[0] !pb-[20px] cursor-auto flex gap-[20px] flex-col relative min-h-[770px]'>
          <ProductVariants />
        </ModalBody>
        <ModalFooter
          className={CN('flex justify-between', { 'flex-col-reverse gap-[16px]': width < 744 })}>
          <Button
            componentId='bulk-order-see-size-chart'
            appearance='link-gray'
            disabled={!szeGuideData?.data?.link}
            onClick={() => {
              getProdDetails()
            }}>
            See Size Chart
          </Button>
          <div className={CN('flex', { 'flex-col-reverse gap-[8px]': width < 744 })}>
            <Button
              componentId='bulk-order-clear-all'
              appearance='secondary-gray'
              className='mr-2'
              disabled={selectedProductTotalCount === 0 || isLoadingCartAdd}
              onClick={() => {
                handleClearAll()
              }}>
              Clear all
            </Button>
            <Button
              componentId='bulk-order-add-to-cart'
              appearance='primary-orange'
              onClick={bulkOrderAction}
              isLoading={isLoadingCartAdd}
              //isShowOptionalAnimation={showTick}
              disabled={selectedProductTotalCount === 0}
              // optionalAnimation={
              //   <Tick
              //     loop={false}
              //     className='!w-[16px] !h-[16px]'
              //     onComplete={() => {
              //       setShowTick(false)
              //     }}
              //   />
              // }>
              className={CN({
                '!cursor-wait': isLoadingCartAdd
              })}>
              {/* Add {selectedProductTotalCount} item{selectedProductTotalCount !== 1 && 's'} to the cart */}
              Add to cart
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal
        componentId='bulk-order-unsaved-warning'
        isOpen={isOpenDiscardSavings}
        className='z-20 min-w-[380px]'>
        <ModalHeader
          componentId='bulk-order-unsaved-warning'
          hasBorder={false}
          heading={null}
          onClose={closeDiscardSavings}
        />
        <ModalBody componentId='bulk-order-unsaved-warning' className='!p-[20px] !pt-0'>
          <div className='flex flex-col items-center justify-center text-center quick-cart-modal-body-warning'>
            <i className='quick-cart-modal-body-warning-icon ri-error-warning-line text-[48px] text-O-500 mb-[16px]' />
            <span>You have made a selection but have not updated your cart.</span>
          </div>
        </ModalBody>
        <ModalFooter componentId='bulk-order-unsaved-warning'>
          <div className='flex gap-[16px]'>
            <Button
              componentId='bulk-order-unsaved-warning-discard-btn'
              appearance='secondary-gray'
              disabled={isLoadingCartAdd}
              onClick={discardChanges}>
              Clear
            </Button>
            <Button
              componentId='bulk-order-unsaved-warning-update-btn'
              appearance='primary-orange'
              className='mr-2'
              isLoading={isLoadingCartAdd}
              onClick={bulkOrderAction}>
              Update Cart
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default BulkOrderModal
