import Link from 'next/link'
import React, { FC } from 'react'
import { Button } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { checkURL } from 'lib/utilities'

import { NextImage } from 'components/atoms'

export interface CompanyProfileExtensiveRangeProps {
  className?: string
  componentId?: string
  sectionDetails?: {
    Action?: {
      ButtonText?: string
      Url?: string
      IconBefore: string
      IconAfter: string
    }
    Header?: string
    BrandSection?: Array<{
      id?: string
      SubHeader?: string
      Description?: string
      Brands?: {
        data?: Array<{
          id?: string
          attributes?: {
            BrandID?: string
            BrandName?: string
            Brand?: any
            LinkToSearchCuration?: boolean
            SearchCurationURL?: string
            BrandLogo?: {
              data?: {
                id?: string
                attributes?: {
                  alternativeText?: string
                  url?: string
                  provider?: string
                  provider_metadata?: {
                    public_id?: string
                    resource_type?: string
                  }
                }
              }
            }
          }
        }>
      }
    }>
  }
}

export const CompanyProfileExtensiveRange: FC<CompanyProfileExtensiveRangeProps> = ({
  className,
  componentId,
  sectionDetails,
  ...restProps
}: CompanyProfileExtensiveRangeProps) => {
  const {
    originalUrl: buttonOriginalUrl,
    restUrlWithQuery: buttonQuery,
    isInsideUrl: buttonUrlIsInternal
  } = checkURL(sectionDetails?.Action?.Url ?? '')

  const CompanyProfileExtensiveRangeSectionClasses = CN(
    `w-full border-t-[2px] border-B-500`,
    className
  )

  return (
    <div className={CompanyProfileExtensiveRangeSectionClasses} {...restProps}>
      <div className='pt-7 lg:pt-4'>
        {/** About Us Company Profile Extensive Range Section Header */}
        {sectionDetails?.Header && (
          <p
            data-component-id={`${componentId}-title`}
            className='font-700 text-h2-m md:!text-h2 lg:text-h2 text-B-500'>
            {sectionDetails?.Header}
          </p>
        )}

        <div className='flex flex-col gap-y-4 pt-1'>
          {sectionDetails?.BrandSection &&
            sectionDetails?.BrandSection?.map(item => (
              <div key={item?.id} className='flex flex-col pb-8'>
                {/** About Us Company Profile Extensive Range Section Sub Header */}
                {item?.SubHeader && (
                  <p
                    data-component-id={`${componentId}-content-${item?.id}-sub-title`}
                    className='font-700 md:!font-600 text-h3-m md:text-h3 text-N-800'>
                    {item?.SubHeader}
                  </p>
                )}

                {/** About Us Company Profile Extensive Range Section Description */}
                {item?.Description && (
                  <div className='font-400 text-base text-black pt-8 break-words max-w-full'>
                    <div
                      data-component-id='content-html-seo-description'
                      dangerouslySetInnerHTML={{
                        __html: item?.Description
                      }}
                      className='prose prose-blue-bold mark-down max-w-none'
                    />
                  </div>
                )}

                {/** About Us Company Profile Extensive Range Section Brand Logo List */}
                {item?.Brands?.data && (
                  <div
                    data-component-id={`${componentId}-content-${item?.id}-brand-list`}
                    className='grid grid-cols-2 md:grid-cols-4 gap-8 pt-8'>
                    {item?.Brands?.data?.map((brandImage, brandImgIndex) => {
                      let url: any
                      let isInside: any
                      const linkToCuration = brandImage?.attributes?.LinkToSearchCuration

                      if (brandImage?.attributes?.LinkToSearchCuration) {
                        const urlProps = checkURL(brandImage?.attributes?.SearchCurationURL)
                        isInside = urlProps?.isInsideUrl
                        url = urlProps?.originalUrl
                      } else {
                        url = brandImage?.attributes?.SearchCurationURL
                        isInside = true
                      }
                      return (
                        <Link
                          aria-hidden='false'
                          aria-label={`brand-image-${brandImage?.attributes?.Brand}`}
                          passHref
                          target={isInside ? '' : '_blank'}
                          href={
                            linkToCuration
                              ? url
                              : `/brands/shop-by-brands/${JSON.parse(brandImage?.attributes?.Brand)?.value}`
                          }
                          key={brandImgIndex}
                          className={CN({
                            'pointer-events-none':
                              !brandImage?.attributes?.BrandLogo?.data?.attributes?.url
                          })}>
                          <div className='border border-N-100 rounded-s flex px-6 py-2 max-w-[190px] md:max-w-[208px]  md:h-[96px] h-[96px] relative'>
                            <NextImage
                              nextImgStyle={{ padding: '8px 24px', objectFit: 'contain' }}
                              data-component-id={`${componentId}-content-${item?.id}-brand-list-brandImg-${brandImgIndex}`}
                              imgKey={brandImage?.id ?? ''}
                              imageUrl={
                                brandImage?.attributes?.BrandLogo?.data?.attributes
                                  ?.provider_metadata?.public_id ?? ''
                              }
                              system='CMS'
                              useWithDimensions={false}
                              alt={
                                brandImage?.attributes?.BrandLogo?.data?.attributes
                                  ?.alternativeText ?? ''
                              }
                              {...restProps}
                            />
                          </div>
                        </Link>
                      )
                    })}
                  </div>
                )}
              </div>
            ))}

          {/** About Us Company Profile Extensive Range Section Action Button */}
          {sectionDetails?.Action?.ButtonText && (
            <Link
              href={buttonUrlIsInternal ? buttonQuery : buttonOriginalUrl ?? {}}
              passHref
              target={buttonUrlIsInternal ? '' : '_blank'}
              className={CN({
                'cursor-pointer': buttonOriginalUrl,
                'cursor-auto pointer-events-none': !buttonOriginalUrl
              })}>
              <div
                data-component-id={`${componentId}-action-btn`}
                className='flex justify-center pt-4'>
                <Button
                  isRingOffset={false}
                  appearance='primary-orange'
                  className='w-fit'
                  size='lg'
                  iconBefore={sectionDetails?.Action?.IconBefore}
                  iconAfter={sectionDetails?.Action?.IconAfter}>
                  {sectionDetails?.Action?.ButtonText}
                </Button>
              </div>
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

export default CompanyProfileExtensiveRange
