import { useQuery } from '@tanstack/react-query'
import { useAppContext } from 'lib/contexts/app-context'
import { httpGET } from 'lib/http'

import { AddressProps } from 'components/molecules'

export interface ICustomerAddressDetailsProps {
  messageTitle: string | null
  messageDescription: string | null
  messageType: string | null
  messageCode: string | null
  data: {
    fullName: string
    emailAddress: string
    accountCode: string
    mobileNumber: string
    customerAddresses: AddressProps[]
  }
}

/* API Request */
export const getCustomerAddressDetails = async (context?: any) => {
  try {
    const { data: response } = await httpGET({
      url: '/customer/getaddresses',
      context
    })

    return response
  } catch (error) {
    return { error }
  }
}

export const useGetCustomerAddresses = (initData?: any) => {
  const { user } = useAppContext()
  const { refetch: getCustomerAddresses, ...restOptions } = useQuery({
    queryKey: ['customer_addresses'],
    queryFn: getCustomerAddressDetails,
    initialData: initData,
    enabled: user?.data?.roles !== undefined
  })

  return { getCustomerAddresses, ...restOptions }
}
