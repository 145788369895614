import { useMutation } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

export interface ISignIn {
  username: string
  password: string
  rememberMe?: boolean
  isCaptchaVerified: boolean
  captchaToken: string
}

/* API Request */
export const signInRequest = async (req: ISignIn) => {
  try {
    const response = await httpPOST({
      type: 'internal',
      url: '/auth/login',
      body: req
    })

    return response
  } catch (error) {
    return { error }
  }
}

/* Client side react-query custom hook */
export const useSignIn = () => {
  const { mutateAsync, ...restOptions } = useMutation({
    mutationFn: signInRequest
  })

  // Passing mutateAsync as signIn
  // and restOptions as rest of the options
  return { signIn: mutateAsync, ...restOptions }
}
