import { AlertProps, Toast } from '@nzsb/shopnx-ui'

export interface IHandleResponseToastProps {
  messageDescription: string
  messageTitle: string
  messageType: any
}

const notify = (props: AlertProps) => Toast(props)

export const handleResponseToast = (response: IHandleResponseToastProps) => {
  /**
   * @param response - [object] response object from API
   * @returns - [void] void
   * @eg: handleResponseToast({ messageDescription: 'Success', messageTitle: 'Success', messageType: 'success' })
   */
  notify({
    alertHeader: response.messageTitle,
    alertBody: response.messageDescription,
    status: response.messageType.charAt(0).toUpperCase() + response.messageType.slice(1)
  })
}

export default handleResponseToast
