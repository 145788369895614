import { useMutation } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

export interface IResetPasswordInfo {
  id?: string | string[]
  password: string
  token?: string | string[]
}

export interface IResetPasswordResponse {
  data: any
  messageDescription: null | string
  messageTitle: string
  messageType: string
}

const resetPassword = async ({ id, password, token }: IResetPasswordInfo) => {
  try {
    const response = await httpPOST({
      endpoint: 'identity',
      url: 'auth/resetPassword',
      body: {
        id,
        password,
        token
      }
    })
    /** When Needed to handle the Notification with default behavior ['Info' | 'Success' | 'Warning' | 'Error'] use handleResponseToast(response) */
    // handleResponseToast(response?.data)
    return response?.data
  } catch (error) {
    return { error }
  }
}

/* Client side react-query custom hook */
export const useResetPassword = () => {
  const { mutateAsync, ...restOptions } = useMutation({
    mutationFn: resetPassword
  })

  // Passing mutateAsync as forget password
  // and restOptions as rest of the options
  return { resetPasswordAsync: mutateAsync, ...restOptions }
}
