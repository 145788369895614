import { httpPOST } from 'lib/http'

export interface ILog {
  level: string
  message: string
}

export const loggingRequest = async (req: ILog) => {
  try {
    const response = await httpPOST({
      type: 'internal',
      url: '/logging/logSender',
      body: req
    })

    return response
  } catch (error) {
    return { error }
  }
}
