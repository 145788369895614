export interface ISizeForBreakPointProps {
  width: number
  xs: number
  sm: number
  md: number
  lg: number
  xl: number
}

/**
 * @param {number} width - The width of the screen
 * @param {number} xs - The size for extra small screens
 * @param {number} sm - The size for small screens
 * @param {number} md - The size for medium screens
 * @param {number} lg - The size for large screens
 * @param {number} xl - The size for extra large screens
 * @returns {number} - The size for the current screen
 * @description - This function returns the size for the current screen
 * @example
 * getSizeForBreakPoint({ width: 1280, xs: 1, sm: 2, md: 3, lg: 4, xl: 5 })
 * // returns 3
 */

export const getSizeForBreakPoint = ({ width, xs, sm, md, lg, xl }: ISizeForBreakPointProps) => {
  if (width < 428) {
    return xs
  } else if (width < 640) {
    return xs
  } else if (width < 744) {
    return sm
  } else if (width < 1280) {
    return md
  } else if (width < 1366) {
    return lg
  } else if (width < 1440) {
    return xl
  } else {
    return xl
  }
}

export default getSizeForBreakPoint
