import { useMutation } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

export interface ISearchProductsReq {
  searchText: string
  pageIndex?: 1
  pageSize?: 10
}

export interface ISearchBarProduct {
  id: string
  sku: string
  title: string
  price: number
  priceWithGst: number
  urlSlug: string
  isDeal: boolean
  isClearance: boolean
  isCatalogue: boolean
  isRestricted: boolean
  isTemporaryUnavailable: boolean
  isNewlyAdded: boolean
  isEnrolled?: boolean
  isDesktop?: boolean
  hasVariant?: boolean
  productFlag: number
  stock: number
  image: {
    id: string
    url: string
    alt: string
    title: string
  }
}

export interface ISearchProductsRes {
  data: {
    products: Array<ISearchBarProduct>
    categories: Array<{
      id: 0
      name: string
      urlSlug: 'string'
    }>
    keywords: Array<{
      id: 'string'
      name: 'string'
    }>
  }
  messageDescription: null | string
  messageTitle: string
  messageType: string
}

/**
 * Fetch Product Search Results
 * @param req
 * @returns
 */
export const fetchProductSearchResults = async (req: ISearchProductsReq) => {
  const data = await httpPOST({
    url: 'search',
    body: req
  })

  return data?.data as ISearchProductsRes
}

export const useProductSearch = () => {
  /* Use Query */
  const { data, mutateAsync, ...restOptions } = useMutation({
    mutationFn: fetchProductSearchResults
  })

  return { searchResults: data, searchProductsAsync: mutateAsync, ...restOptions }
}

export default useProductSearch
