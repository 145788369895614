import Router, { useRouter } from 'next/router'
import React, { FC, useEffect } from 'react'
import { Modal, ModalBody, ModalHeader } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { useModalContext } from 'lib/contexts/modal-context'

import { ResetPasswordForm } from 'components/forms/resetPassword'

export interface IResetPasswordModalProps {
  className?: string
}

export const ResetPasswordModal: FC<IResetPasswordModalProps> = ({
  className
}: IResetPasswordModalProps) => {
  const router = useRouter()
  const { id, token, resetpassword } = router.query

  const { resetPasswordModal } = useModalContext()

  useEffect(() => {
    if (resetpassword === 'open') {
      resetPasswordModal?.open()
    }
  }, [resetpassword])

  return (
    <Modal
      componentId='reset-password-modal'
      isOpen={resetPasswordModal?.isOpen}
      className={CN('w-[444px]', className)}>
      <ModalHeader
        componentId='reset-password-modal'
        heading={null}
        onClose={() => {
          router.replace(router.pathname, router.pathname, { shallow: true })
          Router.push('/')
          resetPasswordModal?.close()
        }}
        className='flex-shrink-0'
        hasBorder={false}
      />
      <ModalBody componentId='reset-password-modal' className='p-0 mx-5 mb-5'>
        <ResetPasswordForm id={id} token={token} />
      </ModalBody>
    </Modal>
  )
}

export default ResetPasswordModal
