import { useMutation } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

export interface IRenameOrderPad {
  messageTitle: string
  messageDescription: string
  messageType: string
  data: string
}

export const renameOrderPad = async (req: any, context?: any) => {
  const { data } = await httpPOST({
    url: `orderpad/rename`,
    context,
    body: {
      id: req.id,
      name: req.name
    }
  })
  return data as IRenameOrderPad
}

export const useRenameOrderPad = () => {
  return useMutation({
    mutationFn: renameOrderPad
  })
}
