import Router, { useRouter } from 'next/router'
import React, { FC, useEffect } from 'react'
import { Alert, Button, Spinner } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { Form, Formik } from 'formik'
import { useResetPassword } from 'lib/actions/auth/resetPassword'
import { useResetPasswordTokenValidation } from 'lib/actions/auth/resetPasswordTokenValidate'
import { useModalContext } from 'lib/contexts/modal-context'
import { forgetPasswordSchema } from 'lib/schemas/resetPassword'

import { PasswordInput } from 'components/atoms'
import { OnScreenMessage } from 'components/molecules'

export interface IResetPasswordProps {
  className?: string
  id?: string | string[]
  token?: string | string[]
  hideHeader?: boolean
}

export const ResetPasswordForm: FC<IResetPasswordProps> = ({
  className,
  id,
  token,
  hideHeader,
  ...restProps
}: IResetPasswordProps) => {
  const ResetPasswordClasses = CN(`reset-password flex flex-col`, className)
  const router = useRouter()

  const { loginModal, forgotPasswordModal, resetPasswordModal } = useModalContext()

  const {
    resetPasswordAsync,
    isPending: isLoadingForgetPasswordAction,
    data: resetPasswordResponse
  } = useResetPassword()
  const {
    resetPasswordTokenValidateAsync,
    isPending: isLoadingTokenValidation,
    data: resetPasswordTokenValidateData
  } = useResetPasswordTokenValidation()

  useEffect(() => {
    if (token && id) {
      resetPasswordTokenValidateAsync({ id, token })
    }
  }, [id, token])

  const forgetPasswordClick = () => {
    router.replace(router.pathname, router.pathname, { shallow: true })
    Router.push('/')
    resetPasswordModal?.close()
    forgotPasswordModal?.open()
  }

  const onSignInClick = () => {
    router.replace(router.pathname, router.pathname, { shallow: true })
    Router.push('/')
    resetPasswordModal?.close()
    loginModal?.open()
  }

  return (
    <div className={ResetPasswordClasses} {...restProps}>
      {isLoadingTokenValidation ? (
        <div className='flex items-center justify-center'>
          <Spinner loop={true} />
        </div>
      ) : (
        <>
          {resetPasswordResponse && (
            <OnScreenMessage
              header={resetPasswordResponse?.messageType === 'success' ? 'Success' : 'Attention!'}
              type={resetPasswordResponse?.messageType}
              description={resetPasswordResponse?.messageDescription}
              actionButtonProps={{
                children:
                  resetPasswordResponse?.messageType === 'success' ? 'SIGN IN' : 'FORGET PASSWORD',
                appearance: 'secondary-orange',
                onClick:
                  resetPasswordResponse?.messageType === 'success'
                    ? onSignInClick
                    : forgetPasswordClick,
                size: 'md',
                componentId:
                  resetPasswordResponse?.messageType === 'success'
                    ? 'resetPasswordSignInBtn'
                    : 'resetPasswordForgetPasswordBtn'
              }}
            />
          )}
          {resetPasswordTokenValidateData && !resetPasswordTokenValidateData?.data?.isValid && (
            <OnScreenMessage
              header='Attention!'
              type={resetPasswordTokenValidateData?.messageType}
              subHeader={resetPasswordTokenValidateData?.messageTitle}
              description={resetPasswordTokenValidateData?.messageDescription}
              actionButtonProps={{
                children: 'Forget Password',
                onClick: forgetPasswordClick,
                appearance: 'secondary-orange',
                className: 'mt-[20px]',
                componentId: 'ResetPasswordForgetPassword'
              }}
            />
          )}
          {resetPasswordTokenValidateData?.data?.isValid && !resetPasswordResponse && (
            <>
              {!hideHeader && (
                <span className='text-N-700 text-display-h2-m md:text-display-h2 font-display uppercase mb-[24px]'>
                  Reset password
                </span>
              )}

              <div className='flex flex-col'>
                <div className='text-sm font-bold text-N-700'>For a secure password</div>
                <div className='text-sm font-normal text-N-700 mb-[24px] mt-[4px]'>
                  Please ensure your password contains a minimum of 8 characters consisting of at
                  least one alpha character, uppercase character, numeric value and special
                  character
                </div>

                <Formik
                  initialValues={{
                    password: '',
                    confirmPassword: ''
                  }}
                  validateOnChange={true}
                  isInitialValid={false}
                  validationSchema={forgetPasswordSchema}
                  onSubmit={async values => {
                    await resetPasswordAsync({
                      id,
                      token,
                      password: values.password
                    })
                  }}>
                  {({
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    values,
                    isValid,
                    isSubmitting
                  }) => (
                    <Form>
                      <div className='flex flex-col gap-[20px]'>
                        <PasswordInput
                          componentId='password'
                          id='password'
                          name='password'
                          wrapperClassName='w-full'
                          hint={errors.password && touched.password ? errors.password : ''}
                          hasError={
                            (errors.password && touched.password) ||
                            (values.confirmPassword &&
                              values.password &&
                              touched.password &&
                              touched.confirmPassword &&
                              values.confirmPassword !== values.password)
                          }
                          inputSize='sm'
                          label='New Password'
                          placeholder=''
                          isStrengthBarEnable
                          required
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur('password')}
                          // onFocus={() => {
                          //   setFieldTouched('password', true)
                          // }}
                        />
                        <PasswordInput
                          componentId='confirmPassword'
                          id='confirmPassword'
                          name='confirmPassword'
                          wrapperClassName='w-full'
                          hint={
                            errors.confirmPassword && touched.confirmPassword
                              ? errors.confirmPassword
                              : ''
                          }
                          hasError={
                            (errors.confirmPassword && touched.confirmPassword) ||
                            (values.confirmPassword &&
                              values.password &&
                              touched.password &&
                              touched.confirmPassword &&
                              values.confirmPassword !== values.password)
                          }
                          inputSize='sm'
                          label='Confirm New Password'
                          placeholder=''
                          required
                          value={values.confirmPassword}
                          onChange={handleChange}
                          onBlur={handleBlur('confirmPassword')}
                        />
                        {values.confirmPassword &&
                          values.password &&
                          touched.password &&
                          touched.confirmPassword &&
                          values.confirmPassword !== values.password && (
                            <Alert
                              variant='inline'
                              status='Error'
                              isCloseIcon={false}
                              alertHeader={
                                'The password and confirmation password must match to reset your password.'
                              }
                            />
                          )}
                        <Button
                          componentId='resetPasswordButton'
                          type='submit'
                          isRingOffset={false}
                          appearance='primary-orange'
                          isLoading={isSubmitting}
                          disabled={
                            !isValid ||
                            isSubmitting ||
                            isLoadingForgetPasswordAction ||
                            values.password !== values.confirmPassword
                          }>
                          RESET PASSWORD
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}
export default ResetPasswordForm
