import { useMutation } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

export interface ISaveAsNewOrderPad {
  messageTitle: string
  messageDescription: string
  messageType: string
  data: string
}

export interface ISaveAsNewOrderPadItemsReq {
  items: Array<{
    productId: number
    variantId?: number
    quantity: number
  }>
  name?: string
}

export const saveAsNewOrderPad = async (req: ISaveAsNewOrderPadItemsReq, context?: any) => {
  const { data } = await httpPOST({
    url: `orderpad/createasneworder`,
    context,
    body: req
  })
  return data as ISaveAsNewOrderPad
}

export const useSaveAsNewOrderPad = () => {
  return useMutation({
    mutationFn: saveAsNewOrderPad
  })
}
