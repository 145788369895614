import React, { FC } from 'react'
import CN from 'classnames'

import { ModalsCollection } from 'components/modals'
import { MinimalFooter } from 'components/organisms'
import { MinimalHeader } from 'components/organisms/Header'

export interface IMinimalLayoutProps {
  [x: string]: any
  className?: string
  pageTitle: string
  children?: React.ReactNode
}

export const MinimalLayout: FC<IMinimalLayoutProps> = ({
  className,
  pageTitle,
  children,
  ...restProps
}: IMinimalLayoutProps) => {
  const MinimalLayoutClasses = CN(
    `global-layout flex flex-col min-h-[100vh] justify-between`,
    className
  )

  return (
    <div className={MinimalLayoutClasses} {...restProps}>
      <div>
        <MinimalHeader pageTitle={pageTitle} className='app__header' />
        <main className='app__body'>{children}</main>
        <ModalsCollection />
      </div>
      <MinimalFooter className='app__footer' />
    </div>
  )
}

export default MinimalLayout
