import { useRouter } from 'next/router'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { debounce, TextField } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { useModalContext } from 'lib/contexts/modal-context'

export interface ProductSearchBarProps {
  className?: string
}

export const ProductSearchBar: FC<ProductSearchBarProps> = ({
  className,
  ...restProps
}: ProductSearchBarProps) => {
  const DropDownModalClasses = CN(`drop-down-modal relative w-full`, className)

  const { searchResultDropdown, expandSearch } = useModalContext()

  const router = useRouter()

  const [searchValue, setSearchValue] = useState('')

  const keyButtonReference = useRef('')
  const inputReference = useRef<HTMLInputElement>(null)

  const saveInput = (searchVal: string) => {
    if (keyButtonReference.current !== 'Enter') {
      searchResultDropdown.open()
      searchResultDropdown.search(searchVal)
    }
  }

  const debouncedSearchAction = useCallback(debounce(saveInput, 600), [])

  const onChangeInputField = (e: any) => {
    debouncedSearchAction(e.target.value, keyButtonReference.current)
    setSearchValue(e.target.value)
  }

  const onClearButtonClick = () => {
    searchResultDropdown.search('')
    searchResultDropdown.close()
    setSearchValue('')
    inputReference.current?.focus()
  }

  useEffect(() => {
    if (router?.query?.q && router?.pathname === '/search') {
      setSearchValue(router?.query?.q as string)
      expandSearch.open()
    }

    if (router?.pathname !== '/search') {
      searchResultDropdown.close()
      setSearchValue('')
      searchResultDropdown.search('')
    }
  }, [router?.query?.q, router?.pathname])

  return (
    <div
      data-component-id='dropdown'
      className={DropDownModalClasses}
      aria-hidden='false'
      {...restProps}>
      <TextField
        key={router?.pathname}
        componentId='header-search-bar'
        ref={inputReference}
        wrapperClassName='w-full'
        className='w-full'
        placeholder='Search Name, Brand or Product Code...'
        inputSize='sm'
        iconBefore='nzsbi-search'
        iconAfter={searchValue ? 'nzsbi-close' : undefined}
        onClickIconAfter={onClearButtonClick}
        value={searchValue}
        onChange={e => {
          onChangeInputField(e)
        }}
        onKeyDown={e => {
          keyButtonReference.current = e.key
          if (e.key === 'Enter') {
            router.push(`/search?q=${searchValue}`)
            searchResultDropdown.close()
            inputReference.current?.blur()
          }
        }}
      />
    </div>
  )
}

export default ProductSearchBar
