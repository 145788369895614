import { CldOgImage } from 'next-cloudinary'
import NextHead from 'next/head'
import React from 'react'

export interface IHeadProps {
  ogImage?: IOGImageProps
  [x: string]: any
}

export interface IOGImageProps {
  titleText?: string
  imageUrl: string
  alt: string
}

export const Head = ({ title, description, ogImage }: IHeadProps) => {
  const constructuredTitle = title ? `${title} - NZ Safety Blackwoods` : `NZ Safety Blackwoods`
  const constructuredDescription = description
    ? description
    : `NZ Safety Blackwoods - New Zealand's leading supplier of safety equipment, engineering supplies, uniforms and packaging.`

  /*
   * TODO:
   * - Add other meta tags
   * - Implement Next-SEO
   *
   * References:
   * https://www.npmjs.com/package/next-seo
   */

  return (
    <>
      <NextHead>
        <title>{constructuredTitle}</title>
        <meta name='description' content={constructuredDescription} />
        <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0' />
        <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png' />
        <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png' />
        <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png' />
        <link rel='manifest' href='/site.webmanifest' />
        <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#5bbad5' />
        <meta name='msapplication-TileColor' content='#da532c' />
        <meta name='theme-color' content='#ffffff'></meta>
        <meta name='robots' content='noindex,nofollow' />
        {/** Facebook Meta Tags */}
        <meta property='og:title' content={constructuredTitle} />
        <meta property='og:description' content={constructuredDescription} />
        <meta property='og:type' content='website' />
        <meta property='og:site_name' content='NZ Safety Blackwoods' />
        {/** Twitter Meta Tags */}
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:title' content={constructuredTitle} />
        <meta name='twitter:description' content={constructuredDescription} />
        {/** Google Search Console */}
        {/* <meta name='google-site-verification' content='3q6QV7Z7fJ9s4L1k0D7KZ3p0q0Qx5d8q7X9m7m3X7qo' /> */}
      </NextHead>
      {ogImage && (
        <CldOgImage
          width={800}
          height={800}
          crop='scale'
          src={ogImage.imageUrl}
          alt={ogImage.alt}
          overlays={[
            {
              publicId: 'General-Use/Logo_Type_1.png',
              width: 780,
              height: 66,
              position: {
                gravity: 'center'
              }
            },
            {
              publicId: 'General-Use/Logo_Type_2.png',
              width: 120,
              height: 120,
              position: {
                gravity: 'south_east',
                x: 20,
                y: 20
              }
            }
          ]}
        />
      )}
    </>
  )
}

export default Head
