import React, { useState } from 'react'
import { Button } from '@nzsb/shopnx-ui'
import { motion } from 'framer-motion'
import { useModalContext } from 'lib/contexts/modal-context'

import { FastSignIn } from '../FastSignIn'

export const CheckoutFaster: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(false)

  const { signUpModal } = useModalContext()

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  const onSignUpClick = () => {
    signUpModal.open()
  }

  return (
    <div className='flex flex-col gap-5 w-full'>
      <div className='flex flex-col gap-4 w-full bg-white p-5 border border-N-100 rounded'>
        <div className='flex flex-col md:flex-row !w-full gap-4 md:items-center justify-between'>
          <h2 className='text-h2 text-N-800 font-bold'>Checkout Faster</h2>
          <div className='flex flex-col gap-4'>
            <Button
              componentId='fast-checkout-signIn-button'
              appearance='secondary-orange'
              onClick={toggleExpand}>
              Sign In
            </Button>
            <Button
              componentId='fast-checkout-signUp-button'
              appearance='link'
              onClick={onSignUpClick}>
              Create an Online Account
            </Button>
          </div>
        </div>
        {isExpanded && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: isExpanded ? 'auto' : 0, opacity: isExpanded ? 1 : 0 }}
            transition={{ duration: 0.3 }}
            className='overflow-hidden'>
            <FastSignIn />
          </motion.div>
        )}
      </div>

      <div className='flex flex-row gap-4 w-full items-center'>
        <hr className='border-N-100 h-[1px] w-full' />
        Or
        <hr className='border-N-100 h-[1px] w-full' />
      </div>
      <h2 className='text-h2 text-N-800 font-bold'>Continue as Guest</h2>
    </div>
  )
}

export default CheckoutFaster
