import React, { FC } from 'react'
import CN from 'classnames'

export interface IProductTitleProps {
  className?: string
  componentId?: string
  title: string
}

export const ProductTitle: FC<IProductTitleProps> = ({
  className,
  componentId,
  title,

  ...restProps
}: IProductTitleProps) => {
  const ProductTitleClasses = CN(`product-title`, className)

  return (
    <div className={ProductTitleClasses} {...restProps}>
      <h2
        className='product-title__title font-heading text-h2-m font-bold sm:!text-h2'
        data-component-id={`${componentId}-product-title`}>
        {title}
      </h2>
    </div>
  )
}

export default ProductTitle
