import React, { FC, useRef, useState } from 'react'
import { Alert, Button, TextField } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { ILinkToCreditAccount, useLinkToCreditAccountRequest } from 'lib/actions'

import { OnScreenMessage } from 'components/molecules'

export interface ILinkToCreditAccountProps {
  className?: string
  hideHeader?: boolean
}

export const LinkToCreditAccountForm: FC<ILinkToCreditAccountProps> = ({
  className,
  hideHeader,
  ...restProps
}: ILinkToCreditAccountProps) => {
  const sequenceFieldRef: any = useRef()

  const [creditAccount, setCreditAccount] = useState<ILinkToCreditAccount>({
    accountNumber: '',
    delivarySequence: ''
  })

  const [showWhatIsCreditAccountAlert, setShowWhatIsCreditAccountAlert] = useState(false)

  const LinkToCreditAccountClasses = CN(
    `link-to-credit-account flex flex-col overflow-hidden w-full bg-white`,
    className
  )

  const {
    linkToCreditAccount,
    isPending: linkToCreditAccountLoading,
    data: linkToCreditAccountData
  } = useLinkToCreditAccountRequest()

  /* ClientSide: Handle link To CreditAccount */
  const handleLinkToCreditAccount = async () => {
    await linkToCreditAccount({
      accountNumber: creditAccount?.accountNumber,
      delivarySequence: creditAccount?.delivarySequence
    })
  }

  return (
    <div className={LinkToCreditAccountClasses} {...restProps}>
      {!linkToCreditAccountData ? (
        <>
          {!hideHeader && (
            <span className='lg:text-display-h2 text-display-h2-m font-display uppercase text-N-700 mb-8'>
              Link to Credit Account
            </span>
          )}
          <div className='w-full h-auto flex flex-row justify-center items-end'>
            <div className='flex w-7/12'>
              <TextField
                componentId='accountNumber'
                value={creditAccount?.accountNumber}
                label='Credit Account Number'
                wrapperClassName='w-full'
                onChange={e => {
                  const regex = /^[A-Za-z0-9\b]+$/

                  if (e.target.value === '' || regex.test(e.target.value)) {
                    if (e.target.value.length >= 8) {
                      sequenceFieldRef?.current?.focus()
                    }

                    setCreditAccount({
                      ...creditAccount,
                      accountNumber: e.target.value
                    })
                  }
                }}
              />
            </div>

            <div className='mx-4 h-11 border-[0.5px] border-solid border-neutral-600 rotate-[20deg]'></div>

            <div className='w-4/12'>
              <TextField
                componentId='delivarySequence'
                ref={sequenceFieldRef}
                label='Sequence'
                value={creditAccount?.delivarySequence}
                wrapperClassName='w-full'
                onChange={e => {
                  const regex = /^[0-9\b]+$/

                  if (
                    (regex.test(e.target.value) || e.target.value === '') &&
                    e.target.value.length < 4
                  ) {
                    setCreditAccount({
                      ...creditAccount,
                      delivarySequence: e.target.value
                    })
                  }
                }}
              />
            </div>
          </div>

          <span className='pt-4 text-N-500'>
            Your account number can be found on your tax invoice.
          </span>

          <Button
            componentId='needHelp'
            isRingOffset={false}
            appearance='link-gray'
            className='w-fit'
            onClick={() => {
              // Note : need link to help page
            }}>
            Need help?
          </Button>

          <Button
            componentId='submit'
            isRingOffset={false}
            className='mt-8'
            appearance='primary-orange'
            size='md'
            isLoading={linkToCreditAccountLoading}
            disabled={
              !(
                creditAccount?.delivarySequence &&
                creditAccount?.accountNumber &&
                creditAccount?.delivarySequence?.length <= 3
              )
            }
            onClick={handleLinkToCreditAccount}>
            submit
          </Button>

          <span className='text-base text-N-700 font-500 mt-7'>
            Don’t have an existing credit account yet?
          </span>

          <Button
            componentId='signUpForCreditAccount'
            className='mt-2'
            isRingOffset={false}
            appearance='secondary-orange'
            size='md'
            onClick={() => {
              // Note : need link to relevant page
            }}>
            sign up for credit account
          </Button>

          <Button
            componentId='whatIsCreditAccount'
            appearance='link-gray'
            isRingOffset={false}
            className='w-fit mt-[10px]'
            onClick={() => {
              setShowWhatIsCreditAccountAlert(!showWhatIsCreditAccountAlert)
            }}>
            What is a credit account?
          </Button>

          <AnimatePresence>
            {showWhatIsCreditAccountAlert && (
              <motion.div
                className='w-full'
                initial={{ height: 0 }}
                animate={{
                  height: 'auto'
                }}
                exit={{ height: 0, opacity: -2 }}>
                <Alert
                  className='mt-4'
                  alertHeader='Apply for a credit account if you would like to pay for goods or services on invoice'
                  status='Info'
                  variant='inline'
                  closeToast={() => {
                    setShowWhatIsCreditAccountAlert(false)
                  }}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </>
      ) : (
        <OnScreenMessage
          header={linkToCreditAccountData?.messageType === 'success' ? 'Success' : 'Attention!'}
          type={linkToCreditAccountData?.messageType}
          subHeader={linkToCreditAccountData?.messageTitle}
          description={linkToCreditAccountData?.messageDescription}
        />
      )}
    </div>
  )
}

LinkToCreditAccountForm.defaultProps = {
  className: undefined
}

export default LinkToCreditAccountForm
