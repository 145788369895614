import React, { FC } from 'react'
import CN from 'classnames'

export interface IPunchOutSuccessfulProps {
  className?: string
}

export const PunchOutSuccessful: FC<IPunchOutSuccessfulProps> = ({
  className,
  ...restProps
}: IPunchOutSuccessfulProps) => {
  const PunchOutSuccessfulClasses = CN(`punch-out-successful`, className)

  return (
    <div className={PunchOutSuccessfulClasses} {...restProps}>
      <div className='w-[80vw] max-w-[842px] flex flex-col justify-center p-[40px]  items-center bg-white border border-N-100 rounded'>
        <i className='flex items-center nzsbi-check-1 text-G-500 text-[64px]' />
        <div className='mt-[12px] font-sans text-h2 font-bold text-N-800 text-center'>
          Punchout Successful
        </div>
        <div className='flex mt-[4px] font-sans text-base text-N-700 font-normal text-center'>
          Please wait while we direct you back to your procurement environment to complete your
          requisition.
        </div>
      </div>
    </div>
  )
}

export default PunchOutSuccessful
