import { useMutation } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

export interface ICallMatrixFE {
  metricName: string
  tags: string[]
}

/* API Request */
export const callMatrixFERequest = async (req: ICallMatrixFE) => {
  try {
    const response = await httpPOST({
      type: 'internal',
      url: 'matrix/matrix',
      body: req
    })

    return response
  } catch (error) {
    return { error }
  }
}

/* Client side react-query custom hook */
export const useCallMatrixFromFE = () => {
  const { mutateAsync, ...restOptions } = useMutation({
    mutationFn: callMatrixFERequest
  })

  // Passing mutateAsync as signIn
  // and restOptions as rest of the options
  return { callMatrixFromFE: mutateAsync, ...restOptions }
}
