import { useQuery } from '@tanstack/react-query'
import { httpGET } from 'lib/http'

export interface IGetAllCategories {
  messageTitle: string | null
  messageDescription: string | null
  messageType: string
  data: { categories: IAllCategory[] }
}

export interface IAllCategory {
  childCategories: IAllCategory | null
  code: string | null
  count: number
  disabled?: boolean
  icon?: string
  id: number
  image: {
    altAttribute: string
    fileName: string
    titleAttribute: string
  }
  isHideFromMenu: boolean
  name: string
  paths: string | null
  urlPaths: string | null
  urlSlug: string
}

export const getAllCategoriesWithoutMinor = async () => {
  const { data } = await httpGET({
    url: `category/get_mega_menu`
  })
  return data as IGetAllCategories
}

export const useGetAllCategoriesWithoutMinor = () => {
  const { ...restOptions } = useQuery({
    queryKey: ['GET_ALL_CATEGORY_WITHOUT_MINOR'],
    queryFn: () => getAllCategoriesWithoutMinor()
  })

  return { ...restOptions }
}
