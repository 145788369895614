/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { FC, useState } from 'react'
import { TextField } from '@nzsb/shopnx-ui'
import CN from 'classnames'

export interface IPriceFilterProps {
  className?: string
  endValue?: any
  maxPrice?: number
  minPrice?: number
  onChange?: (e: any) => void
  startValue?: any
}

export const PriceFilter: FC<IPriceFilterProps> = ({
  className,
  endValue,
  maxPrice,
  minPrice,
  onChange,
  startValue,
  ...restProps
}: IPriceFilterProps) => {
  const PriceFilterClasses = CN(
    `price-filter flex flex-col w-full sm:w-full lg:max-w-[296px] gap-3 bg-white`,
    className
  )

  const [maxValue, setMaxValue] = useState<any>(maxPrice)
  const [minValue, setMinValue] = useState<any>(minPrice)

  return (
    <div className={PriceFilterClasses} {...restProps}>
      {/* Dropdown Panel ==========================*/}

      <div className='flex flex-col w-full'>
        <div className='flex w-full items-center gap-x-[8px]'>
          <div className='flex flex-col w-full'>
            {/* Min Value TextField ==========================*/}
            <TextField
              autoComplete='price'
              className='w-full'
              componentId='price-filter__minPrice'
              iconBefore='nzsbi-dollar text-N-700 text-sm font-normal'
              id='minPrice'
              inputSize='sm'
              required
              type='number'
              value={minValue}
              onKeyDown={(e: any) => {
                if (
                  e.code === 'Minus' ||
                  e.code === 'NumpadSubtract' ||
                  e.code === 'NumpadAdd' ||
                  e.code === 'NumpadDecimal' ||
                  e.code === 'KeyE'
                ) {
                  e.preventDefault()
                }
              }}
              onChange={(e: any) => {
                const regex = /^[0-9\b]+$/
                if (e.target.value === '' || regex.test(e.target.value)) {
                  if (Number(e.target.value) <= maxValue && e.target.value !== '') {
                    setMinValue(e.target.value)
                    onChange &&
                      onChange({ max: maxValue, min: Number(e.target.value), field: 'min' })
                  } else if (
                    Number(e.target.value) >= maxValue &&
                    Number(e.target.value) !== 0 &&
                    e.target.value !== null
                  ) {
                    setMinValue(e.target.value)
                    onChange &&
                      onChange({ max: maxValue, min: Number(e.target.value), field: 'min' })
                  } else {
                    setMinValue(e.target.value)
                  }
                }
              }}
              onFocus={(e: any) => {
                e.target.value = ''
              }}
              onBlur={(e: any) => {
                if (e.target.value === '') {
                  e.target.value = minValue
                }
              }}
            />
          </div>

          <p className='price-filter__middleText flex justify-start text-base font-normal text-N-500'>
            to
          </p>

          <div className='flex flex-col w-full'>
            {/* Max Value TextField ==========================*/}
            <TextField
              autoComplete='price'
              className='w-full'
              componentId='price-filter__maxPrice'
              iconBefore='nzsbi-dollar text-N-700 text-sm font-normal'
              id='maxPrice'
              inputSize='sm'
              required
              type='number'
              value={maxValue}
              onKeyDown={(e: any) => {
                if (
                  e.code === 'Minus' ||
                  e.code === 'NumpadSubtract' ||
                  e.code === 'NumpadAdd' ||
                  e.code === 'NumpadDecimal' ||
                  e.code === 'KeyE'
                ) {
                  e.preventDefault()
                }
              }}
              onChange={(e: any) => {
                const regex = /^[0-9\b]+$/
                if (e.target.value === '' || regex.test(e.target.value)) {
                  if (Number(e.target.value) >= minValue && e.target.value !== '') {
                    setMaxValue(e.target.value)
                    onChange &&
                      onChange({ max: Number(e.target.value), min: minValue, field: 'max' })
                  } else if (Number(e.target.value) <= minValue && minValue !== 0) {
                    setMaxValue(e.target.value)
                    onChange &&
                      onChange({ max: Number(e.target.value), min: minValue, field: 'max' })
                  } else {
                    setMaxValue(e.target.value)
                  }
                }
              }}
              onFocus={(e: any) => {
                e.target.value = ''
              }}
              onBlur={(e: any) => {
                if (e.target.value === '') {
                  e.target.value = maxValue
                }
              }}
            />
          </div>
        </div>

        {/* RangeSlider Component ==========================*/}
        {/* <div className='flex flex-col items-center justify-center h-[56px] mt-[18px] mb-[8px]'>
          <RangeSlider 
            className='w-full'
            currentMax={maxValue}
            currentMin={minValue}
            start={startValue}
            end={endValue}
            onChange={(e: any) => {
              setMinValue(e.min)
              setMaxValue(e.max)
              onChange && onChange(e)
            }}
          />
        </div> */}
      </div>
    </div>
  )
}

PriceFilter.defaultProps = {
  className: '',
  endValue: undefined,
  maxPrice: undefined,
  minPrice: undefined,
  onChange: undefined,
  startValue: undefined
}

export default PriceFilter
