import React, { FC } from 'react'
import CN from 'classnames'

export interface IconsProps {
  alt: string
  icon: string
  id: number
  link: string
  target: string
}

export interface SocialIconsProps {
  [x: string]: any
  className?: string
  icons?: IconsProps[]
}

export const SocialIcons: FC<SocialIconsProps> = ({
  className,
  icons,
  ...restProps
}: SocialIconsProps) => {
  const SocialIconsClasses = CN(`social-icons flex gap-x-[12px] social-icons__images`, className)

  return (
    <ul data-component-id='social-icons' className={SocialIconsClasses} {...restProps}>
      {icons &&
        icons?.length > 0 &&
        icons.map(
          (icon: { id: number; alt: string; link: string; target: string; icon: string }) => (
            <li key={icon.id}>
              <a href={icon.link || '#'} title={icon.alt} target={icon?.target || '_self'}>
                <i className={`social-icon text-[24px] text-white hover:text-B-200 ${icon.icon}`} />
              </a>
            </li>
          )
        )}
    </ul>
  )
}

SocialIcons.defaultProps = {
  className: undefined,
  icons: undefined
}

export default SocialIcons
