import React, { FC } from 'react'
import { Accordion, Button } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { useModalContext } from 'lib/contexts/modal-context'

export interface IProductDataDemoProps {
  className?: string
  product: any // TODO: Add Product type
}

export const ProductDataDemo: FC<IProductDataDemoProps> = ({
  className,
  product,
  ...restProps
}: IProductDataDemoProps) => {
  const ProductDataDemoClasses = CN(`product-data-demo flex flex-col`, className)

  const { bulkOrderModal } = useModalContext()

  const [productDescriptionExpanded, setProductDescriptionExpanded] = React.useState(false)

  const { title, description } = product?.specs?.data || {}

  return (
    <div className={ProductDataDemoClasses} {...restProps}>
      <div className='flex flex-col md:flex-row gap-[32px]'>
        <div className='flex flex-col w-full border'>
          <div>Breadcrumbs</div>
          <div>Image Carousel</div>
        </div>
        <div className='flex flex-col w-full border'>
          <div className='bg-R-200'>{title}</div>
          <div className='flex items-center justify-between'>
            <div>Pricing</div>
            <div>Code Details</div>
          </div>
          <div>Colours</div>
          <div>Sizes</div>
          <div>Actions with Icons</div>
          <div>Button area</div>
          <div className='flex items-center justify-between'>
            <span>Ask Question</span>
            <span>Print</span>
          </div>
        </div>
      </div>
      <div className='p-8'>
        <Button
          appearance='secondary-orange'
          onClick={() => {
            bulkOrderModal.set(true)
          }}>
          Bulk Order
        </Button>
      </div>
      <div className='border w-full flex flex-col py-[48px] md:flex-row-reverse gap-x-[32px] gap-y-[24px]'>
        <div className='w-full border'>
          <Accordion
            item={{
              content: (
                <div className='flex flex-col gap-y-4'>
                  <span className='text-h5 w-[120px] font-600'>Description</span>
                  <span className='text-h4'>{description?.content}</span>
                  {description?.features && (
                    // map the featurs
                    <ul className='flex flex-col gap-[10px]'>
                      {description?.features?.map((feature: any) => (
                        <li key={feature?.id} className='flex'>
                          <span className='text-h4'>{feature?.feature}</span>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ),
              id: 0,
              title: 'PRODUCT DESCRIPTION'
            }}
            expanded={productDescriptionExpanded}
            setExpanded={setProductDescriptionExpanded}
          />
        </div>
        <div className='w-full border'>
          <Accordion
            item={{
              content: (
                <div className='flex flex-col gap-y-4'>
                  <span className='text-h5 w-[120px] font-600'>Description</span>
                  <span className='text-h4'>{description?.content}</span>
                  {description?.features && (
                    // map the featurs
                    <ul className='flex flex-col gap-[10px]'>
                      {description?.features?.map((feature: any) => (
                        <li key={feature?.id} className='flex'>
                          <span className='text-h4'>{feature?.feature}</span>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ),
              id: 0,
              title: 'PDF Downloads'
            }}
            expanded={productDescriptionExpanded}
            setExpanded={setProductDescriptionExpanded}
          />
        </div>
      </div>
    </div>
  )
}

export default ProductDataDemo
