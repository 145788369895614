import { ImageConfigurations } from 'lib/static/globalStaticData'
/**
 * @param imageName - Name of the image [string]
 * @param width - Width of the image [number]
 * @param height - Height of the image [number]
 * @returns - Image url [string]
 *
 * @example
 * getImageUrl('production/image.png', 100, 100)
 * @returns - if storage is cloudinary, it will return 'General-Use/image.png' (which is public_id of the image in cloudinary)
 */

export interface IGetImageUrlProps {
  imageName: string | null
  width?: number
  height?: number
  storage?: 'cloudinary' | 'irs'
  system?: 'Backend' | 'CMS' | 'General'
}

const getFolderName = (system: string) => {
  switch (system) {
    case 'Backend':
      switch (process.env.NEXT_PUBLIC_MATRIX_ENV) {
        case 'localhost':
          return 'NopCommerce-Test'
        case 'dev':
          return 'NopCommerce-Test'
        case 'qa':
          return 'NopCommerce-Test'
        case 'uat':
          return 'NopCommerce-UAT'
        case 'prod':
          return 'NopCommerce-Prod'
        default:
          return 'NopCommerce-Test'
      }

    case 'CMS':
      return ''

    case 'General':
      return 'General-Use'
  }
}

export const getImageUrl = ({
  imageName,
  width = 100,
  height = 100,
  storage = 'cloudinary',
  system = 'Backend'
}: IGetImageUrlProps) => {
  if (!imageName) return 'General/No_Image_Available_New.png'
  if (storage === 'cloudinary') {
    const folderName = getFolderName(system)
    return `${folderName}/${imageName}`
  }

  return `${ImageConfigurations.url}${width}x${height}/${ImageConfigurations.environment}/${imageName}`
}

export default getImageUrl
