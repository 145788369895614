import Image from 'next/image'
import { useRouter } from 'next/router'
import React, { FC } from 'react'
import { Button } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import emptyCartImg from 'public/images/cart-img.png'

export interface IEmptyCartProps {
  className?: string
  componentId?: string
  onClick?: () => void
}

export const EmptyCart: FC<IEmptyCartProps> = ({
  className,
  componentId,
  onClick,
  ...restProps
}: IEmptyCartProps) => {
  const EmptyCartClasses = CN(
    `empty-cart bg-white flex flex-col items-center justify-center`,
    className
  )

  const router = useRouter()

  const onClickBackToHome = () => {
    if (onClick) {
      onClick()
    }
    router.push('/')
  }

  return (
    <div className={EmptyCartClasses} {...restProps}>
      <div
        className={`${componentId}-zero-result-img flex flex-col items-center justify-center w-[128px] h-[128px] lg:w-[160px] lg:h-[160px] mt-[16px] sm:!mt-[24px] lg:!mt-[48px]`}>
        <Image src={emptyCartImg} alt='' />
      </div>
      <p
        className={`${componentId}-zero-result-title flex font-display font-400 text-center text-display-h2-m lg:text-display-h2 text-N-800`}>
        YOUR CART IS EMPTY
      </p>
      <Button
        componentId={`${componentId}-start-shopping-button`}
        onClick={onClickBackToHome}
        className='w-[146px] text-center mt-[16px] mb-[80px] lg:mb-[103px]'
        size='sm'
        appearance='primary-orange'>
        START SHOPPING
      </Button>
    </div>
  )
}

export default EmptyCart
