import React, { ErrorInfo, ReactNode } from 'react'
import { callMatrixFERequest } from 'lib/actions'
import { ILog, loggingRequest } from 'lib/actions/logger/loggingRequest'
import LogLevel from 'lib/enums/logLevel'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const errorString = error?.toString()
    const errorInfoString = errorInfo?.componentStack?.toString() ?? ''
    console.log({ errorString, errorInfoString })
    // send to backend logging service
    const log: ILog = { level: LogLevel.Error, message: `${errorInfoString}, ${errorInfoString}` }
    callMatrixFERequest({
      metricName: 'web.errors.ErrorPage',
      tags: [`type:ClientSideError`]
    })
    loggingRequest(log).catch(err => {
      console.error(err)
    })
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className='page flex flex-col items-center bg-[#EAEAEA] h-[100vh] overflow-auto'>
          <div className='pt-20'>
            <svg
              width='209'
              height='52'
              viewBox='0 0 209 52'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M16.5524 15.7083V14.0877C16.5524 10.5504 16.5524 7.01311 16.5391 3.47583C16.5391 3.00873 16.67 2.91004 17.1203 2.91662C19.0782 2.94294 21.0368 2.94294 22.9962 2.91662C23.4731 2.91662 23.5618 3.05478 23.5596 3.49557C23.5463 10.2792 23.5463 17.0621 23.5596 23.8443C23.5596 24.2697 23.4531 24.3859 23.0139 24.3837C19.8375 24.3633 16.6582 24.3633 13.4759 24.3837C13.0833 24.3837 12.9435 24.25 12.8104 23.91C11.4145 20.0796 10.0045 16.2543 8.5804 12.4342C8.52207 12.2477 8.41939 12.0777 8.28095 11.9386V12.3157C8.28095 16.1447 8.28095 19.9736 8.29204 23.8026C8.29204 24.2412 8.20553 24.3903 7.72641 24.3837C5.73007 24.3552 3.73374 24.364 1.7374 24.3837C1.38915 24.3837 1.2605 24.3136 1.2605 23.9451C1.27233 17.0884 1.27233 10.2324 1.2605 3.37715C1.2605 3.00215 1.38915 2.93855 1.7374 2.93855C4.96999 2.94879 8.20258 2.94879 11.4352 2.93855C11.5763 2.9157 11.7208 2.949 11.8372 3.03119C11.9536 3.11337 12.0323 3.23775 12.0562 3.37715C13.4626 7.3596 14.88 11.3377 16.3084 15.3114C16.3617 15.4561 16.4127 15.603 16.4637 15.75L16.5524 15.7083Z'
                fill='#EF7D00'
              />
              <path
                d='M118.193 44.6033C118.337 43.1735 118.485 41.7524 118.636 40.316C118.864 37.9607 119.086 35.6047 119.302 33.248C119.322 33.0441 119.344 32.9015 119.606 32.9037C121.95 32.9037 124.293 32.9037 126.688 32.9037C126.355 34.3313 126.04 35.7107 125.714 37.0879C124.801 40.9476 123.878 44.805 122.977 48.6669C122.886 49.066 122.755 49.2195 122.312 49.2173C119.264 49.1939 116.216 49.1939 113.169 49.2173C112.725 49.2173 112.583 49.0923 112.552 48.6493C112.29 44.9454 112.004 41.2414 111.727 37.5375C111.602 37.5726 111.591 37.6822 111.576 37.7875C111.046 41.4059 110.52 45.0258 109.997 48.6471C109.939 49.0419 109.837 49.2239 109.364 49.2217C106.259 49.1954 103.154 49.202 100.048 49.2217C99.6955 49.2217 99.538 49.1427 99.4559 48.7831C98.264 43.5682 97.0647 38.3555 95.8581 33.1449C95.8447 33.0923 95.8403 33.0375 95.8292 32.9783C95.9778 32.862 96.1464 32.9169 96.3017 32.9169C98.4245 32.9169 100.549 32.9278 102.672 32.9037C103.096 32.9037 103.191 33.0506 103.233 33.4235C103.64 37.0974 104.056 40.7699 104.48 44.441C104.51 44.5012 104.546 44.5584 104.586 44.612C104.693 43.8116 104.788 43.1033 104.881 42.3927C105.279 39.3796 105.684 36.3664 106.057 33.3511C106.106 32.9542 106.261 32.9125 106.609 32.9125C109.693 32.9125 112.778 32.9278 115.861 32.9125C116.289 32.9125 116.396 33.0331 116.456 33.4454C116.99 37.1734 117.565 40.9015 118.117 44.6296L118.193 44.6033Z'
                fill='#205387'
              />
              <path
                d='M15.4123 38.3357C16.2226 38.4605 17.022 38.6468 17.8035 38.8927C19.9041 39.6559 20.9621 41.1712 21.0065 43.3818C21.0397 45.0243 20.6715 46.4958 19.3961 47.6318C18.3713 48.5462 17.1092 48.9212 15.7716 49.1054C14.9824 49.1945 14.1879 49.229 13.3938 49.2085C9.53419 49.2085 5.67238 49.2085 1.8128 49.2195C1.34477 49.2195 1.24273 49.1011 1.24495 48.6515C1.25974 41.9044 1.25974 35.1581 1.24495 28.4124C1.24495 27.9892 1.30262 27.8203 1.79727 27.8247C5.76776 27.8532 9.74047 27.8401 13.711 27.8664C15.172 27.841 16.6235 28.1034 17.9809 28.6383C19.5802 29.3094 20.4098 30.5287 20.5495 32.2195C20.6158 32.8915 20.5986 33.5691 20.4985 34.237C20.137 36.4146 18.6575 37.4958 16.6456 38.0418C16.2862 38.1427 15.8981 38.2217 15.4123 38.3357ZM9.23695 42.6208C9.23695 43.1668 9.25914 43.7173 9.23695 44.2611C9.21699 44.6274 9.3035 44.7962 9.71386 44.7524C10.0456 44.7359 10.378 44.7359 10.7098 44.7524C11.6503 44.7524 12.1738 44.3839 12.5065 43.5089C12.6854 43.0094 12.7446 42.4757 12.6795 41.9497C12.5886 41.0375 12.0141 40.5024 11.0736 40.4761C10.5945 40.4629 10.1131 40.4936 9.634 40.4761C9.30572 40.4585 9.2303 40.5879 9.23917 40.8861C9.25026 41.4475 9.23695 42.0375 9.23695 42.6208ZM10.2174 36.2129C10.7675 36.2129 11.3265 36.2699 11.8034 35.8861C12.4533 35.362 12.6906 34.3379 12.3801 33.4366C12.0895 32.6142 11.5416 32.2633 10.5434 32.2633C10.2307 32.2633 9.91571 32.2809 9.60295 32.2633C9.29019 32.2458 9.22586 32.3576 9.2303 32.6383C9.24583 33.5155 9.2303 34.3927 9.2303 35.2589C9.23695 36.2129 9.23695 36.2129 10.2174 36.2129Z'
                fill='#205387'
              />
              <path
                d='M53.7398 18.1535C54.8843 18.1535 56.0311 18.1776 57.1757 18.1403C57.6193 18.1271 57.7568 18.296 57.7657 18.6864C57.7669 19.0141 57.8049 19.3406 57.8788 19.66C58.1317 20.6557 58.6375 21.0526 59.5935 20.9758C60.0103 20.9382 60.4012 20.7591 60.6996 20.4688C60.998 20.1786 61.1856 19.7952 61.2305 19.3837C61.3392 18.3157 60.849 17.5635 59.9506 17.0307C58.9081 16.4122 57.7324 16.1535 56.579 15.8443C55.5961 15.6028 54.6287 15.3033 53.6821 14.9473C50.3238 13.5964 49.594 11.3859 49.9733 8.43197C50.3904 5.17758 52.0983 3.421 55.379 2.69513C58.3057 2.04157 61.3483 2.0912 64.2516 2.83986C66.2746 3.35522 67.896 4.41662 68.5748 6.51311C68.7759 7.13873 68.8798 7.79088 68.8831 8.44732C68.8831 8.70171 68.8565 8.84425 68.5348 8.84206C66.2435 8.8289 63.9544 8.84206 61.6586 8.84206C61.4079 8.84206 61.2881 8.78723 61.3015 8.51092C61.3015 8.31088 61.2784 8.11149 61.2327 7.91662C61.0907 7.14908 60.7891 6.82013 60.1103 6.67539C59.2829 6.50653 58.6463 6.70829 58.2825 7.25434C58.0861 7.5433 57.9839 7.88474 57.9894 8.23283C57.995 8.58093 58.1081 8.91901 58.3136 9.20171C58.7062 9.74118 59.2763 10.035 59.8796 10.2828C61.3303 10.875 62.8786 11.16 64.3558 11.6754C64.987 11.8821 65.6016 12.135 66.1947 12.432C68.6724 13.7236 69.7149 15.8421 69.3888 18.5899C68.9718 22.0986 66.8912 23.9868 63.6594 24.6491C60.4298 25.3262 57.0833 25.2292 53.8995 24.3662C50.9715 23.5701 49.6029 21.6403 49.6428 18.6403C49.6428 18.2653 49.7471 18.1381 50.1397 18.1447C51.3331 18.1688 52.5398 18.1535 53.7398 18.1535Z'
                fill='#EF7D00'
              />
              <path
                d='M179.935 46.5484C179.467 47.3708 179.028 48.0287 178.382 48.5352C176.282 50.1997 172.531 50.0703 170.792 48.2545C169.92 47.3445 169.51 46.2085 169.359 45.0023C169.011 42.2085 168.915 39.3949 169.445 36.6252C170.111 33.1668 172.482 32.1405 175.754 32.5265C177.459 32.726 178.768 33.623 179.611 35.1756C179.698 35.0263 179.726 34.8505 179.689 34.6822C179.689 32.5681 179.704 30.4541 179.689 28.3401C179.689 27.9322 179.793 27.8225 180.21 27.8269C182.555 27.8466 184.899 27.8423 187.244 27.8269C187.594 27.8269 187.714 27.9059 187.714 28.2655C187.702 35.0988 187.702 41.9322 187.714 48.7655C187.714 49.0857 187.654 49.2041 187.295 49.2041C184.988 49.1895 182.68 49.1895 180.372 49.2041C179.986 49.2041 179.928 49.0725 179.928 48.737C179.95 48.0659 179.935 47.3817 179.935 46.5484ZM179.713 41.0243C179.713 40.3883 179.755 39.748 179.713 39.1164C179.642 38.3072 179.088 37.8006 178.364 37.7787C177.586 37.7567 177.034 38.1624 176.943 39.0023C176.771 40.4123 176.78 41.838 176.969 43.2458C177.074 43.9585 177.573 44.2984 178.3 44.283C179.07 44.2677 179.631 43.7436 179.709 42.8795C179.751 42.2655 179.709 41.6449 179.709 41.0243H179.713Z'
                fill='#205387'
              />
              <path
                d='M81.4245 40.1557C81.5332 39.9781 81.6464 39.8005 81.7484 39.6206L85.4926 33.3114C85.6213 33.0921 85.7145 32.8948 86.0583 32.897C88.8088 32.9123 91.5615 32.897 94.3165 32.897C94.383 33.1492 94.1856 33.2807 94.0946 33.4364C92.6351 35.7128 91.1755 37.9913 89.6849 40.2478C89.5642 40.3869 89.4962 40.5632 89.4925 40.7465C89.4889 40.9297 89.5499 41.1085 89.665 41.2522C91.2177 43.7106 92.7283 46.1886 94.2543 48.6601C94.3475 48.8114 94.4362 48.9671 94.5316 49.125C94.3564 49.2522 94.1878 49.1952 94.0325 49.1952C91.3707 49.1952 88.709 49.1952 86.0472 49.1952C85.8893 49.2169 85.7286 49.1855 85.591 49.1059C85.4533 49.0264 85.3468 48.9033 85.2886 48.7566C84.0908 46.3794 82.8708 44.011 81.6508 41.6448C81.5732 41.4935 81.5532 41.2961 81.3114 41.1799V41.7325C81.3114 44.0658 81.3114 46.3992 81.3114 48.7347C81.3114 49.0746 81.2449 49.204 80.8678 49.2018C78.521 49.182 76.1764 49.1864 73.8296 49.2018C73.4836 49.2018 73.3571 49.1294 73.3571 48.7632C73.3704 41.943 73.3704 35.1236 73.3571 28.3049C73.3571 27.9167 73.4747 27.818 73.8562 27.8224C76.1653 27.8378 78.4744 27.8443 80.7835 27.8224C81.2471 27.8224 81.3203 27.9803 81.3181 28.3838C81.3048 32.1228 81.3181 35.8597 81.3181 39.5987V40.1316L81.4245 40.1557Z'
                fill='#205387'
              />
              <path
                d='M34.3908 8.07006C31.8222 8.07006 29.3667 8.05252 26.9112 8.08322C26.4055 8.08322 26.3012 7.94506 26.3123 7.47576C26.3456 6.10953 26.3367 4.74111 26.3123 3.37488C26.3123 3.01085 26.4188 2.91874 26.7781 2.92094C32.4684 2.93117 38.1617 2.93117 43.8579 2.92094C44.1995 2.92094 44.3015 3.00866 44.3015 3.34638C44.2816 4.56787 44.2838 5.78936 44.3015 7.01085C44.2949 7.28266 44.1948 7.54412 44.0176 7.75208C41.4002 11.3705 38.7865 14.9897 36.1764 18.6095C36.0333 18.7484 35.9352 18.9263 35.8947 19.1205H36.8241C39.2264 19.1205 41.6287 19.1205 44.0287 19.1205C44.4346 19.1205 44.5633 19.2126 44.5566 19.6315C44.5278 21.0701 44.5367 22.5108 44.5566 23.9516C44.5566 24.274 44.4834 24.3902 44.1307 24.3902C38.1269 24.3785 32.1231 24.3785 26.1193 24.3902C25.7289 24.3902 25.6757 24.2477 25.6757 23.9165C25.6905 22.7674 25.6905 21.619 25.6757 20.4714C25.6691 20.163 25.7701 19.8617 25.9619 19.6183C28.6488 15.9429 31.3387 12.2652 34.0315 8.58541C34.1291 8.44287 34.2311 8.29594 34.3908 8.07006Z'
                fill='#EF7D00'
              />
              <path
                d='M148.972 18.6406C149.415 16.1625 149.815 13.816 150.225 11.4695C150.405 10.4476 150.595 9.42785 150.768 8.39935C150.802 8.20198 150.819 8.05285 151.079 8.05285C153.774 8.05285 156.469 8.05285 159.233 8.05285C158.676 9.43882 158.144 10.7722 157.609 12.1033C155.613 17.0529 153.594 21.9915 151.645 26.9586C150.837 29.0112 149.253 29.9805 147.208 30.3818C145.567 30.7042 143.894 30.498 142.235 30.5331C141.126 30.555 141.126 30.5331 141.126 29.4169C141.126 28.759 141.144 28.1011 141.126 27.4432C141.113 27.0967 141.202 26.9695 141.57 26.9783C142.271 27.0276 142.975 26.9967 143.668 26.8862C144.757 26.6669 145.239 26.009 144.959 24.952C144.582 23.5243 143.95 22.1822 143.422 20.805C141.854 16.7115 140.269 12.6179 138.666 8.52435C138.489 8.06601 138.617 8.03312 139.032 8.03531C141.452 8.05066 143.872 8.05724 146.292 8.03531C146.754 8.03531 146.918 8.16031 147.002 8.60329C147.601 11.7393 148.202 14.8643 148.808 17.9827C148.841 18.1625 148.894 18.3314 148.972 18.6406Z'
                fill='#EF7D00'
              />
              <path
                d='M136.703 49.682C135.246 49.671 133.797 49.6009 132.371 49.2434C129.913 48.625 128.409 47.0921 127.839 44.6776C127.265 42.2768 127.269 39.776 127.853 37.3772C128.46 34.8684 130.1 33.3289 132.653 32.8158C135.28 32.2783 137.989 32.2656 140.62 32.7785C143.653 33.3684 145.279 35.0438 145.804 38.057C146.213 40.1922 146.178 42.3873 145.702 44.5088C145.019 47.4079 143.282 48.9517 140.285 49.4342C139.1 49.6087 137.902 49.6916 136.703 49.682ZM135.277 41.0088C135.277 41.9364 135.277 42.8662 135.277 43.7938C135.277 44.7215 135.776 45.1754 136.732 45.1754C137.688 45.1754 138.225 44.7368 138.236 43.8245C138.255 41.9678 138.255 40.1104 138.236 38.2522C138.236 37.3509 137.695 36.9364 136.699 36.9364C135.769 36.9474 135.288 37.4035 135.279 38.3048C135.27 39.2061 135.277 40.1074 135.277 41.0088Z'
                fill='#205387'
              />
              <path
                d='M157.485 49.6797C156.119 49.7057 154.755 49.5858 153.415 49.3223C150.815 48.7543 149.169 47.2126 148.573 44.6512C148.042 42.3509 148.029 39.9635 148.535 37.6578C149.118 34.9166 150.828 33.2872 153.61 32.7762C156.249 32.2764 158.961 32.289 161.595 32.8135C164.301 33.3573 165.927 35.0328 166.486 37.6885C166.98 39.9398 166.969 42.2706 166.455 44.5174C165.779 47.41 163.998 48.9736 161.021 49.4363C159.85 49.6086 158.668 49.69 157.485 49.6797ZM158.971 41.0854C158.971 40.1578 158.971 39.228 158.971 38.3003C158.971 37.4363 158.51 36.9845 157.64 36.9538C156.598 36.9209 156.028 37.3201 156.012 38.2258C155.977 40.1176 155.977 42.0109 156.012 43.9056C156.028 44.7828 156.629 45.2214 157.592 45.1775C158.505 45.1359 158.956 44.6951 158.962 43.8179C158.978 42.9078 158.971 41.9977 158.971 41.0854Z'
                fill='#205387'
              />
              <path
                d='M90.3726 24.3727H87.3448C85.923 24.3727 84.4989 24.353 83.0771 24.3727C82.6334 24.3727 82.4116 24.2916 82.3606 23.8113C82.283 23.0438 82.1388 22.2762 82.0212 21.5021C80.8123 24.1337 78.5299 24.8201 75.8903 24.8289C74.6658 24.8289 73.5058 24.5613 72.5364 23.7675C71.4273 22.864 71.0236 21.6271 71.0059 20.274C70.9859 18.6644 71.3009 17.1425 72.6784 16.0613C73.681 15.274 74.8854 15.0131 76.121 14.842C77.6005 14.6359 79.1088 14.7104 80.5728 14.4034C81.6153 14.1841 82.0456 13.66 81.9547 12.8113C81.9361 12.6017 81.8695 12.399 81.76 12.2185C81.6505 12.038 81.5009 11.8845 81.3225 11.7697C80.2134 11.0175 78.572 11.6249 78.2681 12.9078C78.2082 13.1556 78.1018 13.2192 77.8578 13.2192C75.9746 13.2192 74.0869 13.2017 72.2081 13.2192C71.7645 13.2192 71.8488 12.9758 71.8643 12.728C72.0152 10.4583 73.2573 8.73676 75.4843 8.13588C78.9203 7.20606 82.3917 7.24553 85.7655 8.4429C88.1034 9.27185 89.126 10.9166 89.1349 13.4868C89.1349 16.2397 89.1349 18.9926 89.1349 21.7455C89.1349 22.785 89.4498 23.6841 90.3726 24.3727ZM82.0434 16.1468C81.4002 16.7587 81.1828 16.8815 80.1358 17.2587C79.6606 17.4 79.2113 17.6154 78.8049 17.8968C78.5771 18.0549 78.3965 18.2706 78.2819 18.5214C78.1673 18.7721 78.123 19.0487 78.1536 19.3222C78.1842 19.5957 78.2886 19.856 78.4559 20.0759C78.6231 20.2959 78.8471 20.4673 79.1044 20.5723C80.2401 21.0964 81.4024 20.4911 81.8172 19.1447C82.1055 18.2017 82.0811 17.2126 82.0434 16.1468Z'
                fill='#EF7D00'
              />
              <path
                d='M117.92 17.055C116.203 17.055 114.484 17.055 112.767 17.055C112.414 17.055 112.275 17.1361 112.295 17.5089C112.337 18.2721 112.308 19.044 112.516 19.783C112.575 20.0629 112.728 20.3146 112.951 20.4962C113.175 20.6777 113.454 20.7782 113.743 20.7809C114.072 20.8193 114.405 20.8237 114.735 20.794C115.648 20.6624 116.121 20.3203 116.287 19.4278C116.387 18.8861 116.629 18.7984 117.124 18.805C119.083 18.8328 121.041 18.8328 122.997 18.805C123.465 18.805 123.576 18.9607 123.516 19.3686C123.294 20.8905 122.46 22.0725 121.298 23.0396C120.136 24.0067 118.714 24.4234 117.241 24.6361C114.885 24.9782 112.528 24.9936 110.201 24.4519C106.987 23.7019 105.228 21.6186 104.877 18.2765C104.683 16.6822 104.728 15.0683 105.01 13.487C105.591 10.5352 107.406 8.70848 110.334 8.00453C113.037 7.35941 115.856 7.35941 118.559 8.00453C121.32 8.66243 122.835 10.4168 123.257 13.294C123.41 14.3952 123.492 15.5049 123.503 16.6164C123.503 16.976 123.401 17.0769 123.039 17.0725C121.356 17.0462 119.639 17.055 117.92 17.055ZM114.267 14.1537H115.438C116.529 14.1537 116.529 14.1537 116.309 13.0703C116.116 12.1186 115.535 11.5352 114.684 11.4519C113.131 11.2918 112.093 12.3291 112.21 13.8642C112.23 14.1208 112.341 14.1602 112.554 14.1559C113.122 14.1493 113.694 14.1537 114.267 14.1537Z'
                fill='#EF7D00'
              />
              <path
                d='M51.841 49.2061H48.7711C47.3493 49.2061 45.9252 49.1754 44.5056 49.2236C43.9999 49.2412 43.8845 49.035 43.8202 48.6315C43.6817 47.8822 43.5928 47.1247 43.554 46.364C43.5074 46.364 43.4498 46.364 43.4453 46.3771C42.5448 48.5854 40.6726 49.3661 38.4789 49.6359C37.6898 49.7348 36.8896 49.7059 36.1099 49.5504C34.0071 49.1359 32.6718 47.6556 32.5209 45.5438C32.463 44.8166 32.5145 44.0849 32.674 43.3727C33.1398 41.342 34.5572 40.3398 36.5047 39.9056C37.86 39.603 39.2486 39.5811 40.6261 39.467C41.2528 39.4376 41.8727 39.3255 42.4693 39.1337C43.1548 38.8837 43.4941 38.4056 43.4475 37.7806C43.4333 37.4593 43.313 37.1513 43.1051 36.9039C42.8973 36.6564 42.6132 36.4829 42.2963 36.41C40.981 36.0854 40.0915 36.5789 39.7255 37.8705C39.6656 38.0898 39.5414 38.0569 39.4039 38.0569C37.4829 38.0569 35.562 38.0569 33.6367 38.0569C33.3483 38.0569 33.3261 37.9583 33.3483 37.7017C33.5901 34.9012 35.0141 33.2872 37.9887 32.7346C40.7711 32.2008 43.6396 32.2952 46.3799 33.0109C47.0217 33.1814 47.6356 33.4413 48.2033 33.7828C48.9317 34.1874 49.5347 34.7809 49.9471 35.4993C50.3595 36.2177 50.5657 37.0338 50.5434 37.8596C50.5634 40.5942 50.5434 43.3267 50.5434 46.0613C50.5567 47.6951 50.7098 48.0723 51.841 49.2061ZM43.5695 40.9122C42.9041 41.7061 42.0679 41.9407 41.2649 42.228C40.9153 42.3461 40.5855 42.5154 40.2867 42.7302C40.0442 42.9003 39.855 43.1346 39.7409 43.406C39.6267 43.6775 39.5922 43.9753 39.6412 44.2653C39.7277 44.8793 40.1425 45.2631 40.717 45.4758C41.713 45.842 42.7133 45.3903 43.157 44.3552C43.6006 43.3201 43.5695 42.1754 43.5695 40.9122Z'
                fill='#205387'
              />
              <path
                d='M193.566 44.467C194.599 44.467 195.635 44.467 196.671 44.467C196.966 44.467 197.086 44.5394 197.115 44.8508C197.194 45.5394 197.525 45.9341 198.077 46.0263C198.776 46.1447 199.457 46.114 199.936 45.4934C200.342 44.9648 200.145 44.2499 199.492 43.8749C198.84 43.4999 198.042 43.3552 197.285 43.1995C195.988 42.9298 194.675 42.7236 193.435 42.2324C190.476 41.0613 189.358 37.8947 190.933 35.1447C191.23 34.6258 191.633 34.1733 192.116 33.8155C192.599 33.4578 193.151 33.2025 193.739 33.0657C197.175 32.1374 200.802 32.1564 204.228 33.1205C206.267 33.6995 207.267 35.1074 207.42 37.1841C207.444 37.5328 207.371 37.6578 206.988 37.6556C205.031 37.6359 203.073 37.6403 201.114 37.6556C200.806 37.6556 200.686 37.5833 200.608 37.2653C200.346 36.2039 199.599 35.7916 198.581 36.0482C198.028 36.1885 197.807 36.5504 197.733 37.0635C197.66 37.5767 197.784 37.9714 198.226 38.2477C198.838 38.6271 199.537 38.7148 200.222 38.8091C201.573 38.9934 202.935 39.0767 204.261 39.432C206.99 40.1644 207.981 41.9451 207.704 44.6622C207.46 47.0745 206.076 48.5328 203.776 49.1008C200.2 49.978 196.609 50.0328 193.106 48.7609C191.347 48.1249 190.17 46.9166 190.001 44.9539C189.965 44.5613 190.074 44.4517 190.467 44.4605C191.496 44.4824 192.532 44.467 193.566 44.467Z'
                fill='#205387'
              />
              <path
                d='M67.377 39.2542C66.3234 39.2542 65.2697 39.2389 64.2183 39.2542C63.8479 39.2542 63.717 39.149 63.7747 38.7959C63.7847 38.6689 63.7847 38.5413 63.7747 38.4143C63.7525 37.0985 62.639 36.4406 61.4301 37.0218C61.2345 37.1087 61.0693 37.2508 60.9553 37.4303C60.8412 37.6098 60.7834 37.8186 60.7891 38.0305C60.7736 40.0525 60.758 42.0766 60.7891 44.1007C60.8024 44.899 61.3081 45.2608 62.2153 45.274C63.1603 45.2893 63.6571 44.9341 63.7481 44.1358C63.7847 43.7539 63.7914 43.3697 63.7681 42.9867C63.757 42.717 63.8191 42.6029 64.1296 42.6051C66.3278 42.6183 68.526 42.6051 70.7242 42.6051C70.8772 42.6051 71.0746 42.5547 71.0525 42.8244C70.8928 44.831 70.3316 46.6556 68.6502 47.9691C67.4413 48.9143 66.0239 49.3507 64.5244 49.5481C62.2538 49.876 59.94 49.7553 57.7169 49.1928C55.1993 48.5349 53.711 46.9253 53.223 44.4077C52.7636 42.1134 52.7794 39.7507 53.2695 37.4626C53.793 35.0503 55.3213 33.502 57.7436 32.9099C60.5641 32.193 63.5206 32.1795 66.3478 32.8705C69.2047 33.581 70.7552 35.4823 70.9837 38.3529C71.0525 39.217 71.0525 39.217 70.1497 39.217L67.377 39.2542Z'
                fill='#205387'
              />
              <path
                d='M100.696 18.0441C100.696 19.9936 100.685 21.9454 100.696 23.895C100.696 24.2743 100.601 24.384 100.208 24.3818C97.901 24.3613 95.5927 24.3613 93.2828 24.3818C92.8392 24.3818 92.6972 24.2831 92.6994 23.8182C92.7194 19.9739 92.6994 16.1252 92.7216 12.2809C92.7216 11.7875 92.6063 11.623 92.0917 11.6493C91.0159 11.691 91.0159 11.6603 91.0159 10.6186C91.0159 9.90811 91.0292 9.19539 91.0159 8.48486C91.0048 8.1603 91.0846 8.03092 91.4418 8.04627C91.841 8.06162 92.3689 8.22609 92.6152 7.97609C92.9057 7.68223 92.715 7.14495 92.7105 6.71513C92.695 5.38618 93.0277 4.22609 94.23 3.45635C94.796 3.10409 95.4533 2.92201 96.1221 2.93223C98.5798 2.93223 101.035 2.93223 103.491 2.93223C103.808 2.93223 103.957 2.98267 103.945 3.34451C103.919 4.23706 103.921 5.13179 103.945 6.02434C103.945 6.36864 103.835 6.46293 103.502 6.44758C103.041 6.42455 102.579 6.42455 102.118 6.44758C101.707 6.46732 101.304 6.54407 101.026 6.88618C100.895 7.02724 100.801 7.19874 100.754 7.38495C100.706 7.57116 100.707 7.76612 100.756 7.95197C100.842 8.1932 101.255 8.04188 101.519 8.04846C102.184 8.066 102.85 8.06381 103.515 8.04846C103.804 8.04846 103.959 8.09671 103.943 8.43223C103.923 9.3796 103.926 10.327 103.943 11.2831C103.943 11.6121 103.806 11.68 103.513 11.6735C102.754 11.6603 101.996 11.6932 101.242 11.6603C100.813 11.6428 100.678 11.7568 100.683 12.1976C100.711 14.1428 100.696 16.0923 100.696 18.0441Z'
                fill='#EF7D00'
              />
              <path
                d='M126.493 16.6995C126.493 15.1863 126.482 13.6732 126.493 12.16C126.493 11.7982 126.429 11.649 126.016 11.6512C124.854 11.6512 124.854 11.6403 124.854 10.478C124.854 9.78499 124.854 9.09201 124.854 8.39903C124.854 8.13806 124.94 8.02403 125.206 8.05473C125.243 8.05802 125.279 8.05802 125.315 8.05473C126.489 8.03061 126.489 8.03061 126.489 6.88368C126.489 6.21043 126.489 5.53499 126.489 4.86175C126.489 4.57666 126.528 4.43631 126.872 4.4385C129.293 4.45166 131.712 4.45166 134.13 4.4385C134.438 4.4385 134.498 4.55254 134.494 4.82227C134.481 5.7521 134.507 6.68192 134.481 7.61175C134.469 7.98017 134.596 8.07447 134.951 8.0635C135.743 8.03938 136.539 8.0635 137.333 8.05034C137.621 8.05034 137.715 8.13148 137.71 8.42096C137.692 9.36833 137.692 10.3186 137.71 11.2718C137.71 11.5789 137.626 11.6841 137.307 11.6753C136.495 11.6556 135.681 11.6753 134.867 11.6644C134.587 11.6644 134.478 11.728 134.481 12.0262C134.481 14.2499 134.467 16.4758 134.496 18.6995C134.496 18.9463 134.546 19.1907 134.644 19.418C134.741 19.6452 134.884 19.8507 135.064 20.022C135.244 20.1934 135.457 20.3271 135.69 20.4153C135.923 20.5034 136.172 20.5441 136.421 20.535C137.701 20.535 137.701 20.535 137.701 21.8069C137.701 22.5175 137.688 23.2302 137.701 23.9407C137.701 24.2455 137.666 24.3793 137.293 24.3793C134.762 24.3617 132.231 24.3793 129.7 24.3617C127.899 24.342 126.551 22.9714 126.489 21.1819C126.489 21.1468 126.489 21.1096 126.489 21.0723L126.493 16.6995Z'
                fill='#EF7D00'
              />
              <path
                d='M30.7397 38.544C30.7397 41.9322 30.7397 45.3225 30.7508 48.7107C30.7508 49.123 30.6443 49.2216 30.234 49.2173C27.9449 49.1953 25.6528 49.1953 23.3577 49.2173C22.9296 49.2173 22.852 49.0945 22.852 48.6997C22.8653 41.8854 22.8653 35.0711 22.852 28.2567C22.852 27.8466 22.9562 27.748 23.3666 27.7502C25.6557 27.7721 27.9478 27.7721 30.2429 27.7502C30.671 27.7502 30.7508 27.8708 30.7486 28.2677C30.7331 31.6931 30.7397 35.1186 30.7397 38.544Z'
                fill='#205387'
              />
            </svg>
          </div>

          <div className='pt-8  md:w-[390px] w-[325px]'>
            <svg viewBox='0 0 391 240' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M19.6077 127.714C19.6077 128.57 18.913 129.259 18.0629 129.259H0.317581V126.167H18.0629C18.9156 126.167 19.6077 126.861 19.6077 127.712V127.714Z'
                fill='#EF7D00'
              />
              <path
                d='M19.6077 119.669C19.6077 120.524 18.913 121.213 18.0629 121.213H0.317581V118.121H18.0629C18.9156 118.121 19.6077 118.816 19.6077 119.666V119.669Z'
                fill='#EF7D00'
              />
              <path
                d='M88.2145 84.6653H86.2575C82.5352 84.6653 79.5103 87.6902 79.5103 91.4098V155.973C79.5103 159.693 82.5352 162.718 86.2575 162.718H88.2145C91.9341 162.718 94.959 159.693 94.959 155.973V91.4098C94.959 87.6902 91.9341 84.6653 88.2145 84.6653ZM91.8667 155.973C91.8667 157.987 90.2285 159.625 88.2145 159.625H86.2575C84.2408 159.625 82.6027 157.987 82.6027 155.973V91.4098C82.6027 89.3958 84.2408 87.7576 86.2575 87.7576H88.2145C90.2285 87.7576 91.8667 89.3958 91.8667 91.4098V155.973Z'
                fill='#EF7D00'
              />
              <path
                d='M54.992 92.2885C47.6409 92.2885 41.6454 98.1985 41.5107 105.518C41.5107 105.601 41.5107 105.687 41.5107 105.77V142.225C41.5107 149.659 47.5605 155.706 54.9946 155.706H82.6052V92.2885H54.992ZM79.5103 152.614H54.992C49.2635 152.614 44.6004 147.953 44.6004 142.225V105.77C44.6004 100.041 49.2635 95.3809 54.992 95.3809H79.5103V152.614Z'
                fill='#EF7D00'
              />
              <path
                d='M116.229 101.267H91.8667V112.805H116.229C119.356 112.805 121.896 110.265 121.896 107.138V106.934C121.896 103.81 119.356 101.267 116.229 101.267ZM118.803 107.138C118.803 108.559 117.65 109.712 116.229 109.712H94.959V104.36H116.229C117.65 104.36 118.803 105.516 118.803 106.934V107.138Z'
                fill='#EF7D00'
              />
              <path
                d='M116.229 134.581H91.8667V146.118H116.229C119.356 146.118 121.896 143.575 121.896 140.452V140.247C121.896 137.121 119.356 134.581 116.229 134.581ZM118.803 140.452C118.803 141.87 117.65 143.026 116.229 143.026H94.959V137.673H116.229C117.65 137.673 118.803 138.826 118.803 140.247V140.452Z'
                fill='#EF7D00'
              />
              <path
                d='M41.6636 105.516C41.6117 105.516 41.5625 105.516 41.5107 105.516C36.6479 105.599 32.7184 109.578 32.7184 114.458V132.919C32.7184 137.8 36.6479 141.781 41.5081 141.862C41.5599 141.862 41.6117 141.862 41.6636 141.862H44.6004V105.511H41.6636V105.516ZM41.5081 138.775C38.3509 138.692 35.8107 136.1 35.8107 132.924V114.464C35.8107 111.288 38.3535 108.694 41.5081 108.613V138.777V138.775Z'
                fill='#EF7D00'
              />
              <path
                d='M55.7644 136.776C54.9116 136.776 54.2195 136.084 54.2195 135.231V112.154C54.2195 111.301 54.9116 110.609 55.7644 110.609C56.6172 110.609 57.3093 111.301 57.3093 112.154V135.231C57.3093 136.084 56.6172 136.776 55.7644 136.776Z'
                fill='#EF7D00'
              />
              <path
                d='M65.6117 136.776C64.7589 136.776 64.0668 136.084 64.0668 135.231V112.154C64.0668 111.301 64.7589 110.609 65.6117 110.609C66.4645 110.609 67.1565 111.301 67.1565 112.154V135.231C67.1565 136.084 66.4645 136.776 65.6117 136.776Z'
                fill='#EF7D00'
              />
              <path
                d='M23.5114 114.811C20.9426 114.811 18.6772 116.13 17.3526 118.124C16.7487 119.033 16.3417 120.086 16.194 121.216C16.1499 121.537 16.1266 121.864 16.1266 122.193V125.187C16.1266 125.519 16.1499 125.845 16.194 126.164C16.3417 127.294 16.7513 128.347 17.3526 129.257C18.6746 131.25 20.9426 132.569 23.5114 132.569H35.7433V114.806H23.5114V114.811ZM32.651 129.482H23.5114C21.1448 129.482 19.2189 127.559 19.2189 125.19V122.196C19.2189 119.829 21.1422 117.903 23.5114 117.903H32.651V129.482Z'
                fill='#EF7D00'
              />
              <path
                d='M390.682 117.763V120.806H354.694C353.854 120.806 353.173 120.125 353.173 119.285C353.173 118.445 353.854 117.763 354.694 117.763H390.682Z'
                fill='#EF7D00'
              />
              <path
                d='M390.682 125.682V128.725H354.694C353.854 128.725 353.173 128.043 353.173 127.204C353.173 126.364 353.854 125.682 354.694 125.682H390.682Z'
                fill='#EF7D00'
              />
              <path
                d='M280.719 84.8286H278.793C275.133 84.8286 272.152 87.8069 272.152 91.4695V155.019C272.152 158.679 275.131 161.66 278.793 161.66H280.719C284.379 161.66 287.36 158.682 287.36 155.019V91.4695C287.36 87.8095 284.382 84.8286 280.719 84.8286ZM284.314 155.017C284.314 156.999 282.702 158.612 280.719 158.612H278.793C276.81 158.612 275.198 156.999 275.198 155.017V91.4669C275.198 89.484 276.81 87.8717 278.793 87.8717H280.719C282.702 87.8717 284.314 89.484 284.314 91.4669V155.017Z'
                fill='#EF7D00'
              />
              <path
                d='M285.839 120.205C284.999 120.205 284.317 119.523 284.317 118.684V127.805C284.317 126.963 284.999 126.283 285.839 126.283H287.36V120.205H285.839ZM285.839 120.205C284.999 120.205 284.317 119.523 284.317 118.684V127.805C284.317 126.963 284.999 126.283 285.839 126.283H287.36V120.205H285.839ZM285.839 120.205C284.999 120.205 284.317 119.523 284.317 118.684V127.805C284.317 126.963 284.999 126.283 285.839 126.283H287.36V120.205H285.839ZM285.839 120.205C284.999 120.205 284.317 119.523 284.317 118.684V127.805C284.317 126.963 284.999 126.283 285.839 126.283H287.36V120.205H285.839ZM318.343 91.7339H284.317V154.156H318.343C325.578 154.156 331.482 148.339 331.614 141.133C331.614 141.05 331.614 140.968 331.614 140.885V105.003C331.614 97.6852 325.66 91.7313 318.34 91.7313L318.343 91.7339ZM318.343 151.113H287.36V94.777H318.343C323.983 94.777 328.571 99.3649 328.571 105.003V140.885C328.571 146.525 323.981 151.11 318.343 151.11V151.113ZM285.839 120.205C284.999 120.205 284.317 119.523 284.317 118.684V127.805C284.317 126.963 284.999 126.283 285.839 126.283H287.36V120.205H285.839ZM285.839 120.205C284.999 120.205 284.317 119.523 284.317 118.684V127.805C284.317 126.963 284.999 126.283 285.839 126.283H287.36V120.205H285.839ZM285.839 120.205C284.999 120.205 284.317 119.523 284.317 118.684V127.805C284.317 126.963 284.999 126.283 285.839 126.283H287.36V120.205H285.839Z'
                fill='#EF7D00'
              />
              <path
                d='M331.617 105.355C331.568 105.355 331.516 105.355 331.464 105.355H328.571V141.136H331.464C331.513 141.136 331.565 141.136 331.614 141.136C336.399 141.056 340.269 137.139 340.269 132.333V114.163C340.269 109.357 336.402 105.441 331.617 105.36V105.355ZM337.226 132.328C337.226 135.454 334.725 138.007 331.617 138.088V108.398C334.725 108.479 337.226 111.032 337.226 114.158V132.328Z'
                fill='#EF7D00'
              />
              <path
                d='M307.031 129.327H285.839C284.999 129.327 284.317 128.645 284.317 127.805C284.317 126.965 284.999 126.283 285.839 126.283H307.031C307.871 126.283 308.553 126.965 308.553 127.805C308.553 128.645 307.871 129.327 307.031 129.327Z'
                fill='#EF7D00'
              />
              <path
                d='M307.031 120.205H285.839C284.999 120.205 284.317 119.523 284.317 118.684C284.317 117.844 284.999 117.162 285.839 117.162H307.031C307.871 117.162 308.553 117.844 308.553 118.684C308.553 119.523 307.871 120.205 307.031 120.205Z'
                fill='#EF7D00'
              />
              <path
                d='M356.532 120.806C356.387 119.694 355.985 118.658 355.391 117.763C354.09 115.801 351.858 114.503 349.331 114.503H337.291V131.986H349.331C351.858 131.986 354.09 130.687 355.391 128.725C355.985 127.828 356.387 126.794 356.532 125.682C356.576 125.366 356.597 125.044 356.597 124.718V121.771C356.597 121.444 356.576 121.123 356.532 120.806ZM349.329 128.943H340.332V117.546H349.329C351.659 117.546 353.554 119.44 353.554 121.771V124.718C353.554 127.048 351.659 128.943 349.329 128.943Z'
                fill='#EF7D00'
              />
              <path
                d='M191.003 35.6701C190.64 35.6701 190.272 35.5664 189.945 35.3487C189.072 34.7629 188.836 33.5809 189.422 32.7074L198.1 19.7393H191.005C190.303 19.7393 189.657 19.353 189.326 18.7335C188.994 18.114 189.033 17.3623 189.422 16.7765L200.08 0.845707C200.666 -0.0278185 201.848 -0.263696 202.721 0.32211C203.595 0.907916 203.831 2.0899 203.245 2.96342L194.569 15.9315H201.664C202.366 15.9315 203.012 16.3177 203.344 16.9372C203.675 17.5567 203.636 18.3084 203.245 18.8942L192.587 34.8251C192.218 35.3746 191.617 35.6701 191.003 35.6701Z'
                fill='#EF7D00'
              />
              <path
                d='M229.562 54.3458C228.982 54.3458 228.406 54.0814 228.033 53.5786C227.406 52.7336 227.582 51.5412 228.424 50.9139L240.947 41.6058L234.262 39.2263C233.601 38.9905 233.121 38.4098 233.018 37.7152C232.914 37.0205 233.202 36.3232 233.764 35.9059L249.148 24.4723C249.993 23.845 251.185 24.0213 251.813 24.8637C252.44 25.7087 252.264 26.9011 251.421 27.5283L238.899 36.8364L245.584 39.216C246.245 39.4518 246.724 40.0325 246.828 40.7271C246.932 41.4218 246.644 42.1191 246.082 42.5364L230.698 53.97C230.355 54.224 229.959 54.3458 229.562 54.3458Z'
                fill='#EF7D00'
              />
              <path
                d='M163.104 54.3459C162.71 54.3459 162.311 54.224 161.969 53.97L146.585 42.5364C146.02 42.1165 145.735 41.4218 145.839 40.7272C145.942 40.0325 146.422 39.4519 147.083 39.216L153.768 36.8365L141.246 27.5284C140.4 26.9011 140.227 25.7087 140.854 24.8637C141.481 24.0187 142.674 23.8451 143.519 24.4723L158.903 35.9059C159.468 36.3258 159.753 37.0205 159.649 37.7152C159.545 38.4099 159.066 38.9905 158.405 39.2264L151.72 41.6059L164.242 50.914C165.087 51.5413 165.261 52.7336 164.634 53.5786C164.26 54.0815 163.685 54.3459 163.104 54.3459Z'
                fill='#EF7D00'
              />
              <path
                d='M203.364 205.842C203.712 205.842 204.064 205.94 204.378 206.15C205.215 206.71 205.438 207.843 204.878 208.68L196.568 221.099H203.362C204.036 221.099 204.653 221.469 204.969 222.063C205.285 222.656 205.249 223.377 204.875 223.937L194.668 239.191C194.108 240.028 192.975 240.251 192.138 239.691C191.301 239.132 191.078 237.999 191.638 237.162L199.948 224.746H193.154C192.48 224.746 191.863 224.375 191.547 223.781C191.231 223.188 191.265 222.467 191.64 221.907L201.848 206.653C202.2 206.127 202.776 205.844 203.364 205.844V205.842Z'
                fill='#EF7D00'
              />
              <path
                d='M166.438 187.959C166.995 187.959 167.545 188.213 167.902 188.695C168.504 189.504 168.335 190.644 167.526 191.246L155.536 200.157L161.935 202.436C162.57 202.661 163.027 203.219 163.128 203.882C163.226 204.548 162.951 205.214 162.412 205.616L147.682 216.565C146.873 217.166 145.732 216.998 145.131 216.189C144.53 215.381 144.698 214.24 145.507 213.639L157.498 204.727L151.098 202.449C150.463 202.223 150.007 201.666 149.906 201.002C149.807 200.336 150.082 199.67 150.621 199.268L165.352 188.319C165.678 188.076 166.059 187.959 166.438 187.959Z'
                fill='#EF7D00'
              />
              <path
                d='M230.081 187.959C230.459 187.959 230.84 188.076 231.167 188.319L245.897 199.268C246.437 199.67 246.711 200.336 246.613 201.002C246.514 201.668 246.056 202.223 245.421 202.449L239.021 204.727L251.012 213.639C251.82 214.24 251.989 215.38 251.387 216.189C250.786 216.998 249.646 217.166 248.837 216.565L234.106 205.616C233.567 205.214 233.292 204.548 233.391 203.882C233.489 203.216 233.948 202.661 234.583 202.436L240.983 200.157L228.992 191.246C228.183 190.644 228.015 189.504 228.616 188.695C228.974 188.213 229.523 187.959 230.081 187.959Z'
                fill='#EF7D00'
              />
              <path
                d='M196.278 175.932C227.589 175.932 252.971 150.549 252.971 119.238C252.971 87.9273 227.589 62.5447 196.278 62.5447C164.967 62.5447 139.584 87.9273 139.584 119.238C139.584 150.549 164.967 175.932 196.278 175.932Z'
                fill='#EF7D00'
              />
              <path
                d='M196.278 171.256C225.006 171.256 248.295 147.967 248.295 119.238C248.295 90.5096 225.006 67.2206 196.278 67.2206C167.549 67.2206 144.26 90.5096 144.26 119.238C144.26 147.967 167.549 171.256 196.278 171.256Z'
                fill='#EF7D00'
              />
              <path
                d='M196.278 158.106C190.497 158.106 185.795 153.404 185.795 147.624C185.795 141.844 190.497 137.142 196.278 137.142C202.058 137.142 206.76 141.844 206.76 147.624C206.76 153.404 202.058 158.106 196.278 158.106Z'
                fill='white'
              />
              <path
                d='M196.278 141.82C193.076 141.82 190.474 144.425 190.474 147.624C190.474 150.823 193.079 153.428 196.278 153.428C199.476 153.428 202.081 150.823 202.081 147.624C202.081 144.425 199.476 141.82 196.278 141.82Z'
                fill='white'
              />
              <path
                d='M196.278 132.743C191.42 132.743 187.306 129.106 186.708 124.285L183.113 95.2641C182.646 91.4927 183.82 87.6953 186.337 84.8492C188.854 82.0005 192.478 80.3676 196.278 80.3676C200.078 80.3676 203.701 82.0005 206.218 84.8492C208.735 87.6979 209.909 91.4927 209.443 95.2641L205.847 124.285C205.249 129.106 201.135 132.743 196.278 132.743Z'
                fill='white'
              />
              <path
                d='M196.278 85.0463C193.818 85.0463 191.472 86.1038 189.842 87.9468C188.211 89.7897 187.452 92.2496 187.755 94.6913L191.35 123.712C191.659 126.195 193.776 128.067 196.278 128.067C198.779 128.067 200.897 126.195 201.205 123.712L204.8 94.6913C205.104 92.2496 204.342 89.7923 202.714 87.9468C201.083 86.1038 198.738 85.0463 196.278 85.0463Z'
                fill='white'
              />
            </svg>
          </div>

          <div className='flex flex-col md:ml-[40px]'>
            <span className='font-display font-400 md:text-display-h1 text-display-h1-m text-N-800 pt-8 px-4 sm:px-0'>
              OOPS! SOMETHING WENT WRONG
            </span>

            <span className='font-500 text-base text-N-600 pt-2 px-4 sm:px-0 md:w-[552px]'>
              Sorry, it appears that something is preventing our website from working as it should.
              <br />
              <br />
              Rest assured, our team is aware and working to take care of it.
              <br />
              <br />
              In the meantime, you may want to try:
              <br />
              <br />
              <ul className='list-disc pl-6'>
                <li>Reload the browser window to refresh the page</li>

                <li>
                  Take a break and grab yourself a tea or coffee and come back and try again in 30
                  minutes.
                </li>
              </ul>
              <br />
              If you require immediate help please contact our Customer Care team on{' '}
              <a href={`tel:'0800 660 660'`} className='text-O-500 underline'>
                0800 660 660
              </a>
            </span>
          </div>
        </div>
      )
    }

    // Return children components in case of no error
    return this.props.children
  }
}

export default ErrorBoundary
