import { FC } from 'react'
import { TextField } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { useFormikContext } from 'formik'

export interface AddressBlockProps {
  city: string
  className?: string
  hasError?: boolean
  hideSearchField?: boolean
  field: string
  onSearchFieldClick: () => void
  placeholder?: string
  postcode: string
  required?: boolean
  searchingAddress: string
  streetAddress: string
  suburb: string
}

export const AddressBlock: FC<AddressBlockProps> = ({
  city,
  className,
  hasError,
  hideSearchField,
  field,
  onSearchFieldClick,
  placeholder,
  postcode,
  required,
  searchingAddress,
  streetAddress,
  suburb
}: AddressBlockProps) => {
  const { handleBlur } = useFormikContext()
  return (
    <div className={CN('flex flex-col gap-[20px] w-full', className)}>
      {!hideSearchField && (
        <TextField
          autoComplete='off'
          componentId={`${field}-searchingAddress`}
          hasError={hasError}
          iconAfter='nzsbi-search'
          name={field}
          label='Search Address'
          onBlur={handleBlur}
          onClick={onSearchFieldClick}
          onClickIconAfter={onSearchFieldClick}
          placeholder={placeholder}
          required={required}
          value={searchingAddress}
        />
      )}

      <TextField
        label='Street Address'
        disabled
        componentId={`${field}-streetAddress`}
        id={`${field}-streetAddress`}
        value={streetAddress}
      />
      <TextField
        componentId={`${field}-suburb`}
        disabled
        id={`${field}-suburb`}
        label='Suburb'
        value={suburb}
      />
      <div className='grid grid-cols-2 gap-[32px] w-full'>
        <TextField
          componentId={`${field}-city`}
          disabled
          id={`${field}-city`}
          label='City'
          value={city}
        />
        <TextField
          componentId={`${field}-postcode`}
          disabled
          id={`${field}-postcode`}
          label='Postcode'
          value={postcode}
        />
      </div>
    </div>
  )
}

export default AddressBlock
