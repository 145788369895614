import React, { FC } from 'react'
import CN from 'classnames'

import { NextImage, OctagonWrapper } from 'components/atoms'

export interface CompanyProfileWhatWeDoSectionProps {
  className?: string
  componentId?: string
  sectionDetails?: {
    Header?: string
    SubHeader?: string
    Cards?: Array<{
      Description?: string
      CardImage: {
        data: {
          id: string
          attributes: {
            name: string
            alternativeText: string | null
            url: string
            provider_metadata: {
              public_id: string
              resource_type: string
            }
          }
        }
      }
      Icon?: string
      id?: string
      Header?: string
      SubHeader?: string
    }>
  }
}

export const CompanyProfileWhatWeDoSection: FC<CompanyProfileWhatWeDoSectionProps> = ({
  className,
  componentId,
  sectionDetails,
  ...restProps
}: CompanyProfileWhatWeDoSectionProps) => {
  const CompanyProfileWhatWeDoSectionClasses = CN(`w-full border-t-[2px] border-B-500`, className)

  return (
    <div className={CompanyProfileWhatWeDoSectionClasses} {...restProps}>
      <div className='pt-7 lg:pt-4'>
        {/** Company Profile What We Do Section Title */}
        {sectionDetails?.Header && (
          <p
            data-component-id={`${componentId}-title`}
            className='font-700 text-h2-m md:!text-h2 lg:text-h2 text-B-500'>
            {sectionDetails?.Header}
          </p>
        )}

        {/** Company Profile What We Do Section Sub Title */}
        {sectionDetails?.SubHeader && (
          <p
            data-component-id={`${componentId}-sub-title`}
            className='font-700 md:!font-600 text-h3-m md:text-h3 text-N-800'>
            {sectionDetails?.SubHeader}
          </p>
        )}

        {/** Company Profile What We Do Section Content */}
        <div data-component-id={`${componentId}-content`} className='flex flex-col gap-y-4 pt-8'>
          {sectionDetails?.Cards &&
            sectionDetails?.Cards?.map((item, index) => (
              <div
                data-component-id={`${componentId}-content-item-${index}`}
                className='flex flex-col md:flex-row gap-4'
                key={`${item?.CardImage?.data?.attributes?.url}-item`}>
                {/** Image For Company Profile What We Do Section Content In Desktop And Tab View*/}
                {item?.CardImage?.data?.attributes?.url && (
                  <div
                    data-component-id={`${componentId}-content-item-${index}-desktop-image`}
                    className='relative h-[260px] w-[260px]  hidden md:block'>
                    <NextImage
                      nextImgStyle={{ objectFit: 'fit' }}
                      data-component-id={`${componentId}-image-section-img`}
                      imgKey={item?.CardImage?.data?.id ?? ''}
                      imageUrl={
                        item?.CardImage?.data?.attributes?.provider_metadata?.public_id ?? ''
                      }
                      system='CMS'
                      useWithDimensions={true}
                      alt={''}
                      width='260'
                      height='260'
                      crop='fill'
                    />
                  </div>
                )}

                {/** Image For Company Profile What We Do Section Content In Mobile View*/}
                {item?.CardImage?.data?.attributes?.url && (
                  <div
                    data-component-id={`${componentId}-content-item-${index}-mobile-image`}
                    className='relative h-[200px] w-full block md:hidden'>
                    <NextImage
                      data-component-id={`${componentId}-image-section-img`}
                      imgKey={item?.CardImage?.data?.id ?? ''}
                      imageUrl={
                        item?.CardImage?.data?.attributes?.provider_metadata?.public_id ?? ''
                      }
                      system='CMS'
                      useWithDimensions={false}
                      alt={''}
                    />
                  </div>
                )}

                <div
                  data-component-id={`${componentId}-content-item-${index}-detail-section`}
                  className='flex flex-col gap-y-6'>
                  <div className='flex gap-x-3'>
                    {/** Icon For Company Profile What We Do Section Content */}
                    {item?.Icon && (
                      <span>
                        <OctagonWrapper
                          componentId={`${componentId}-content-item-${index}-detail-section`}
                          icon={item?.Icon}
                          className='flex items-center justify-center'
                          wrapperBackgroundColor='#FCE8D1'
                          wrapperBorderColor='#FCE8D1'
                          iconColor='text-O-500'
                        />
                      </span>
                    )}

                    {/** Header And Sub Header For Company Profile What We Do Section Content */}
                    <div className='flex flex-col'>
                      {item?.Header && (
                        <p
                          data-component-id={`${componentId}-content-item-${index}-detail-section-header`}
                          className='font-700 text-h2-m md:!text-h2 lg:text-h2 text-O-500'>
                          {item?.Header}
                        </p>
                      )}

                      {item?.SubHeader && (
                        <div
                          data-component-id={`${componentId}-content-item-${index}-detail-section-sub-header`}
                          className='font-700 text-base text-B-500 uppercase'>
                          {item?.SubHeader}
                        </div>
                      )}
                    </div>
                  </div>

                  {/** Description For Company Profile What We Do Section Content */}
                  {item?.Description && (
                    <div
                      data-component-id={`${componentId}-content-item-${index}-detail-section-description`}
                      className={CN('font-400 text-base text-N-700 break-all max-w-full', {
                        'w-full md:!w-[388px] lg:!w-[570px]':
                          item?.CardImage?.data?.attributes?.url,
                        'w-full': !item?.CardImage?.data?.attributes?.url
                      })}>
                      <div
                        data-component-id='content-html-seo-description'
                        dangerouslySetInnerHTML={{
                          __html: item?.Description
                        }}
                        className='prose prose-blue-bold mark-down max-w-none'
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default CompanyProfileWhatWeDoSection
