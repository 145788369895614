import React, { FC } from 'react'
import { Modal, ModalBody, ModalHeader } from '@nzsb/shopnx-ui'
import { useModalContext } from 'lib/contexts/modal-context'

import { Login } from 'components/forms/Login'

export interface ISignInModalProps {
  className?: string
}

export const SignInModal: FC<ISignInModalProps> = ({
  className,
  ...restProps
}: ISignInModalProps) => {
  const { loginModal } = useModalContext()

  return (
    <Modal
      componentId='sign-in'
      isOpen={loginModal.isOpen}
      onClickOverlay={loginModal.close}
      onClose={loginModal.close}
      className='w-[444px] flex-shrink-0'
      {...restProps}>
      <ModalHeader
        componentId='sign-in'
        onClose={loginModal.close}
        heading={null}
        hasBorder={false}
      />
      <ModalBody componentId='sign-in' className='!pt-[0] !pb-[20px] cursor-auto'>
        <Login />
      </ModalBody>
    </Modal>
  )
}

export default SignInModal
