import * as Yup from 'yup'

export const forgetPasswordSchema = Yup.object().shape({
  email: Yup.string(),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password should contain a minimum of 8 characters')
    .matches(/^(?=.*[a-z])/, 'Password should have at least one character')
    .matches(/^(?=.*[A-Z])/, 'Password should have at least one upper case character')
    .matches(/^(?=.*[0-9])/, 'Password should have at least one numeric value')
    .matches(
      /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/,
      'Password should have at least one special character'
    ),
  confirmPassword: Yup.string().required('Confirm Password is required')
})
