import React, { FC } from 'react'
import { Button } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { useAddNewAddress, useDeleteAddress, useUpdateAddress } from 'lib/actions'
import { useModalContext } from 'lib/contexts/modal-context'
import { handleResponseToast } from 'lib/utilities'

import { AddressCard, AddressProps } from 'components/molecules/AddressCard'
export interface ShippingAddressSectionProps {
  className?: string
  componentId?: string
  data: AddressProps[]
}

export const ShippingAddressSection: FC<ShippingAddressSectionProps> = ({
  className,
  componentId,
  data,
  ...restProps
}: ShippingAddressSectionProps) => {
  const ShippingAddressSectionClasses = CN(
    `${componentId}-address-section flex flex-col gap-y-8`,
    className
  )

  const { searchAddressModal } = useModalContext()
  const {
    close,
    open,
    setAddressDetails,
    setPrimaryBtnLoading,
    setPrimaryBtnText,
    setSaveFn,
    setIsUpdateAccountDetails,
    setTitle
  } = searchAddressModal

  const resetForm = () => {
    setAddressDetails({
      attentionTo: '',
      city: '',
      isDefaultAddress: false,
      postcode: '',
      searchingAddress: '',
      showAttentionTo: false,
      showMakeDefaultAddress: false,
      streetAddress: '',
      suburb: ''
    })
    setPrimaryBtnText(undefined)
    setSaveFn(() => () => {})
    setPrimaryBtnLoading(false)
    setTitle('')
  }

  const { addNewAddressAsync } = useAddNewAddress()
  const { updateAddressAsync } = useUpdateAddress()
  const { mutate: deleteAddressAsync } = useDeleteAddress()

  const deleteAddress = (id: string) => {
    deleteAddressAsync({ id })
  }

  const addNewAddress = async (address: any) => {
    setAddressDetails({
      ...address,
      showMakeDefaultAddress: true,
      showAttentionTo: true
    })
    setIsUpdateAccountDetails(true)
    setPrimaryBtnLoading(true)
    setTitle('Create Shipping Address')

    const response = await addNewAddressAsync({
      streetAddress: address.streetAddress,
      suburb: address.suburb,
      city: address.city,
      postcode: address.postcode,
      attentionTo: address.attentionTo,
      isDefaultAddress: address.isDefaultAddress
    })

    if (response.messageType === 'success') {
      close()
      setIsUpdateAccountDetails(false)
      resetForm()
    } else {
      handleResponseToast(response)
    }
  }

  const updateAddress = async (address: any, id: string) => {
    setAddressDetails({
      ...address,
      showMakeDefaultAddress: true,
      showAttentionTo: true
    })
    setIsUpdateAccountDetails(true)
    setPrimaryBtnLoading(true)

    const response = await updateAddressAsync({
      id: id,
      streetAddress: address.streetAddress,
      suburb: address.suburb,
      city: address.city,
      postcode: address.postcode,
      attentionTo: address.attentionTo,
      isDefaultAddress: address.isDefaultAddress
    })

    if (response.messageType === 'success') {
      close()
      setIsUpdateAccountDetails(false)
      resetForm()
    } else {
      handleResponseToast(response)
    }
  }

  const handleEditAddressClick = (addr: any) => {
    setAddressDetails({
      ...addr,
      showMakeDefaultAddress: true,
      showAttentionTo: true,
      isUpdateAccountDetails: true,
      postcode: addr.postCode,
      isDefaultAddress: addr.isDefault
    })
    setTitle('Edit Shipping Address')
    setPrimaryBtnText('Save')
    setIsUpdateAccountDetails(true)
    setSaveFn(() => (add: AddressProps) => {
      updateAddress(add, addr.id)
    })
    open()
  }

  const handleCreateAddressClick = () => {
    setAddressDetails({
      showMakeDefaultAddress: true,
      showAttentionTo: true,
      attentionTo: '',
      city: '',
      isDefaultAddress: false,
      postcode: '',
      searchingAddress: '',
      streetAddress: '',
      suburb: ''
    })
    setTitle('Create Shipping Address')
    setPrimaryBtnText('Create Shipping Address')
    setIsUpdateAccountDetails(true)
    setPrimaryBtnLoading(false)
    setSaveFn(() => (add: AddressProps) => {
      addNewAddress(add)
    })
    open()
  }

  return (
    <div className={ShippingAddressSectionClasses} {...restProps}>
      <div className='flex flex-col gap-1'>
        <h3 className='font-600 text-h3'>Shipping Address</h3>
        <span className='text-N-500 font-400'>
          You can save up to 6 shipping addresses for your convenience
        </span>
      </div>
      {data?.length > 0 && (
        <div className='grid lg:grid-cols-2 gap-5 grid-cols-1'>
          {data.map((address: AddressProps) => (
            <AddressCard
              key={address.id}
              componentId={`${componentId}+${address.id}`}
              data={address}
              onRemoveClick={() => {
                deleteAddress(address.id)
              }}
              onEditClick={() => handleEditAddressClick(address)}
            />
          ))}
        </div>
      )}

      <Button
        size='md'
        disabled={data?.length >= 6}
        appearance='secondary-orange'
        iconBefore='nzsbi-plus'
        componentId='update-details-create-shipping-address'
        className='w-fit'
        onClick={handleCreateAddressClick}>
        Create Shipping Address
      </Button>
    </div>
  )
}

export default ShippingAddressSection
