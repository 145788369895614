import Link from 'next/link'
import React, { FC } from 'react'
import { Button } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { IComponentMoleculesServicesMultiImageBlock } from 'lib/actions'
import { checkURL } from 'lib/utilities'

import { NextImage } from 'components/atoms'

export interface MultiImageBlockProps {
  className?: string
  componentId?: string
  data: IComponentMoleculesServicesMultiImageBlock
}

export const MultiImageBlock: FC<MultiImageBlockProps> = ({
  className,
  componentId,
  data,
  ...restProps
}: MultiImageBlockProps) => {
  const MultiImageBlockClasses = CN(
    `w-full flex flex-col px-4 py-6 md:!px-6 rounded bg-B-25`,
    className
  )

  const {
    originalUrl: primaryButtonOriginalUrl,
    restUrlWithQuery: primaryButtonQuery,
    isInsideUrl: primaryButtonUrlIsInternal
  } = checkURL(data?.PrimaryAction?.Url ?? '')

  const {
    originalUrl: secondaryButtonOriginalUrl,
    restUrlWithQuery: secondaryButtonQuery,
    isInsideUrl: secondaryButtonUrlIsInternal
  } = checkURL(data?.SecondaryAction?.Url ?? '')

  return (
    <div
      data-component-id={`${componentId}-section`}
      className={MultiImageBlockClasses}
      {...restProps}>
      {data?.Header && <div className='text-h3 font-600 text-B-500'>{data?.Header}</div>}
      {data?.Images?.data && (
        <div className='w-full grid grid-cols-3 gap-6 md:!gap-5 lg:!gap-6 pt-4'>
          {data?.Images?.data?.slice(0, 3)?.map((image, index) => (
            <div
              key={`${image?.attributes?.provider_metadata?.public_id ?? index}-service-multi-image`}
              className='flex justify-center items-center rounded h-[80px] md:!h-[172px] relative'>
              <NextImage
                nextImgStyle={{ objectFit: 'cover' }}
                data-component-id={`${componentId}-brand-home-image`}
                imgKey={
                  image?.attributes?.alternativeText ??
                  image?.attributes?.provider_metadata?.public_id
                }
                imageUrl={image?.attributes?.provider_metadata?.public_id}
                system='CMS'
                useWithDimensions={false}
                alt={image?.attributes?.alternativeText ?? image?.attributes?.name}
                {...restProps}
              />
            </div>
          ))}
        </div>
      )}
      {(data?.SecondaryAction?.ButtonText || data?.PrimaryAction?.ButtonText) && (
        <div
          className={CN('w-full grid gap-4 pt-8 md:!pt-6 lg:!pt-8', {
            'grid-cols-2': data?.PrimaryAction?.ButtonText && data?.SecondaryAction?.ButtonText,
            'grid-cols-1': !data?.PrimaryAction?.ButtonText || !data?.SecondaryAction?.ButtonText
          })}>
          {data?.SecondaryAction?.ButtonText && (
            <Link
              href={
                secondaryButtonUrlIsInternal
                  ? secondaryButtonQuery
                  : secondaryButtonOriginalUrl ?? {}
              }
              passHref
              target={secondaryButtonUrlIsInternal ? '' : '_blank'}
              className={CN({
                'cursor-pointer': secondaryButtonOriginalUrl,
                'cursor-auto pointer-events-none': !secondaryButtonOriginalUrl
              })}>
              <div className='flex justify-center'>
                <Button
                  appearance='secondary-orange'
                  className='w-full'
                  size='md'
                  iconBefore={data?.SecondaryAction?.IconBefore}
                  iconAfter={data?.SecondaryAction?.IconAfter}>
                  {data?.SecondaryAction?.ButtonText}
                </Button>
              </div>
            </Link>
          )}
          {data?.PrimaryAction?.ButtonText && (
            <Link
              href={
                primaryButtonUrlIsInternal ? primaryButtonQuery : primaryButtonOriginalUrl ?? {}
              }
              passHref
              target={primaryButtonUrlIsInternal ? '' : '_blank'}
              className={CN({
                'cursor-pointer': primaryButtonOriginalUrl,
                'cursor-auto pointer-events-none': !primaryButtonOriginalUrl
              })}>
              <div className='flex justify-center'>
                <Button
                  appearance='primary-orange'
                  className='w-full'
                  size='md'
                  iconBefore={data?.PrimaryAction?.IconBefore}
                  iconAfter={data?.PrimaryAction?.IconAfter}>
                  {data?.PrimaryAction?.ButtonText}
                </Button>
              </div>
            </Link>
          )}
        </div>
      )}
    </div>
  )
}

export default MultiImageBlock
