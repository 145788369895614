import { useMutation } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

export interface IRequestItemsForMyCatalogueItemsResponse {
  messageTitle: string | null
  messageDescription: string | null
  messageType: string
  messageCode: string
  data: any
}

export interface IRequestItemsForMyCatalogueItemsReq {
  productId?: number
  comment?: string
}

export const addRequestItems = async (req: IRequestItemsForMyCatalogueItemsReq) => {
  const { data } = await httpPOST({
    url: `account/add_product_to_mycatalogue`,
    body: req
  })

  return data as IRequestItemsForMyCatalogueItemsResponse
}

export const useAddRequestItemsForMyCatalogue = () => {
  const { data, mutateAsync, ...restOptions } = useMutation({
    mutationFn: addRequestItems
  })

  return {
    addRequestItemsForMyCatalogueResponse: data,
    addRequestItemsForMyCatalogueAsync: mutateAsync,
    ...restOptions
  }
}
