import NextNProgress from 'nextjs-progressbar'
import type { AppProps } from 'next/app'
import React, { useState } from 'react'
import { ToastContainer } from '@nzsb/shopnx-ui'
import { HydrationBoundary, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { AppWrapper } from 'lib/contexts/app-context'
import { ModalWrapper } from 'lib/contexts/modal-context'
import { SideBarWrapper } from 'lib/contexts/sideBar-context'
import { layoutTypes, topProgressBarProps } from 'lib/static/globalStaticData'
import DatadogInit from 'lib/utilities/dataDogInit'

import ErrorBoundary from 'components/error/ErrorBoundary'
import { GlobalLayout } from 'components/layouts/GlobalLayout'
import { MinimalLayout } from 'components/layouts/MinimalLayout'

import 'remixicon/fonts/remixicon.css'
/** Inner Styles */
import 'styles/common/tailwind.scss'
import 'styles/common/global.scss'
import '@nzsb/shopnx-ui/dist/esm/src/styles/index.scss'

export default function App({ Component, pageProps }: AppProps) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false
          }
        }
      })
  )
  const { layoutType, pageTitle } = pageProps

  const version = process.env.NEXT_PUBLIC_SEMANTIC_RELEASE_VERSION || `0.0.0`
  const date = process.env.NEXT_PUBLIC_BUILD_DATE_TIME || `05 June 2024 12.15`
  console.log(`app loading... Release >>> ${version} - ${date}`)

  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <HydrationBoundary state={pageProps.dehydratedState}>
          <AppWrapper>
            <SideBarWrapper>
              <ModalWrapper>
                <DatadogInit />
                <NextNProgress {...topProgressBarProps} />
                {layoutType === layoutTypes.none && <Component {...pageProps} />}
                {layoutType === layoutTypes.minimal && (
                  <MinimalLayout pageTitle={pageTitle}>
                    <Component {...pageProps} />
                    <ToastContainer />
                  </MinimalLayout>
                )}
                {layoutType === layoutTypes.global ||
                  (!layoutType && (
                    <GlobalLayout>
                      <Component {...pageProps} />
                      <ToastContainer />
                    </GlobalLayout>
                  ))}
              </ModalWrapper>
            </SideBarWrapper>
          </AppWrapper>
        </HydrationBoundary>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ErrorBoundary>
  )
}
