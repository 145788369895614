import React, { FC } from 'react'
import { Select } from '@nzsb/shopnx-ui'
import CN from 'classnames'

export interface OptionProps {
  label: string
  value: string
}

export interface SortBySelectorProps {
  className?: string
  componentId?: string
  onChange: any
  option: OptionProps[]
  value?: any
  disabled?: boolean
}

export const SortBySelector: FC<SortBySelectorProps> = ({
  className,
  componentId,
  onChange,
  option,
  value,
  disabled,
  ...restProps
}: SortBySelectorProps) => {
  const SortBySelectorClasses = CN(`${componentId}-sort-by flex min-w-[166px] items-end`, className)

  return (
    <div className={SortBySelectorClasses} {...restProps}>
      <Select
        className='w-full !focus-within:border-R-400 text-N-600 font-medium z-40'
        label='Sort By'
        onChange={(val: any) => onChange(val)}
        placeholder='Relevance'
        data={option}
        size='sm'
        defaultValue={option[0]}
        isClearable={false}
        isSearchable={false}
        value={value}
        isDisabled={disabled}
      />
    </div>
  )
}

export default SortBySelector
