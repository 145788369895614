import { useMutation } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

import { useGetCart } from './useGetCart'

export interface ISaveCartItemsResponse {
  messageTitle: string | null
  messageDescription: string | null
  messageType: string
  data: any
}

export interface ISaveCartItemsReq {
  cartId?: string
  name?: string
}

export const saveCartItems = async (req: ISaveCartItemsReq) => {
  const { data } = await httpPOST({
    url: `orderpad/create`,
    body: req
  })

  return data as ISaveCartItemsResponse
}

export const useSaveCartItems = () => {
  const { refetch: fetchGetCart } = useGetCart()

  const { data, mutateAsync, ...restOptions } = useMutation({
    mutationFn: async (req: ISaveCartItemsReq) => {
      const saveCartData = await saveCartItems(req)
      await fetchGetCart()
      return saveCartData
    }
  })

  return { saveCartItemsResponse: data, saveCartItemsAsync: mutateAsync, ...restOptions }
}
