import Link from 'next/link'
import { FC, ReactNode } from 'react'
import { Button } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { checkURL } from 'lib/utilities'

import { NextImage } from 'components/atoms'

export interface PopularProductCategoriesProps {
  actionButton: { children: ReactNode; url?: string }
  categories: {
    id: string
    DisplayText: string
    URL: string
    Thumbnail: {
      data: {
        id: string
        attributes: {
          provider_metadata: {
            public_id: string
            resource_type: string
          }
          provider: string
        }
      }
    }
  }[]
  className?: string
  componentId?: string
  header: string
  [x: string]: any
}

export const PopularProductCategories: FC<PopularProductCategoriesProps> = ({
  actionButton,
  categories,
  className,
  componentId,
  header,
  ...restProps
}: PopularProductCategoriesProps) => {
  const PopularProductCategoriesClasses = CN(
    `${componentId} flex flex-col gap-[24px] lg:gap-[32px]`,
    className
  )

  const {
    originalUrl: actionOriginalUrl,
    restUrlWithQuery: actionRestUrlWithQuery,
    isInsideUrl: actionIsInsideUrl
  } = checkURL(actionButton?.url ?? '')

  return (
    <div className={PopularProductCategoriesClasses} {...restProps}>
      {/** Popular Product Categories Header Section */}
      <div
        data-component-id={`${componentId}-header`}
        className='flex flex-col lg:!flex-row lg:justify-between'>
        <span
          data-component-id={`${componentId}-header-title`}
          className='font-600 text-h3 text-N-800'>
          {header}
        </span>

        {actionButton?.children && (
          <Link
            href={actionIsInsideUrl ? actionRestUrlWithQuery : actionOriginalUrl ?? {}}
            passHref
            target={actionIsInsideUrl ? '' : '_blank'}>
            <Button
              appearance='link'
              className='w-fit pt-[8px] lg:pt-0'
              componentId={`${componentId}-header-action-btn`}
              iconAfter='nzsbi-chevron-right'
              isRingOffset={false}
              size='md'
              {...actionButton}>
              {actionButton?.children}
            </Button>
          </Link>
        )}
      </div>

      {/** Popular Product Category Cards Section */}
      <div className='grid grid-cols-2 auto-rows-[1fr] sm:grid-cols-3 lg:grid-cols-6 gap-[16px] sm:gap-[20px] lg:gap-[32px]'>
        {categories.map(item => {
          const { originalUrl, restUrlWithQuery, isInsideUrl } = checkURL(item?.URL ?? '')

          return (
            <Link
              className={CN({
                'pointer-events-none': !originalUrl,
                'pointer-events-auto': originalUrl
              })}
              href={isInsideUrl ? restUrlWithQuery : originalUrl ?? {}}
              key={item?.id}
              passHref
              target={isInsideUrl ? '' : '_blank'}>
              <div className='flex flex-col gap-[16px] w-full h-full p-[15px] bg-white rounded border border-[#D9D9D5] shadow'>
                <div className='flex justify-center items-start relative w-full'>
                  <NextImage
                    alt={item?.Thumbnail?.data?.attributes?.provider_metadata?.public_id ?? ''}
                    className='object-cover !h-[116px]'
                    data-component-id={`${componentId}-category-banner-image`}
                    height={116}
                    imageUrl={item?.Thumbnail?.data?.attributes?.provider_metadata?.public_id}
                    imgKey={`${item?.Thumbnail?.data?.id ?? ''}`}
                    system='CMS'
                    useWithDimensions
                    width={155}
                    {...restProps}
                  />
                </div>

                <span className='text-base font-500 text-N-700 text-center line-clamp-2'>
                  {item?.DisplayText}
                </span>
              </div>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default PopularProductCategories
