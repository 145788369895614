import router from 'next/router'
import React, { FC } from 'react'
import { Button } from '@nzsb/shopnx-ui'

import { NextImage } from 'components/atoms'

export const ComingSoon: FC = () => {
  const onClickGoBack = () => {
    router.back()
  }

  const onClickBackToHome = () => {
    router.push('/')
  }

  const onClickFeedBack = () => {
    router.push('/feedback')
  }

  return (
    <div className='flex flex-col items-center justify-center'>
      <div className='relative flex flex-col w-[245px] h-[200px]'>
        <NextImage
          data-component-id='coming-soon-image'
          imgKey='img-busy-coding_fqzre8'
          system='General'
          imageUrl='img-busy-coding_fqzre8.png'
          useWithDimensions={false}
          alt='Busy Coding'
        />
      </div>

      <h1 className='font-display font-400 text-center text-display-h1-m mt-[32px] text-N-800 uppercase'>
        We Hope You are enjoying our new website
      </h1>

      <p className='font-sans font-500 text-center text-base mt-[8px] text-N-500'>
        Sorry, we are currently building this page to bring you an improved experience.
      </p>

      <div className='w-full flex flex-col md:!flex-row pt-[20px] gap-[16px] md:!w-fit'>
        <Button
          componentId='coming-soon-go-back'
          onClick={onClickGoBack}
          className='w-full md:!w-[150px] text-center'
          size='md'
          appearance='secondary-orange'>
          GO BACK
        </Button>

        <Button
          componentId='coming-soon-home-page'
          onClick={onClickBackToHome}
          className='w-full md:!w-[150px] text-center'
          size='md'
          appearance='secondary-orange'>
          HOME PAGE
        </Button>

        <Button
          componentId='coming-soon-feedback'
          onClick={onClickFeedBack}
          className='w-full md:!w-[150px] text-center'
          size='md'
          appearance='secondary-orange'>
          FEEDBACK
        </Button>
      </div>
    </div>
  )
}

export default ComingSoon
