import { useRouter } from 'next/router'
import React, { FC, useEffect, useState } from 'react'
import { Drawer } from '@nzsb/shopnx-ui'

import { PerPageItemCount } from 'components/molecules/PerPageItemCount'
import { SortBySelector } from 'components/molecules/SortBySelector'
import { SideBarFilters } from 'components/organisms/SideBar/SideBarFilters'

export interface CategoryMinoraItemFilterDrawerProps {
  brandIds?: string[]
  categoryIds?: string[]
  className?: string
  closeCategoryMinorItemFilterDrawer: () => void
  colourIds?: string[]
  componentId?: string
  expandedCategoryList?: string[]
  filterData?: any
  isOpenCategoryMinorItemFilterDrawer?: boolean
  key?: number
  maxPrice?: number
  minPrice?: number
  queryData?: any
  returnPageIndex?: number
  returnPageSize?: number
  returnResultCount?: number
  selectedCategoryIds?: string[]
  setExpandedCategoryList?: any
  setSelectedCategoryIds?: any
  sort: string
  sortType: string
}

export const CategoryMinorItemFilterDrawer: FC<CategoryMinoraItemFilterDrawerProps> = ({
  brandIds,
  categoryIds,
  className,
  closeCategoryMinorItemFilterDrawer,
  colourIds,
  componentId,
  expandedCategoryList,
  filterData,
  isOpenCategoryMinorItemFilterDrawer,
  key,
  maxPrice,
  minPrice,
  queryData,
  returnPageIndex,
  returnPageSize = 0,
  returnResultCount,
  selectedCategoryIds,
  setExpandedCategoryList,
  setSelectedCategoryIds,
  sort,
  sortType,
  ...restProps
}: CategoryMinoraItemFilterDrawerProps) => {
  const router = useRouter()

  /** Sort Function dropdown Options */
  const sortOptions = [
    { label: 'Relevance', value: 'relevanceAsc', sort: 'relevance', sortType: 'asc' },
    { label: 'Price: Low - High', value: 'priceAsc', sort: 'price', sortType: 'asc' },
    { label: 'Price: High - Low', value: 'priceDesc', sort: 'price', sortType: 'desc' },
    { label: 'Name A - Z', value: 'nameAsc', sort: 'name', sortType: 'asc' }
  ]

  /** Use State for Collect the selected Sorting value without any delay */
  const [selectedSortOption, setSelectedSortOption] = useState(
    sortOptions?.find(
      option => option?.value === `${sort}${sortType?.charAt(0).toUpperCase() + sortType?.slice(1)}`
    )
  )

  /** SortBy Onchange Function */
  const onSortBySelectorChange = (value: any) => {
    setSelectedSortOption(
      sortOptions.find(option => option.value === `${value.sort}${value.sortType}`)
    )
    router.push({
      pathname: router.pathname,
      query: {
        ...router.query,
        sort: value?.sort,
        sortType: value?.sortType
      }
    })
  }

  /** Item Per Page Count function */
  const onPageItemCountButtonClick = (value: number) => {
    router.push({
      pathname: router.pathname,
      query: {
        ...router.query,
        pageSize: value,
        page: 1
      }
    })
  }

  const resetFilters = () => {
    const { q } = router.query
    closeCategoryMinorItemFilterDrawer()
    router.push(
      {
        pathname: router.pathname,
        query: { q: q?.toString() }
      },
      undefined,
      { scroll: false }
    )
  }

  /** Once Query Data is changed, select back the changed data in to the select box */
  useEffect(() => {
    setSelectedSortOption(
      sortOptions.find(
        option => option.value === `${sort}${sortType.charAt(0).toUpperCase() + sortType.slice(1)}`
      )
    )

    setSelectedCategoryIds(categoryIds)
  }, [queryData])

  return (
    <div {...restProps}>
      <Drawer
        componentId='category-minor-item-filter-drawer'
        onOverlayClick={closeCategoryMinorItemFilterDrawer}
        headerButtonProps={{ onClick: closeCategoryMinorItemFilterDrawer }}
        headerTitle='Filter'
        show={isOpenCategoryMinorItemFilterDrawer}
        primaryButtonTitle='APPLY'
        primaryButtonProps={{ onClick: closeCategoryMinorItemFilterDrawer, size: 'md' }}
        secondaryButtonTitle='CANCEL'
        secondaryButtonProps={{
          onClick: resetFilters,
          size: 'md',
          appearance: 'secondary-gray'
        }}>
        <div className='flex flex-col p-[24px] w-full gap-[24px]'>
          <SortBySelector
            componentId='category-minor-item-search'
            onChange={onSortBySelectorChange}
            option={sortOptions}
            value={selectedSortOption}
            disabled={returnResultCount === 0}
          />
          <PerPageItemCount
            componentId='category-minor-item-per-page-item-count'
            enableBlockButton
            activePageSize={returnPageSize}
            totalCount={returnResultCount}
            pageIndex={returnPageIndex}
            buttonValues={[30, 60, 90]}
            onclick={onPageItemCountButtonClick}
            className='flex'
          />
          <SideBarFilters
            isMobile
            filterData={filterData}
            selectedCategoryIds={selectedCategoryIds}
            setSelectedCategoryIds={setSelectedCategoryIds}
            setExpandedCategoryList={setExpandedCategoryList}
            expandedCategoryList={expandedCategoryList}
            minPrice={minPrice}
            maxPrice={maxPrice}
            brandIds={brandIds || []}
            colourIds={colourIds || []}
            priceKey={key}
            returnResultCount={returnResultCount}
          />
        </div>
      </Drawer>
    </div>
  )
}

export default CategoryMinorItemFilterDrawer
