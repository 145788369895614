export const checkDateDiff = (dateString: string, noOfDays: number) => {
  if (dateString === null || dateString === undefined) {
    return true
  }

  const isMoreThanMonth =
    Math.ceil(
      Math.abs(new Date(dateString).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)
    ) > noOfDays

  return isMoreThanMonth
}

export default checkDateDiff
