import React, { FC, ReactNode } from 'react'
import { Button, ButtonProps } from '@nzsb/shopnx-ui'
import CN from 'classnames'

export interface IOnScreenMessageProps {
  actionButtonProps?: ButtonProps
  className?: string
  description?: string
  header?: string
  subHeader?: string
  type?: 'success' | 'error' | 'warning' | 'info'
  variant?: 1 | 2
  children?: ReactNode | ReactNode[]
}

export const OnScreenMessage: FC<IOnScreenMessageProps> = ({
  actionButtonProps,
  className,
  description,
  header,
  subHeader,
  type,
  variant = 1,
  children
}: IOnScreenMessageProps) => {
  return (
    <>
      {variant === 1 && (
        <div className={CN('flex flex-col gap-y-[24px]', className)}>
          <div className='flex item-center md:gap-x-[12px] gap-x-[8px]'>
            <i
              className={CN('flex items-center  md:text-h1 text-h2-m', {
                'nzsbi-error-1 text-R-500':
                  type === 'error' || type === 'info' || type === 'warning',
                'nzsbi-check-1 text-G-500': type === 'success'
              })}
            />
            <p className='text-N-700 md:text-display-h2 text-display-h2-m font-display uppercase'>
              {header}
            </p>
          </div>
          <div className='flex flex-col gap-y-[8px]'>
            {subHeader && <p className='text-N-500 text-base font-700'>{subHeader}</p>}
            {children}
            {description && <div className='text-base text-N-700 font-400'>{description}</div>}
            {actionButtonProps && (
              <div className='flex flex-col mt-[12px]'>
                <Button {...actionButtonProps} isRingOffset={false} />
              </div>
            )}
          </div>
        </div>
      )}
      {variant === 2 && (
        <div className={CN('flex flex-col items-center gap-y-[24px] font-heading', className)}>
          <div className='flex flex-col items-center gap-y-[12px]'>
            <i
              className={CN('flex items-center  text-[64px]', {
                'nzsbi-error-1 text-R-500':
                  type === 'error' || type === 'info' || type === 'warning',
                'nzsbi-check-1 text-G-500': type === 'success'
              })}
            />
            <div className='flex flex-col items-center'>
              <p className='text-N-700 md:text-[33px] md:leading-[44px] text-display-h2-m tracking-[-0.66px] font-700'>
                {header}
              </p>
              {description && (
                <div className='text-base text-center text-N-700 font-400'>{description}</div>
              )}
            </div>
          </div>
          {actionButtonProps && (
            <div className='flex flex-col mt-[12px]'>
              <Button {...actionButtonProps} isRingOffset={false} />
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default OnScreenMessage
