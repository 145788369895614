/* eslint-disable */
export const BREADCRUMB_DATA = [
  {
    id: 0,
    label: 'Home',
    onClick: () => console.log('Home')
  },
  {
    id: 2,
    label: 'Products',
    onClick: () => console.log('/products')
  },
  {
    id: 2,
    label: 'Power tools',
    onClick: () => console.log('/products/power-tools')
  },
  {
    id: 3,
    label: 'Screwdrivers',
    onClick: () => console.log('/products/power-tools/screwdrivers')
  },
  {
    id: 4,
    label: 'Philips screwdriver',
    onClick: () =>
      console.log('/products/power-tools/screwdrivers/philips-screwdriver')
  },
  {
    id: 5,
    label: 'PH000',
    onClick: () =>
      console.log(
        '/products/power-tools/screwdrivers/philips-screwdriver/ph000'
      )
  },
  {
    id: 6,
    label: 'PH000X1000',
    onClick: () =>
      console.log(
        '/products/power-tools/screwdrivers/philips-screwdriver/ph000/ph000x1000'
      )
  }
]

export const PAGINATION_DATA = [
  {
    id: 1,
    value: 1
  },
  {
    id: 2,
    value: 2
  },
  {
    id: 3,
    value: 3
  },
  {
    id: 4,
    value: 4,
    truncated: true
  },
  {
    id: 5,
    value: 5
  },
  {
    id: 6,
    value: 6
  },
  {
    id: 7,
    value: 7,
    truncated: true
  },
  {
    id: 8,
    value: 8
  },
  {
    id: 9,
    value: 9
  }
]

export const PAGE_COUNT_SELECTOR_DATA = [
  {
    value: "10",
    label: "10"
  },
  {
    value: "30",
    label: "30"
  },
  {
    value: "50",
    label: "50"
  },
  {
    value: "100",
    label: "100"
  },
  {
    value: "300",
    label: "300"
  }
]

export const TABS_DATA = [
  {
    id: 1,
    title: 'Tab item',
    content:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
    icon: 'nzsbi-calendar'
  },
  {
    id: 2,
    title: 'Tab item',
    content:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    badge: {
      children: 23,
      appearance: 'neutral',
      view: 'subtle'
    }
  },
  {
    id: 3,
    title: 'Tab item',
    content:
      'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour'
  },
  {
    id: 4,
    title: 'Tab item',
    content:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
    icon: 'nzsbi-calendar',
    badge: {
      children: 23,
      appearance: 'neutral',
      view: 'subtle'
    }
  }
]

export const FOOTER_APPS_DATA = [
  {
    id: 1,
    url: '/footer/android-app-download.svg',
    alt: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
  },
  {
    id: 2,
    url: '/footer/apple-app-download.svg',
    alt: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
  }
]

export const FOOTER_SOCIAL_ICONS_DATA = [
  {
    id: 5,
    alt: 'Linkedin',
    link: 'https://nz.linkedin.com/company/nz-safety-blackwoods?original_referer=',
    target: '_blank',
    icon: 'nzsbi-linkedin'
  },
  {
    id: 3,
    alt: 'Instagram',
    link: 'https://www.instagram.com/nzsb_online/',
    target: '_blank',
    icon: 'nzsbi-instagram'
  },
  {
    id: 1,
    alt: 'Facebook',
    link: 'https://www.facebook.com/nzsafetyblackwoods',
    target: '_blank',
    icon: 'nzsbi-facebook'
  },
]

export const AUTHENTICATED_FOOTER_ACCOUNT_LIST =  [
  {
    id: 1,
    label: 'My Account',
    link: 'https://www.google.com',
  },
  {
    id: 2,
    label: 'Req. New Credit Amount',
    link: 'https://www.google.com',
  }
]

export const FOOTER_LINKS_BLOCKS_DATA = [
  { 
    id: 1,
    heading: 'Shop With Us',
    navList: [
      {
        id: 1,
        label: 'Products',
        link: 'https://www.google.com',
      },
      {
        id: 2,
        label: 'Services',
        link: 'services',
      },
      {
        id: 3,
        label: 'Store Locator',
        link: 'store-locator',
      },
      {
        id: 4,
        label: 'EDI Solutions',
        link: 'https://www.google.com',
      },
    ]
  },
  { 
    id: 2,
    heading: 'About Us',
    navList: [
      {
        id: 1,
        label: 'Company Profile',
        link: 'https://www.google.com',
      },
      {
        id: 2,
        label: 'Careers',
        link: 'https://www.google.com',
      },
      {
        id: 3,
        label: 'Community',
        link: 'https://www.google.com',
      },
      {
        id: 4,
        label: 'Sustainability',
        link: 'https://www.google.com',
      },
      {
        id: 5,
        label: 'Whistleblower Policy',
        link: 'https://www.google.com',
      },
      {
        id: 6,
        label: 'Lastest News',
        link: 'https://www.google.com',
      }
    ]
  },
  { 
    id: 3,
    heading: 'Account',
    navList: [
      {
        id: 1,
        label: 'My Account',
        link: 'https://www.google.com',
      },
      {
        id: 2,
        label: 'Req. New Credit Amount',
        link: 'https://www.google.com',
      }
    ]
  },
  { 
    id: 4,
    heading: 'Help & Support',
    navList: [
      {
        id: 1,
        label: 'Order Tracking',
        link: 'https://www.google.com',
      },
      {
        id: 2,
        label: 'Delivery',
        link: 'https://www.google.com',
      },
      {
        id: 3,
        label: 'Order Changes',
        link: 'https://www.google.com',
      },
      {
        id: 4,
        label: 'Exchanges & Returns',
        link: 'https://www.google.com',
      },
      {
        id: 5,
        label: 'Product Queries',
        link: 'https://www.google.com',
      },
      {
        id: 6,
        label: 'Payments & Accounts',
        link: 'https://www.google.com',
      },
      {
        id: 7,
        label: 'Using Our Website',
        link: 'https://www.google.com',
      }
    ]
  }
]


export default BREADCRUMB_DATA
