import Link from 'next/link'
import React, { FC } from 'react'
import CN from 'classnames'
import { ICategoryInformation } from 'lib/actions'

import { NextImage } from 'components/atoms'

export interface CategoryCardListProps {
  [x: string]: any
  className?: string
  componentId?: string
  header?: string
  categories: ICategoryInformation[]
}

export const CategoryCardList: FC<CategoryCardListProps> = ({
  className,
  componentId,
  header,
  categories,
  ...restProps
}: CategoryCardListProps) => {
  const CategoryCardListClasses = CN(
    `${componentId}-categories-card-list gap-[24px] flex flex-col`,
    className
  )

  return (
    <div className={CategoryCardListClasses} {...restProps}>
      {header && (
        <h2
          data-component-id='popular-product-category-header'
          className='font-700 text-h3-m text-N-700 lg:!text-h3 lg:!font-600'>
          {header}
        </h2>
      )}

      <div className='grid grid-cols-2 sm:grid-cols-4 gap-x-[16px] sm:gap-x-[20px] lg:gap-x-[32px] gap-y-4 md:gap-y-0'>
        {categories?.map(item => {
          return (
            <Link passHref href={`${item.urlSlug}`} key={item?.id}>
              <div className='flex flex-col justify-center items-center gap-[16px] bg-white border border-N-100 rounded shadow-sm p-[16px] h-full w-full'>
                <div className='relative w-full h-[80px]'>
                  <NextImage
                    data-component-id={`${componentId}-category-banner-image`}
                    imgKey={`${item?.image?.id ?? ''}`}
                    system='Backend'
                    imageUrl={item?.image?.fileName}
                    useWithDimensions={false}
                    alt={item?.image?.altAttribute ?? ''}
                    {...restProps}
                  />
                </div>
                <span className='text-base font-500 text-N-700 text-center'>{item?.name}</span>
              </div>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default CategoryCardList
