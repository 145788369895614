type IRole = 'PUNCH_OUT_USER' | 'NON_TRADE' | 'FULL_TRADE'

export enum UserRoles {
  PUNCH_OUT_USER = 'PUNCH_OUT_USER',
  NON_TRADE = 'NON_TRADE',
  FULL_TRADE = 'FULL_TRADE'
}

type IRoles = IRole[] | undefined

export const checkRoleBasedProperties = (roles: IRoles) => {
  /**
   * Show GST price primary
   * The roles are
   * 1. PUNCH_OUT_USER
   * 2. FULL_TRADE
   */
  const showExcludeGSTPrimary = roles?.includes('PUNCH_OUT_USER') || roles?.includes('FULL_TRADE')
  /** Show availability status for customers having role which means any user having a role
   * 1. PUNCH_OUT_USER
   * 2. NON_TRADE
   * 3. FULL_TRADE
   *
   * So we just check for existence of any role
   */
  const isAvailabilityAndPickupLocationShow = roles && roles?.length > 0

  /**
   * Show shipping charges for the following roles
   * 1. PUNCH_OUT_USER
   */
  const showShippingCharges = !roles?.includes('PUNCH_OUT_USER')
  return {
    showGstPricePrimary: !showExcludeGSTPrimary,
    isAvailabilityAndPickupLocationShow,
    showShippingCharges
  }
}

export default checkRoleBasedProperties
