import React, { FC } from 'react'
import CN from 'classnames'

import { AboutUsPageNavigationCard } from './AboutUsPageNavigationCard'

export interface IAboutUsPageCard {
  id?: string
  title?: string
  description?: string
  imgUrl?: string
  icon?: string
  url?: string
  links: Array<{
    id: string
    Header: string
  }>
}

export interface IAboutUsPageNavigationListProps {
  cards?: Array<IAboutUsPageCard>
  componentId?: string
  className?: string
  sectionHeader?: string
}

export const AboutUsPageNavigationList: FC<IAboutUsPageNavigationListProps> = ({
  cards,
  className,
  componentId,
  sectionHeader,
  ...restProps
}: IAboutUsPageNavigationListProps) => {
  const AboutUsPageNavigationListClasses = CN(`w-full`, className)

  return (
    <div
      data-component-id={`${componentId}-section`}
      className={AboutUsPageNavigationListClasses}
      {...restProps}>
      {/** About Us Page Navigation Section Header */}
      {sectionHeader && (
        <span
          data-component-id={`${componentId}-section-header`}
          className='font-700 text-h2-m md:text-h2 text-N-700'>
          {sectionHeader}
        </span>
      )}

      {/** About Us Page Navigation Card List Section */}
      {cards && cards?.length > 0 && (
        <div
          data-component-id={`${componentId}-card-list`}
          className='grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-6'>
          {cards?.map((item: IAboutUsPageCard, index: number) => (
            <AboutUsPageNavigationCard
              componentId={`${componentId}-card-${index}`}
              className='w-full'
              key={item?.id}
              imgKey={item?.id ?? ''}
              linkList={item?.links}
              description={item?.description ?? ''}
              imgUrl={item?.imgUrl ?? ''}
              title={item?.title ?? ''}
              icon={item?.icon ?? ''}
              url={item?.url ?? ''}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default AboutUsPageNavigationList
