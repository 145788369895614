import { useMutation } from '@tanstack/react-query'
import { httpGET } from 'lib/http'

export interface IDownloadQuoteReq {
  orderPadId?: string
}

export const downloadQuote = async (orderPadId?: string) => {
  const data = await httpGET({
    url: `orderpad/${orderPadId}/download`
  })

  return data?.data
}

export const useGetDownloadQuote = () => {
  return useMutation({
    mutationFn: downloadQuote
  })
}
