import { useQuery } from '@tanstack/react-query'
import { httpGET } from 'lib/http'

export interface IGetSizeGuideByProductIdResponse {
  messageTitle?: string
  messageDescription?: string
  messageType?: string
  messageCode?: string
  data: {
    description: string
    id: string
    isActive: boolean
    link: string
    linkType: string
    productId: string
  }
}

/* API Request */
export const getSizeGuideByProductId = async (id?: number | string) => {
  if (id) {
    const { data: response } = await httpGET({
      url: `/product/getproductsizeguide/${id}`
    })
    return response as IGetSizeGuideByProductIdResponse
  }

  return null
}

/* Client side react-query custom hook */
export const useGetSizeGuideByProductId = (id?: number | string) => {
  return useQuery({
    queryKey: ['product-size-chart', id],
    queryFn: () => getSizeGuideByProductId(id)
  })
}
