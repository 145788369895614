import React, { FC, useState } from 'react'
import { Accordion } from '@nzsb/shopnx-ui'
import CN from 'classnames'
export interface ProductDescriptionCardProps {
  className?: string
  componentId?: string
  content?: string
  features?: any
}

export const ProductDescriptionCard: FC<ProductDescriptionCardProps> = ({
  className,
  componentId,
  content,
  features,
  ...restProps
}: ProductDescriptionCardProps) => {
  const ProductDescriptionCardClasses = CN(
    `${componentId}-product-description rounded-[4px]`,
    className
  )

  const [expandedProductDescription, setExpandedProductDescription] = useState<false | number>(0)

  return (
    <div className={ProductDescriptionCardClasses} {...restProps}>
      <Accordion
        className='w-full !border-t-0 !border-b-0'
        headerClasses='!normal-case !font-500 !text-base !text-B-500'
        isArrowIcons={false}
        setExpanded={setExpandedProductDescription}
        expanded={expandedProductDescription}
        item={{
          id: 0,
          content: (
            <div data-component-id={`${componentId}-content-body`} className='flex flex-col'>
              <span
                data-component-id={`${componentId}-content`}
                className='font-400 text-base text-N-700'>
                {content}
              </span>

              <ul
                data-component-id={`${componentId}-features`}
                className='list-disc py-4 pl-6 font-400 text-base text-N-700'>
                {features?.map((item: any) => (
                  <li data-component-id={`${componentId}-features-${item?.id}`} key={item?.id}>
                    {item?.feature}
                  </li>
                ))}
              </ul>
            </div>
          ),
          title: 'PRODUCT DESCRIPTION'
        }}
        variant='blue'
      />
    </div>
  )
}

export default ProductDescriptionCard
