import * as Yup from 'yup'

// const passwordRegex = /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%!*?^&+=]).*$/
const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%!*?^&+=]).{8,}$/

export const ChangePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required(`Current password can't be empty.`),
  newPassword: Yup.string()
    .required(`New password can't be empty.`)
    .matches(passwordRegex, 'Enter a stronger password.'),
  confirmedPassword: Yup.string()
    .required(`Confirm password can't be empty.`)
    .oneOf([Yup.ref('newPassword')], 'New password and confirm password must match.')
})
