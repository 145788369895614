import Link from 'next/link'
import React, { FC } from 'react'
import { Button } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { IComponentMoleculesServicesTextWithImageBlock } from 'lib/actions'
import { checkURL } from 'lib/utilities'

import { NextImage } from 'components/atoms'

export interface TextWithImageBlockProps {
  blockData?: IComponentMoleculesServicesTextWithImageBlock
  className?: string
  componentId?: string
  id?: string
  isPromotion?: boolean
}

export const TextWithImageBlock: FC<TextWithImageBlockProps> = ({
  blockData,
  className,
  componentId,
  isPromotion = false,
  ...restProps
}: TextWithImageBlockProps) => {
  /** Checking for Primary Button */
  const {
    originalUrl: primaryButtonOriginalUrl,
    restUrlWithQuery: primaryButtonQuery,
    isInsideUrl: primaryButtonUrlIsInternal
  } = checkURL(blockData?.PrimaryAction?.Url ?? '')

  /** Checking for Secondary Button */
  const {
    originalUrl: secondaryButtonOriginalUrl,
    restUrlWithQuery: secondaryButtonQuery,
    isInsideUrl: secondaryButtonUrlIsInternal
  } = checkURL(blockData?.SecondaryAction?.Url ?? '')

  /** Checking for Image */
  const {
    originalUrl: imageOriginalUrl,
    restUrlWithQuery: imageQuery,
    isInsideUrl: imageIsInternal
  } = checkURL(blockData?.Image?.data?.attributes?.url ?? '')

  const TextWithImageBlockClasses = CN(
    'flex flex-col rounded py-6 px-4 sm:px-6 w-full',
    { 'bg-B-25': blockData?.UseBlueBackground },
    className
  )

  return (
    <div className={TextWithImageBlockClasses} {...restProps}>
      {/** Text With Image Block Header */}
      {blockData?.Header && (
        <span
          data-component-id={`${componentId}-header`}
          className={CN('font-600 text-h3', { 'text-B-500': !isPromotion })}>
          {blockData?.Header}
        </span>
      )}

      {/** Text With Image Block Sub Header */}
      {blockData?.SubHeader && (
        <span
          data-component-id={`${componentId}-sub-header`}
          className='font-700 text-base text-N-800'>
          {blockData?.SubHeader}
        </span>
      )}

      {/** Text With Image Block Content */}
      <div
        className={CN({
          'sm:block flex': blockData?.IsImageLeft,
          'sm:block flex-col-reverse': !blockData?.IsImageLeft
        })}>
        <div
          className={CN('flex justify-between pt-4 gap-x-8', {
            'flex-col-reverse sm:flex-row-reverse': blockData?.IsImageLeft,
            'sm:flex-row flex-col-reverse': !blockData?.IsImageLeft
          })}>
          <div
            className={CN('flex flex-col justify-between', {
              'sm:w-1/2': blockData?.Image,
              'w-full': !blockData?.Image?.data?.attributes?.provider_metadata
            })}>
            {/** Text With Image Block Content Description */}
            {blockData?.Description && (
              <div
                data-component-id='content-html-seo-description'
                dangerouslySetInnerHTML={{ __html: blockData?.Description }}
                className='prose mark-down max-w-none pt-8 sm:pt-0'
              />
            )}

            {(blockData?.PrimaryAction?.ButtonText || blockData?.SecondaryAction?.ButtonText) && (
              <div className='flex gap-x-4'>
                {/** Text With Image Block Content Secondary Button */}
                {blockData?.SecondaryAction?.ButtonText && (
                  <Link
                    href={
                      secondaryButtonUrlIsInternal
                        ? secondaryButtonQuery
                        : secondaryButtonOriginalUrl ?? {}
                    }
                    data-component-id={`${componentId}-secondary-link-button-desktop-and-tab-view`}
                    passHref
                    target={secondaryButtonUrlIsInternal ? '' : '_blank'}
                    className={CN({
                      'cursor-pointer': secondaryButtonOriginalUrl,
                      'cursor-auto pointer-events-none': !secondaryButtonOriginalUrl,
                      'w-1/2': blockData?.PrimaryAction?.ButtonText,
                      'w-full': !blockData?.PrimaryAction?.ButtonText
                    })}>
                    <Button
                      appearance='secondary-orange'
                      isRingOffset={false}
                      componentId={`${componentId}-secondary-button-desktop-and-tab-view`}
                      className='mt-6'
                      isBlock
                      size='md'
                      iconAfter={blockData?.SecondaryAction?.IconAfter ?? ''}
                      iconBefore={blockData?.SecondaryAction?.IconBefore ?? ''}>
                      {blockData?.SecondaryAction?.ButtonText}
                    </Button>
                  </Link>
                )}

                {/** Text With Image Block Content Primary Button */}
                {blockData?.PrimaryAction?.ButtonText && (
                  <Link
                    href={
                      primaryButtonUrlIsInternal
                        ? primaryButtonQuery
                        : primaryButtonOriginalUrl ?? {}
                    }
                    data-component-id={`${componentId}-primary-link-button-desktop-and-tab-view`}
                    passHref
                    target={primaryButtonUrlIsInternal ? '' : '_blank'}
                    className={CN({
                      'cursor-pointer': primaryButtonOriginalUrl,
                      'cursor-auto pointer-events-none': !primaryButtonOriginalUrl,
                      'w-1/2': blockData?.SecondaryAction?.ButtonText,
                      'w-full': !blockData?.SecondaryAction?.ButtonText
                    })}>
                    <Button
                      appearance='primary-orange'
                      isRingOffset={false}
                      componentId={`${componentId}-primary-button-desktop-and-tab-view`}
                      className='mt-6'
                      isBlock
                      size='md'
                      iconAfter={blockData?.PrimaryAction?.IconAfter ?? ''}
                      iconBefore={blockData?.PrimaryAction?.IconBefore ?? ''}>
                      {blockData?.PrimaryAction?.ButtonText}
                    </Button>
                  </Link>
                )}
              </div>
            )}
          </div>

          {/** Text With Image Block Content Image*/}
          {blockData?.Image?.data?.attributes?.provider_metadata && (
            <div className='flex justify-center items-center sm:w-1/2'>
              <Link
                href={imageIsInternal ? imageQuery : imageOriginalUrl ?? {}}
                data-component-id={`${componentId}-content-image-link-desktop-and-tab-view`}
                passHref
                aria-label='text-with-image-block-image'
                target={imageIsInternal ? '' : '_blank'}
                className={CN('w-full', {
                  'cursor-pointer': imageOriginalUrl,
                  'cursor-auto pointer-events-none': !imageOriginalUrl
                })}>
                <NextImage
                  nextImgStyle={{
                    objectFit: 'fit'
                  }}
                  className='!w-full !h-fit'
                  data-component-id={`${componentId}-content-image-desktop-and-tab-view`}
                  imgKey={blockData?.Image?.data?.id ?? ''}
                  imageUrl={blockData?.Image?.data?.attributes?.provider_metadata?.public_id ?? ''}
                  system='CMS'
                  useWithDimensions={true}
                  width={952}
                  height={132}
                  alt={blockData?.Image?.data?.attributes?.alternativeText ?? ''}
                  {...restProps}
                />
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TextWithImageBlock
