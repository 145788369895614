import { useMutation } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

export const retryPayment = async (orderId: string) => {
  const response = await httpPOST({
    url: `orders/${orderId}/retry-payment`
  })
  return response?.data
}

/* Client side react-query custom hook */
export const useRetryPayment = () => {
  return useMutation({
    mutationFn: (orderId: string) => retryPayment(orderId)
  })
}
