import { useQuery } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

export const getCMSCategoryPopularBrands = async (categorySlug: string) => {
  if (categorySlug) {
    const response = await httpPOST({
      endpoint: 'cms',
      url: 'graphql',
      body: JSON.stringify({
        query: `query {
            categoryInformation: categoryPillars (
              filters: { 
                CategorySlug: {eq: "${categorySlug}"}
              }
            ){
              data {
      id
      attributes {
        CategoryTemplate {
          ... on ComponentMoleculesCategoryBrandBlock {
            __typename
            CategoryBrandBlockHeader: Header
            Brands(pagination: { limit: 6 }) {
              data {
                id
                attributes {
                  BrandID
                  BrandName
                  Brand
                  LinkToSearchCuration
                  SearchCurationURL
                  BrandLogo {
                    data {
                      id
                      attributes {
                        name
                        alternativeText
                        url
                        provider_metadata
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
            }
          }`
      })
    })

    return response?.data?.categoryInformation?.data[0]?.attributes?.CategoryTemplate[0] ?? null
  }
  return null
}

export const useGetCMSCategoryPopularBrands = (categorySlug: string) => {
  const { ...restOptions } = useQuery({
    queryKey: [`GET_ALL_CATEGORY_BRANDS_${categorySlug}`],
    queryFn: () => getCMSCategoryPopularBrands(categorySlug)
  })

  return { ...restOptions }
}
