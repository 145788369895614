import Link from 'next/link'
import React, { FC } from 'react'
import CN from 'classnames'

export interface IMinimalFooterProps {
  className?: string
}

export const MinimalFooter: FC<IMinimalFooterProps> = ({
  className,
  ...restProps
}: IMinimalFooterProps) => {
  const MinimalFooterClasses = CN(
    `footer py-[26px] lg:px-[80px] bg-white flex flex-col lg:!flex-row items-center lg:!e justify-between gap-y-[8px] text-sm text-N-700 font-500 text-center`,
    className
  )

  return (
    <footer className={MinimalFooterClasses} {...restProps}>
      <div>
        <p>© NZ Safety Blackwoods 2024</p>
      </div>
      <div className='flex flex-col item-center justify-center items-center gap-y-[8px] sm:!gap-x-[20px] sm:!flex-row sm:!justify-between underline'>
        <Link href='/terms/privacy-policy' className='hover:text-O-500'>
          Privacy Policy
        </Link>
        <Link href='/terms/terms-and-conditions' className='hover:text-O-500'>
          Terms and Conditions
        </Link>
        <Link href='/terms/disclaimer' className='hover:text-O-500'>
          Disclaimer
        </Link>
        <Link href='/account' className='hover:text-O-500'>
          Need help completing this form?
        </Link>
      </div>
    </footer>
  )
}
export default MinimalFooter
