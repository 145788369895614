import { FC } from 'react'
import CN from 'classnames'

export interface SEOBlockProps {
  className?: string
  componentId?: string
  contentHtml: string
}

export const SEOBlock: FC<SEOBlockProps> = ({
  className,
  componentId,
  contentHtml
}: SEOBlockProps) => {
  const SEOBlockClasses = CN(
    `${componentId} container flex flex-col w-full justify-center items-center`,
    className
  )

  return (
    <section className={SEOBlockClasses}>
      <div
        data-component-id='content-html-disclaimer'
        dangerouslySetInnerHTML={{ __html: contentHtml }}
        className='prose prose-break-link mark-down max-w-none lg:max-w-[840px]'
      />
    </section>
  )
}

export default SEOBlock
