import { useMutation } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

export interface IResetPasswordTokenInfo {
  id?: string | string[]
  token?: string | string[]
}

export interface IResetPasswordTokenResponse {
  data: any
  messageDescription: null | string
  messageTitle: string
  messageType: string
}

const resetPasswordTokenValidation = async ({ id, token }: IResetPasswordTokenInfo) => {
  try {
    const response = await httpPOST({
      endpoint: 'identity',
      url: 'auth/validateresetpasswordtoken',
      body: {
        id,
        token
      }
    })
    return response?.data
  } catch (error) {
    return { error }
  }
}

/* Client side react-query custom hook */
export const useResetPasswordTokenValidation = () => {
  const { mutateAsync, ...restOptions } = useMutation({
    mutationFn: resetPasswordTokenValidation
  })

  // Passing mutateAsync as forget password
  // and restOptions as rest of the options
  return { resetPasswordTokenValidateAsync: mutateAsync, ...restOptions }
}
