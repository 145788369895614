import Link from 'next/link'
import React, { FC, useState } from 'react'
import { Accordion } from '@nzsb/shopnx-ui'
import CN from 'classnames'

export interface ProductResourcesProps {
  className?: string
  componentId?: string
  resources?: Array<{
    id: string
    link: string
    description: string
    linkType: string
  }>
}

export const ProductResources: FC<ProductResourcesProps> = ({
  className,
  componentId,
  resources,
  ...restProps
}: ProductResourcesProps) => {
  const ProductResourcesClasses = CN(`${componentId}-product-resources rounded-[4px]`, className)

  const [expandedProductResources, setExpandedProductResources] = useState<false | number>(0)

  return (
    <div className={ProductResourcesClasses} {...restProps}>
      <Accordion
        className='w-full !border-t-0 !border-b-0'
        headerClasses='!normal-case !font-500 !text-base !text-B-500'
        isArrowIcons={false}
        setExpanded={setExpandedProductResources}
        expanded={expandedProductResources}
        item={{
          id: 0,
          content: (
            <div
              data-component-id={`${componentId}-content-body`}
              className='flex flex-col gap-y-2 p-2'>
              {resources &&
                resources.length > 0 &&
                resources?.map((item: any) => (
                  <div
                    data-component-id={`${componentId}-content-body-${item?.id}`}
                    key={item?.id}
                    className='flex gap-x-4'>
                    <div
                      data-component-id={`${componentId}-content-body-${item?.id}-icon`}
                      className='flex justify-center items-center w-[32px] h-[32px] bg-white rounded-lg'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'>
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M5 3.78895C5 3.35323 5.35323 3 5.78895 3H12.5509C12.7759 3 12.9901 3.09602 13.1398 3.26392L17.9444 8.65287C18.0733 8.7974 18.1445 8.98427 18.1445 9.1779V19.211C18.1445 19.6468 17.7913 20 17.3555 20H5.78895C5.35323 20 5 19.6468 5 19.211V3.78895ZM6.57791 4.57791V18.4221H16.5666V9.96685H12.5509C12.1152 9.96685 11.762 9.61363 11.762 9.1779V4.57791H6.57791ZM13.3399 5.85941V8.38895H15.5951L13.3399 5.85941Z'
                          fill='#3D3D3C'
                        />
                      </svg>
                    </div>

                    <Link
                      href={item?.link}
                      target='_blank'
                      className='flex items-center font-500 text-base text-N-700 text-left underline underline-offset-2'>
                      {item?.description}
                    </Link>
                  </div>
                ))}
            </div>
          ),
          title: 'RESOURCES'
        }}
        variant='blue'
      />
    </div>
  )
}

export default ProductResources
