import { useQuery } from '@tanstack/react-query'
import { httpGET } from 'lib/http'

export interface IGetMinimumDispatchesCountResponse {
  messageTitle: string | null
  messageDescription: string | null
  messageType: string
  data: IGetMinimumDispatchesCount
}

export interface IGetMinimumDispatchesCount {
  minimunDispatches?: number
}

export const getMinimumDispatchesCount = async (cartId?: any, context?: any) => {
  if (cartId) {
    const data = await httpGET({
      url: `cart/getminimundispatches/${cartId}`,
      context
    })
    return data?.data as IGetMinimumDispatchesCountResponse
  } else {
    return null
  }
}

export const useGetMinimumDispatchesCount = () => {
  return useQuery({
    queryKey: ['GET_MINIMUM_DISPATCHES_COUNT'],
    queryFn: getMinimumDispatchesCount
  })
}
