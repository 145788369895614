import { noUat } from 'lib/utilities/blockEnv'

export const SHOP_WITH_US_BLOCK_DATA = [
  {
    id: 1,
    label: 'Products',
    href: '/category/all-categories'
  },
  {
    id: 2,
    label: 'Services',
    href: '/services'
  },
  {
    id: 3,
    label: 'Store Locator',
    href: '/store-locator'
  },
  {
    id: 4,
    label: 'EDI Solutions',
    href: '/coming-soon'
  },
  {
    id: 6,
    label: 'Current Promotions',
    href: '/current-promotions'
  }
]

export const ACCOUNT_LOGGED_OUT_USER_BLOCK_DATA = [
  {
    id: 1,
    label: 'Sign In',
    href: noUat() ? 'https://nzsafetyblackwoods.co.nz/en' : '/auth/login',
    target: noUat() ? '_blank' : ''
  },
  {
    id: 2,
    label: 'Create a Web Login',
    href: noUat() ? 'https://nzsafetyblackwoods.co.nz/en/register' : '/auth/register',
    target: noUat() ? '_blank' : ''
  },
  {
    id: 3,
    label: 'Request a Credit Account',
    href: noUat() ? 'https://nzsafetyblackwoods.co.nz/en/customer/requestaccount' : '/coming-soon',
    target: noUat() ? '_blank' : ''
  }
]

export const LOGGED_IN_HELP_CONTAINER_BLOCK_DATA = [
  {
    id: 1,
    label: 'Order Tracking',
    href: '/coming-soon'
  },
  {
    id: 2,
    label: 'Delivery',
    href: '/terms/shipping-details'
  },
  {
    id: 3,
    label: 'Order Changes',
    href: '/coming-soon'
  },
  {
    id: 4,
    label: 'Exchanges & Returns',
    href: '/terms/return-policy'
  },
  {
    id: 5,
    label: 'Product Queries',
    href: '/coming-soon'
  },
  {
    id: 6,
    label: 'Product Notices',
    href: '/product-safety-notices-and-recalls'
  },
  {
    id: 7,
    label: 'Payments & Accounts',
    href: '/coming-soon'
  },
  {
    id: 8,
    label: 'Using Our Website',
    href: '/support/welcome'
  }
]

export const LOGGED_OUT_HELP_CONTAINER_BLOCK_DATA = [
  {
    id: 1,
    label: 'Delivery',
    href: '/terms/shipping-details'
  },
  {
    id: 2,
    label: 'Exchanges & Returns',
    href: '/terms/return-policy'
  },
  {
    id: 3,
    label: 'Product Queries',
    href: '/coming-soon'
  },
  {
    id: 4,
    label: 'Product Notices',
    href: '/product-safety-notices-and-recalls'
  },
  {
    id: 5,
    label: 'Using Our Website',
    href: '/support/welcome'
  }
]
