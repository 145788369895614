import { datadogRum } from '@datadog/browser-rum'

/** Data Dog Rum */
datadogRum.init({
  applicationId: process.env.NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID || '',
  clientToken: process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN || '',
  site: 'datadoghq.com',
  service: 'shopnx_app',
  env: process.env.NEXT_PUBLIC_MATRIX_ENV,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  //sessionReplaySampleRate: process.env.ENVIRONMENT === 'prod' ? 25 : 0,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'allow',
  trackSessionAcrossSubdomains: true,
  allowFallbackToLocalStorage: true,
  usePartitionedCrossSiteSessionCookie: true
})

datadogRum.startSessionReplayRecording()

export default function DatadogInit() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null
}
