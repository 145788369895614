import React, { FC } from 'react'
import { Modal, ModalBody, ModalHeader } from '@nzsb/shopnx-ui'
import { useModalContext } from 'lib/contexts/modal-context'

import { LinkToCreditAccountForm } from 'components/forms/LinkToCreditAccount'

export interface ILinkToCreditAccountModalProps {
  className?: string
  showModal?: boolean
  setShowModal?: any
}

export const LinkToCreditAccountModal: FC<ILinkToCreditAccountModalProps> = ({
  className,
  showModal,
  setShowModal,
  ...restProps
}: ILinkToCreditAccountModalProps) => {
  const { linkToCreditAccountModal } = useModalContext()

  return (
    <Modal
      componentId='link-to-credit-account-modal'
      isOpen={linkToCreditAccountModal?.isOpen}
      className='w-[444px]'
      {...restProps}>
      <ModalHeader
        componentId='link-to-credit-account-modal'
        onClose={linkToCreditAccountModal?.close}
        heading=''
        hasBorder={false}
      />
      <ModalBody componentId='link-to-credit-account-modal' className='p-0 mx-5 mb-5'>
        <LinkToCreditAccountForm />
      </ModalBody>
    </Modal>
  )
}

export default LinkToCreditAccountModal
