import { ILog, loggingRequest } from 'lib/actions/logger/loggingRequest'
import LogLevel from 'lib/enums/logLevel'
import { httpPOST } from 'lib/http'
export interface IMatrixProps {
  type?: 'increment' | 'histogram' | 'counter' | 'gauge' | 'decrement'
  metricName?: any
  tags: string[]
  value?: number
}

/**
 * @param type - [string] type of matrix 'increment' | 'histogram' | 'counter' | 'gauge' | 'decrement'
 * @param metricName - [string] name of the matrix
 * @param tags - [array] tags for the matrix
 * @param value - [number] value for the matrix
 * @returns - [void] void
 * @eg: callMatrix({ type: 'increment', metricName: 'test', tags: ['test'], value: 1 })
 */

export const callMatrix = async ({
  type = 'increment',
  metricName,
  tags,
  value = 1
}: IMatrixProps) => {
  try {
    const response = await httpPOST({
      type: 'external',
      endpoint: 'matrix',
      params: {
        headers: {
          ApiKey: process.env.NEXT_PUBLIC_MATRIX_API_KEY
        }
      },
      url: `/${type}`,
      body: {
        metricName: metricName,
        tags: [`env:${process.env.NEXT_PUBLIC_MATRIX_ENV}`, ...tags],
        value: value
      }
    })

    if (response?.data !== 'success') {
      const log: ILog = {
        level: LogLevel.Error,
        message: `Error on callMatric: - ${
          response ? JSON.stringify(response) : "error don't have details"
        }`
      }
      loggingRequest(log).catch(err => {
        console.error(err)
      })
    }

    return response
  } catch (error) {
    const log: ILog = {
      level: LogLevel.Error,
      message: `Error on callMatric: - ${
        error ? JSON.stringify(error) : "error don't have details"
      }`
    }
    loggingRequest(log).catch(err => {
      console.error(err)
    })
  }
  return {
    data: 'success'
  }
}

export default callMatrix
