import { useMutation } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

export interface IChangePasswordInfo {
  confirmedPassword: string
  currentPassword: string
  newPassword: string
}

export interface IChangePasswordResponse {
  data: any
  messageDescription: null | string
  messageTitle: string
  messageType: string
}

const changePassword = async ({
  confirmedPassword,
  currentPassword,
  newPassword
}: IChangePasswordInfo) => {
  const response = await httpPOST({
    endpoint: 'identity',
    url: '/auth/changepassword',
    body: {
      confirmedPassword,
      currentPassword,
      newPassword
    }
  })
  /** When Needed to handle the Notification with default behavior ['Info' | 'Success' | 'Warning' | 'Error'] use handleResponseToast(response) */
  // handleResponseToast(response?.data)
  return response?.data
}

/* Client side react-query custom hook */
export const useChangePassword = () => {
  const { mutateAsync, ...restOptions } = useMutation({
    mutationFn: changePassword
  })

  // Passing mutateAsync as forgot password
  // and restOptions as rest of the options
  return { changePasswordAsync: mutateAsync, ...restOptions }
}
