import { useQuery } from '@tanstack/react-query'
import { httpGET } from 'lib/http'

export interface IGetBasicAccountInfoResponse {
  messageTitle: string | null
  messageDescription: string | null
  messageType: string
  data: IBasicAccountInfo
}

export interface IBasicAccountInfo {
  fullName: string
  emailAddress: string
  roles: any
  accountCode: string
  permissions: string[]
}

export const getBasicAccountInfo = async (context?: any) => {
  try {
    const { data } = await httpGET({
      url: `account/getcustomer`,
      context
    })
    return data?.data
  } catch (error) {
    return { error }
  }
}

export const useGetBasicAccountInfo = () => {
  return useQuery({ queryKey: ['GET_BASIC_ACCOUNT_INFO'], queryFn: getBasicAccountInfo })
}
